
.timer-wrapper{
    border: none !important;
    background: $primary;
    color: $white;
    font-weight: 700;
    max-width: 300px;
    padding: 14px 20px;
    margin: 15px 0;
    text-transform: uppercase;
    @include make-flex;
    @include justify-content(center);
    @include align-items(center);
}