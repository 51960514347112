//
//  Rating summary
//  ---------------------------------------------------

.rating-summary{
    @include make-flex();
    @include align-items(center);
    .label{
        margin-right: $indent-small;
    }
    .rating-result__stars{
        line-height: 1;

        span.fa{
            color: #fcda00;
            display: inline-block;
            font-size: 14px;
            line-height: 1;
            padding: 0 2px;
        }
    }
    .rating-result__text{
        display: none;
    }
}
.items.review-items .rating-result__stars{
    padding-top: 3px;
}

//
//  Review List
//  ---------------------------------------------------

.review-list{
    .review-items{
        margin-top: $indent-base;
    }
    .review-item{
        margin-bottom: $indent-base;
    }
    .review-title{
        @extend .heading-4;
        margin-bottom: $indent-small;
    }

    //Rating
    .review-ratings{}

    .rating-summary{
        @include make-flex();
    }
    .rating-label{
        @include flex-basis(30%);
        padding: $input-padding-vert 0;
    }
    .rating-result{
        display: inline-block;
    }


    //Details
    .review-details{
        @extend .clearfix;
        p{
            float: left;
            padding-right: 2rem;
            font-weight: 700;
            .review-details-value{
                text-transform: uppercase;
            }
        }
    }
    .review-author{
        text-align: left;
    }
    .review-date{
        text-align: right;
    }
    .review-details-label{}
    .review-details-value{
        display: inline-block;
    }

    .review-ratings,
    .review-content{
        margin-bottom: 1.5rem;
    }
}

@media (min-width: $screen-sm-min){
    .review-list{
        .rating-label{
            @include flex-basis(10%);
        }
    }
}

//
//  Review Form
//  ---------------------------------------------------

.review-add{
    .review-legend{
        @extend .heading-4;
        span{
            display: inline-block;
            margin-right: $indent-small;
        }
    }
}

.review-fieldset{
    .row{
        margin-top: $indent-base;
    }

    .review-legend{
        border-bottom: solid 1px $border-color;
        border-top: solid 1px $border-color;
        margin: 0;
        padding: 20px 0;
        display: block;
        width: 100%;
        span{
            font-weight: normal;
            text-transform: uppercase;
        }
        strong{
            font-weight: normal;
        }
    }
}

.review-field-rating{
    margin-bottom: 0;
    >.label{
        min-width: 100px;
    }
}
.control.review-control-vote{
    display: inline-block;
    label[class^='rating-']{
        display: inline-block;
        cursor: pointer;
        margin-right: 0;
        //Star rating
        .rating-star {
            color: #fcda00;
            display: inline-block;
            font-size: 1.5rem;
            margin: $input-padding-vert 0;
            line-height: 1;
            padding: 0 4px;
            @include font-icon("\e83a");
        }

        &.fill{
            .rating-star {
                @include font-icon("\e838");
            }
        }
    }
    .radio{
        display: none;
    }
}

.catalog-product-view .data.items .content #review-form{
    .actions-toolbar{
        padding: 10px 0 30px;
    }
    input,
    textarea{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}
