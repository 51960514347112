
//
//  Fotorama
//  ---------------------------------------------

.gallery-placeholder{
    .loading-mask{
        padding: 0 0 50%;
        position: absolute;
        display: none;
    }
    
    .fotorama-hidden, 
    .fotorama--hidden, 
    .fotorama__load {
        left: -99999px;
        position: absolute;
        top: -99999px;
        z-index: -1000;
        display: none;
    }
}

.fotorama-item{
    .fotorama__stage{
        margin-bottom: $indent-small;
    }
}

.gallery-placeholder,
.fotorama-item{
    &.fotorama--fullscreen,
    &.fotorama--fullscreen .fotorama__nav,
    &.fotorama--fullscreen .fotorama__stage {
        background: #fff;
    }

    &:not(.fotorama--fullscreen) {
        .fotorama__wrap--css3 .fotorama__html,
        .fotorama__wrap--css3 .fotorama__stage .fotorama__img {
            left: 50% !important;
            transform: translate(-50%, 0);
            -webkit-transform:  translate(-50%, 0);
        }
    }
    .fotorama__thumb-border {
        border-color: $primary;
    }
    .fotorama__wrap {
        margin-right: auto;
        margin-left: auto;
    }
}

.fotorama__fullscreen-icon,
.fotorama__zoom-out,
.fotorama__zoom-in,
.fotorama__arr,
.fotorama__stage__shaft,
.fotorama__nav__frame--thumb,
.fotorama__thumb,
.fotorama__nav__frame--dot,
.fotorama__dot{
    &:focus,
    &:focus:after,
    &after{
        box-shadow: none !important;
    }
}

// Fix image position after change color in swatch attributes
.fotorama.fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    left: 50%!important;
    @include transform(translateX(-50%));
}