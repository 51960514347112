.page-messages{
    margin-bottom: $indent-base;
}
.messages{
    position: fixed;
    z-index: 199;
    top: 70px;
    right: 5%;
    max-width: 90%;

    @media (min-width: $screen-sm-min){
        top: 20px;
        max-width: 100%;
    }
}

.message{
    padding: 0.8rem 1rem;
    margin-bottom: $indent-small;
    border: 1px solid transparent;
    border-radius: 2px;
}

.message:not(.notice):not(.info){
    position: relative;
    cursor: pointer;
    padding-bottom: 1.4rem;

    > div:after{
        content: 'Klik om te sluiten';
        position: absolute;
        bottom: 7px;
        right: 0;
        left: 0;
        font-size: 10px;
        line-height: 1;
        color: $secondary-1;
        text-align: center;
        text-transform: uppercase;
    }
}

.message-info,
.message-notice,
.message.notice,
.message.info{
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.message-warning{
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.message-error{
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.message-success{
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}