//
//  Cart page
//  _____________________________________________

$cart-page_indent: 40px;
$cart-page_indent__sm: 20px;

.checkout-cart-index{
    .page-title span{
        z-index: 35;
        line-height: 1;
        color: $secondary-1;
        font-weight: 400;
        text-transform: none;
    }
}

#shopping-cart-table{
    border-collapse: collapse;

    a{
        font-weight: 400;
    }

    .col.item{
        @include make-flex;
        @include justify-content(flex-start);
        @include align-items(center);

        @media (max-width: $screen-lg-min - 1){
            @include flex-wrap(wrap);
        }
    }

    thead{
        th{
            font-size: 14px;
            color: $default;
            font-weight: 300;
            border-bottom: none;
        }

        .price{
            color: $secondary-1;
        }
    }
    tr th{
        padding-left: $cart-page_indent;
        &:last-child{
            border-right: none;
        }
        &:first-child{
            padding-left: 0;
        }

        @media (max-width: $screen-sm-min - 1){
            border-right: none;
        }
    }
    td{
        vertical-align: middle;
        min-width: 140px;
        padding: $cart-page_indent;
        &:first-child{
            padding-left: 0;
        }
        @media (max-width: $screen-lg-min - 1){
            padding: $cart-page_indent__sm;
        }

        &.price,
        &.subtotal{
            font-size: 1rem;
            line-height: 1;
            color: $primary;
        }

        > div{
            margin: 0;
        }

        input.qty{
            text-align: center;
            margin: 0 auto;
            border-radius: 100px;
        }
    }

    .cart.item{
        .item-info{
            @media (max-width: $screen-sm-min - 1){
                @include make-flex;
                @include flex-direction(column);

                td{
                    border-right: none;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        td{
            text-align: center;
            &:last-child{
                border-right: none;
            }
            &.col.item{
                text-align: left;

                @media (max-width: $screen-sm-min - 1){
                    &:before{
                        display: none;
                    }
                }
            }

            .product-item-photo{
                padding-right: $cart-page_indent;

                @media (max-width: $screen-sm-min - 1){
                    padding-right: 0;
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 20px;
                }

                > span{
                    display: block;
                    background: $bg-dark;
                }
            }
        }

        .item-actions{
            td{
                padding: 10px 0;
            }
        }
        .actions-toolbar{
            @include make-flex;
            @include justify-content(flex-start);
            @include align-items(center);
            position: relative;

            .action{
                margin: 0;
                margin-right: 10px;
            }
            > .action{
                font-size: 18px;
                line-height: 18px;
                background: transparent;
                color: $secondary-1;
                padding: 6px 20px 6px 0;
                &:hover{
                    color: $primary;
                }
            }
            > .action span{
                display: none;
            }
            > .action-edit{
                @include font-icon('\e3c9');
            }
            > .action-delete{
                @include font-icon('\e872');
            }

            .action-gift{
                font-size: 18px;
                line-height: 18px;
                background: transparent;
                color: $secondary-1;
                padding: 6px 20px 6px 0;
                &:hover{
                    color: $primary;
                }
                @include font-icon('\e8f6');

                span{
                    display: none;
                }
            }
            .action-towishlist{
                @include font-icon('\e87e');
            }
            .gift-content{
                display: none;
                background: $white;
                border: 1px solid $border-color;
                padding: $indent-small;
                text-align: left;
                min-width: 250px;
                &._active{
                    display: block;
                    position: absolute;
                    z-index: 50;
                    top: 100%;
                    left: 0;
                }
            }
        }

        .col.price,
        .col.subtotal{
            font-size: 24px;
            line-height: 1;
            font-weight: 400;

            @media (max-width: $screen-md-min - 1){
                font-size: 16px;
            }
        }

        .product-item-details{
            max-width: 50%;

            @media (max-width: $screen-lg-min - 1){
                @include flex-basis(100%);
                width: 100%;
            }

            dl.item-options{
                font-size: 12px;
                @include make-flex;
                @include flex-direction(row);
                @include justify-content(center);
                @include align-items(center);
                @include flex-wrap(wrap);

                dt{
                    font-weight: 400;
                    text-transform: capitalize;
                    padding-right: 5px;
                    &:after{
                        content: ':';
                    }
                }
                dd{
                    padding-left: 0;
                    text-transform: capitalize;
                }
            }
        }
    }
    .item-control{
        position: relative;

        .action-gift{
            font-size: 22px;
            line-height: 37px;
            @include font-icon('\e8f6');

            span{
                display: none;
            }
        }
        .gift-content{
            display: none;
            background: $white;
            border: 1px solid $border-color;
            padding: $indent-small/2;
            text-align: left;
            min-width: 250px;
            &._active{
                display: block;
                position: absolute;
                z-index: 50;
                top: 100%;
                left: 0;
            }
        }

        .action-edit,
        .action-delete{
            span{
                display: none;
            }
            font-size: 1.5rem;
            line-height: 1;
            background-color: transparent;
        }
    }
}

table.cart{
    width: 100%;

    > caption,
    .qty label {
        display: none;
    }

    .product-item-details,
    .product-item-photo{
        display: table-cell;
    }

    td.price .price,
    td.subtotal .price{
        display: inline-block;
        padding: $input-padding-vert 0;
    }
}

.cart-container .main.actions{
    //Cart actions
    margin: 1rem 0 0;
    @include make-flex;
    @include justify-content(flex-start);
    @include align-items(center);

    @media (max-width: $screen-md-min - 1) {
        margin-bottom: 1.5rem;
        @include flex-direction(column);
        @include align-items(flex-start);
    }

    .action{
        padding: 5px;
        font-size: 12px;
        line-height: 22px;
        &:before{
            font-size: 22px;
            line-height: 1;
        }

        span{
            font-weight: 400;
            text-transform: capitalize;
            &:hover{
                color: #000;
            }
        }

        &.continue{
            @include font-icon('\f104', $fa);
            padding: 5px 10px;
            line-height: 16px;

            span {
                &:hover,
                &:focus {
                    color: $white;
                }
            }

            @media (max-width: $screen-md-min - 1) {
                margin-bottom: 15px;
            }
        }
        &.clear{
            @include font-icon('\e611');
            color: $secondary-1;
            background-color: transparent;
            border: none;
            &:hover{
                color: $primary;
            }

            @media (min-width: $screen-md-min) {
                margin-left: 2rem;
            }
            @media (max-width: $screen-md-min - 1) {
                margin-bottom: 8px;
            }
        }
        &.update{
            @include font-icon('\e028');
            color: $secondary-1;
            background-color: transparent;
            border: none;
            &:hover{
                color: $primary;
            }
        }
        &.continue,
        &.clear,
        &.update{
            span{
                display: inline-block;
                line-height: 22px;
            }
        }
    }
}

//
//  Cart Summary
//  ---------------------------------------------


.cart-summary{
    padding: 30px;

    .title strong{
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        color: $secondary-1;
    }
    >.title{
        font-size: 34px;
        line-height: 1;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 1.5rem;
        color: $secondary-1;
        font-weight: 400;
    }
    .checkout.methods{
        .item{
            margin-bottom: $indent-small/2;
            text-align: center;
        }
    }
    .block{
        margin: 0;
    }
    .cart-totals{
        text-align: left;

        caption{
            display: none;
        }
        th, td{
            padding-left: 0;
        }
    }
    .legend{
        display: none;
    }

    .checkout.methods.checkout-methods-items{
        .action.primary{
            background: $primary;
            padding: 15px 20px;
            &:hover{
                background: $secondary-1;
            }
        }
    }
}

.table.totals{
    padding: $indent-small 0;
    .mark,
    .amount{
        padding: 0;
    }
    .mark{
        padding: 0 $indent-small 0 0;
        color: $default;
        text-align: left;

        &,
        strong{
            font-weight: 300;
        }
    }
    .amount{
        color: $secondary-1;
        text-align: right;

        &,
        strong{
            font-weight: 400;
        }
    }
    .shipping .value{
        font-weight: 400;
        font-size: 12px;
        vertical-align: initial;
    }
}

.items.methods,
.item-options{
    @include make-flex();
    @include flex-direction(column);

    dt, dd{
        @include flex-basis(50%);
    }

    .item{
        @include flex-basis(100%);
    }
    .checkout{
        width: 100%;
    }
    .action.multicheckout{
        background-color: transparent;
        padding: 5px 0;
        border: none;
        font-size: 16px;
        font-weight: 300;
        color: $secondary-2;
        &:hover{
            background-color: transparent;
            color: $primary;
        }
    }
}

// Shipping block
.block.shipping{
    .items.methods{
        display: block;
        .item-title,
        .item-options{
            max-width: 100%;
            padding: 0;
        }
    }

    .field.note{
        font-size: 12px;
        line-height: 1;
        text-transform: none;
        font-weight: 400;
    }
}

#co-shipping-method-form{
    .items.methods .item-title{
        font-size: 20px;
        color: $black;
    }


}

