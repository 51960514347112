
@import "_modals.scss";

//
//  Variables
//  _____________________________________________

$checkout-page__border: 1px solid $border-color;

$checkout-wrapper__margin: $indent-base;
$checkout-wrapper__columns: 8;


$checkout-step-title__border: $checkout-page__border;
$checkout-step-title__padding: 10px;

$shipping-item__indent: 20px;
$shipping-item__border: $checkout-page__border;


$checkout-sidebar__margin: 10px;
$checkout-sidebar__border: $checkout-page__border;


$checkout-tooltip__hover__z-index: 100;

$checkout-tooltip-icon-arrow__font-size: 10px;
$checkout-tooltip-icon-arrow__left: -10;

$checkout-tooltip-icon__color: #000;
$checkout-tooltip-icon__content: '\f059';
$checkout-tooltip-icon__font-size: 24px;
$checkout-tooltip-icon__hover__color: $primary;

$checkout-tooltip-content__background-color: #f5f5f5;
$checkout-tooltip-content__border-color: #dddddd;
$checkout-tooltip-content__border-width: 1px;
$checkout-tooltip-content__font-size: 18px;
$checkout-tooltip-content__padding: 12px;
$checkout-tooltip-content__width: 270px;
$checkout-tooltip-content__active__border-color: $border-color;

$checkout-tooltip-content-mobile-popup__width: 200px;
$checkout-tooltip-content-mobile__right: -10;
$checkout-tooltip-content-mobile__top: 30px + $checkout-tooltip-icon-arrow__font-size;


$checkout-opc__border: $checkout-page__border;
$checkout-opc__indent: 20px;
$checkout-opc__content-indent: 10px;

//
//  Common
//  _____________________________________________

.checkout-index-index{
    .page-title{
        margin-bottom: 2em;
    }
    .page-title span{
        z-index: 35;
        line-height: 1;
        color: $secondary-1;
        font-weight: 600;
        text-transform: none;
    }
}

.checkout-index-index .opc-wrapper {
    .step-title {
        @extend .heading-3;
        margin-bottom: $checkout-step-title__padding;
    }

    .step-content {
        margin: 0 0 40px;

        .note{
            font-size: $input-font-size - 2;
            &::before{
                font-family: $fa;
                content: '\f0d8';
                padding-right: 5px;
            }
        }

        .message{
            padding: 5px 5px 5px 0;
            margin-bottom: 0;
            font-size: 12px;
            line-height: 1;
            color: #F11F17;
        }

        .field {
            &.required {
                .label {
                    font-weight: 400;
                }
            }
        }
    }
}

.checkout-index-index{
    .page-title{
        text-align: center;
        span{
            font-weight: 400;
        }
    }
    #customer-email-fieldset{
        .loading-mask{
            display: none !important;
        }
        .field{
            margin-bottom: 0 !important;
        }
    }


    .loading-mask{
        bottom: 0;
        left: 0;
        margin: auto;
        position: fixed !important;
        right: 0;
        top: 0;
        z-index: 9999;

        .loader {
            position: absolute;
            z-index: 9999;
            left: 50%;
            top: 50%;
            margin: -45px 0 0 -45px;
            @include custom-spinner();

            &:before,
            &:after{
                display: none;
            }

            p{
                display: none;
            }
            > img {
                bottom: 0;
                left: 0;
                height: 60px;
                margin: auto;
                position: fixed;
                right: 0;
                top: 0;
                z-index: 100;
                width: auto;
                display: none;
            }
        }
    }

    legend,
    .legend{
        font-weight: 300;
    }
    input,
    select {
        border-radius: 100px;
    }
}

.checkout-onepage-success #registration{
    .action.primary{
        @include button-block;
        @include button-block__primary;
        cursor: pointer;
        outline: none;
        border: none;
        font-size: 18px;
        font-weight: 300;
    }
}

.checkout-container{
    @media (min-width: $screen-sm-min + 1) {
        @include clearfix;
        .opc-wrapper{
            float: left;
            padding-right: 30px;
            width: 66.6667%;
        }

        .opc-sidebar{
            float: right;
            margin: -83px 0 20px;
            width: 33.3333%;
        }
    }
}

//
//  Progress Bar
//  _____________________________________________

.opc-progress-bar{
    margin-bottom: $indent-base;
    @include make-flex;
    @media (min-width: $screen-sm-min){
        float: left;
        padding-right: 30px;
        width: 66.6667%;
    }
    @media (max-width: $screen-lg-min - 1){
        @include flex-wrap(wrap);
    }

    .opc-progress-bar-item{
        font-size: $default-font-size + 2;
        line-height: 2em;
        position: relative;
        padding-top: 30px;
        opacity: .5;
        @include flex-basis(50%);
        &:before,
        &:after{
            display: none;
        }

        @media (max-width: $screen-lg-min - 1){
            @include flex-basis(100%);
        }

        &:first-child span:before{
            content: '01';
        }
        &:last-child span:before{
            content: '02';
        }
        span{
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
            color: $secondary-1;
            vertical-align: baseline;

            &:before{
                color: $primary;
                font-size: 30px;
                line-height: 30px;
                padding-right: 25px;
            }

            @media (min-width: $screen-xl-min - 100px){
                font-size: 34px;
                line-height: 73px;
                &:before{
                    font-size: 73px;
                    line-height: 73px;
                }
            }
            @media (max-width: $screen-xl-min - 101px){
                font-size: 30px;
                line-height: 40px;
                &:before{
                    font-size: 40px;
                    line-height: 40px;
                }
            }
        }

        &._active{
            opacity: 1;
        }

        &._complete{
            opacity: .5;
        }
    }
}

//
//  Sidebar
//  _____________________________________________

.opc-sidebar {
    font-size: 16px;
    line-height: 28px;
    .opc-block-summary span.title{
        font-size: 34px;
        line-height: 1;
        font-weight: 400;
        display: block;
        margin-bottom: $checkout-sidebar__margin * 2;
        color: $secondary-1;
    }

    .table-totals{
        th,
        td{
            padding: 0;
        }
    }
}

.opc-block-summary .block.items-in-cart{
    margin-top: $indent-base;

    .title strong{
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
        color: $secondary-1;
    }
    .product-item{
        margin-bottom: $indent-small;

        > .product{
            @include make-flex;
            @include justify-content(space-between);
            @include align-items(center);
        }

        .product-image-container{
            > span{
                display: block;
                background: $bg-dark;
            }
        }

        .product-item-details{
            padding-left: 20px;
            @include flex-basis(calc(100% - 85px));

            .product-item-name{
                color: $secondary-1;
            }

            .subtotal{
                font-size: 20px;
                font-weight: 400;
                color: $primary;
            }
        }
    }
}

#opc-sidebar .opc-block-shipping-information{
    padding-top: 0;
    border-top: none;
    .ship-to{
        margin-top: $indent-base;
    }
}

//
//  Shipping
//  _____________________________________________

.opc-wrapper{
    .shipping-address-item{
        padding: $shipping-item__indent;
        border: solid 1px transparent;
        &.selected-item{
            border: $shipping-item__border;
        }

    }
}

.form-shipping-address{
    .field.street{
        label{
            display: none;
        }
    }
    .field.required.street div.mage-error{
        position: relative;
    }
}

.table-checkout-shipping-method{
    width: 100%;
    margin-bottom: $indent-small;

    thead tr,
    tbody tr {
        display: table-row;
    }

//    margin-bottom: $indent-small;
//    @include make-flex;
//    @include flex-direction(row);
//
//    thead tr,
//    tbody tr{
//        @include make-flex;
//        @include flex-direction(column);
//        margin: 0;
//        padding-right: 10px;
//
//        @media (min-width: $screen-sm-min){
//            @include flex-basis(220px);
//            width: 220px;
//        }
//    }
//
//    th,
//    td{
//        max-height: 39px;
//        font-weight: 300;
//        padding: $input-padding-vert 0;
//    }
//    td.ch-method{
//        .radio,
//        input[type="radio"]{
//            margin: 0;
//        }
//    }
//    td > span{
//        display: inline-block;
//    }
}
#checkout-step-shipping_method .actions-toolbar .action,
#checkout-payment-method-load .actions-toolbar .action{
    background: $primary;
    &:hover{
        background: $secondary-1;
    }
    @media (min-width: $screen-sm-min){
        padding: 21px 80px;
    }
}
.payment-method-billing-address{
    font-size: 16px;
    line-height: 28px;

}
.methods-shipping{
    margin-bottom: $indent-base;
}

#myparcel_td {
    .mypa-date {
        display: none !important;
    }
    .mypa-onoffswitch-checkbox {
        display: none !important;
    }
    #mypa-tabs-container {
        height: 64px;
    }
    #mypa-tabs {
        font-size: 14px;
    }
    .mypa-header-title {
        display: inline;
    }
}

//
//  Estimate totals
//  _____________________________________________

.opc-estimated-wrapper{
    @include make-flex();
    @include justify-content(space-between);
    margin-bottom: $indent-base;

    .estimated-block span{
        display: block;
        font-weight: 700;
    }

    @media (min-width: $screen-sm-min + 1) {
        display: none;
    }
}

//
//  Order Summary
//  _____________________________________________

.opc-summary-wrapper{
    &,
    *{
        &:focus{
            outline: none;
        }
    }
}

.opc-block-summary {
    .table-totals{
        caption{
            display: none;
        }
    }

    .minicart-items-wrapper,
    .minicart-items li > .product{
        overflow: visible;
    }
    .minicart-items .product.options .toggle{
        margin-bottom: 0;
    }
    .item-options{
        @include flex-direction(row);
        @include flex-wrap(wrap);
    }
    .product.options{
        position: relative;

        .content{
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 2;
            background: $white;
            width: 100%;
            padding: 5px;
            box-shadow: 0 0 5px 0 transparentize($secondary-1, .5);
        }
    }
}

.opc-block-shipping-information{
    border-top: $checkout-opc__border;
    padding-top: $checkout-opc__indent;

    .shipping-information-title{
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
        color: #191919;
        @include make-flex();
        @include justify-content(space-between);
    }

    .shipping-information-content{
        padding: $checkout-opc__content-indent;
    }

    .action-edit{
        background: none;
        padding: 0;
        span{
            display: none;
        }
        &:hover{
            background: none;
            opacity: 0.5;
        }
        &::before{
            @include font-icon__pseudo('\f040', $fa);
            padding-right: 10px;
            font-size: 14px;
        }
    }
}

.billing-address-details{
    margin-bottom: $indent-small;
}

.opc-payment-additional{
    margin-top: $indent-small;
}

@media (max-width: $screen-sm-min) {
    .opc-block-summary {
        .table-totals{
            th{
                display: block;
                width: 100%;
                padding: 10px 0 0;
            }
            td{
                padding: 0;
                &::before{
                    display: none;
                }
            }
        }
    }
}

//
//  Authentication
//  _____________________________________________

.checkout-container{
    position: relative;
    .authentication-wrapper{
        position: absolute;
        right: 0;
        top: -25px;

        button.action-auth-toggle{
            text-transform: uppercase;
            font-weight: 700;
            color: $white;
            transition: .3s ease;
            background: $primary;
            padding: 3px 8px 1px;
            border-radius: 100px;
            &:hover{
                background: $primary;
            }
        }
        aside.authentication-dropdown{
            @media (min-width: $screen-sm-min) {
                top: -100px;
            }
        }

        .actions-toolbar{
            @include flex-wrap(wrap);

            .primary{
                margin-bottom: 5px;
            }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
            top: 40px;
        }
    }

    @media (min-width: $screen-sm-min) {
        .authentication-wrapper{
            .modal-custom-overlay{
                background: none;
            }
        }
        .authentication-dropdown {
            @include abs-modal();
            @include abs-modal-popup();
            width: 270px;
            right: 0;
            left: auto;
            position: absolute;
            overflow: visible;

            .modal-inner-wrap{
                margin: 0;
                width: 100%;
                @include transform(translateY(-300%));
            }
        }
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-min + 1) {
    .checkout-index-index .custom-slide._show  {
        overflow: visible!important;
    }
}

//
//  Tooltip
//  _____________________________________________

.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 1px;

    &._active {
        z-index: $checkout-tooltip__hover__z-index;

        .field-tooltip-content {
            display: block;
        }
        .field-tooltip-action {
            &:before {
                color: $checkout-tooltip-icon__hover__color;
            }
        }
    }

    .field-tooltip-action {
        &::before{
            font-size: $checkout-tooltip-content__font-size;
            font-family: $fa;
            content: $checkout-tooltip-icon__content;
        }

        &:focus {
            ._keyfocus & {
                z-index: $checkout-tooltip__hover__z-index;
                + .field-tooltip-content {
                    display: block;
                }
                &::before {
                    color: $checkout-tooltip-icon__hover__color;
                }
            }
        }
    }

    .field-tooltip-content {
        background: $checkout-tooltip-content__background-color;
        border: solid 1px $checkout-tooltip-content__border-color;
        padding: $checkout-tooltip-content__padding;
        width: $checkout-tooltip-content__width;
        display: none;
        right: 0;
        position: absolute;
        text-transform: none;
        top: 100%;
        word-wrap: break-word;
        z-index: 2;
    }
}


//
//  Print version
//  _____________________________________________

.page-print.sales-order-print{
    #maincontent{
        margin-top: 3rem;
        @include make-container($gutter-width);
        min-width: $container-xs;
        max-width: $screen-xs-min;

        @media (min-width: $screen-sm-min) {
            max-width: $container-sm;
        }
        @media (min-width: $screen-md-min) {
            max-width: $container-md;
        }
        @media (min-width: $screen-lg-min) {
            max-width: $container-lg;
        }
    }

    .actions-toolbar,
    .page-footer{
        display: none;
    }
}