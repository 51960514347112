//
//  Main Styles
//  -------------------------------------------

*{
    margin: 0;
}

html, body {
    height: 100%;
}

body{
    font-weight: $default-font-weight;
    text-align: left;
    -webkit-text-size-adjust: none;
    color: $content-color;
    background: $content-bg;

    @media (min-width: $screen-md-min) {
        font-size: $default-font-size;
        line-height: $default-line-height;
    }
}

.translate-edit-icon {
    width: auto;
}

//
//  Header
//  ----------------------------------------

.page-wrapper{
    background: $content-bg;
    overflow: hidden;
}

.page-header{
    max-height: 212px;
    margin-bottom: $indent-base;
    @media (min-width: $screen-md-min){
        margin-bottom: 50px;
    }

    @media (max-width: $screen-sm-min - 1){
        height: auto !important;
        margin-bottom: 0;
    }
}

.page-header__panel{
    display: none;
}

.page-header__content{
    padding: 20px 0 100px;
    position: relative;
    @media (min-width: $screen-sm-min) {
        padding: 25px 0 12px;
        position: static;
    }
    @media (max-width: $screen-xs-min - 1){
        padding-bottom: 40px;;
    }
    .container{
        @include make-flex;
        @include justify-content(center);
        @media (min-width: $screen-sm-min) {
            @include justify-content(space-between);
        }
    }
    .rd-navbar-search{
        order: (0);
    }
    .rd-navbar-cart-wrap{
        order: (5);
    }

    .logo{
        @media (max-width: $screen-xl-min - 100px){
            width: 460px;
        }
        @media (max-width: $screen-lg-min){
            width: 400px;
        }
        @media (max-width: $screen-sm-min){
            width: 340px;
        }
        @media (max-width: $screen-xs-min){
            width: 300px;
        }
    }
}

.page-header__content .container{
    @include align-items(center);
    @media (min-width: $screen-sm-min){
        position: relative;
    }
}

.header.links{
    li{
        margin-left: $indent-small;
    }
}

//
//  Switchers
//  ---------------------------------------------

//Switcher variables
$switcher-dropdown-bg: $white;
$switcher-dropdown-color: $default;
$switcher-dropdown-bg-hover: lighten($default, 60%);
$switcher-dropdown-color-hover: $default;
$switcher-dropdown-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.2);

.header-switchers {
    @include clearfix;
    > div {
        float: left;
        &:first-child {
            margin-left: 0;
        }
    }
    .actions {
        .action {
            background: none;
            transition: .3s ease;
            &.active,
            &:hover {
                background-color: transparent;
                color: $primary;
            }
        }
    }
}

.switcher{
    position: relative;
    margin: 0 $indent-small;
    padding: 0;
    .action.toggle{
        cursor: pointer;
        &:after{
            @include font-icon__pseudo($icon:'\e5cf');
            padding: 0 2px;
        }
        &.active{
            &:after{
                @include font-icon__pseudo($icon:'\e5ce');
            }
        }
    }
    .dropdown{
        .mage-dropdown-dialog{
            @include transform(translateY(-30px));
            transition: .3s all ease;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 0;
            background: $switcher-dropdown-bg;
            box-shadow: $switcher-dropdown-shadow;
            border: none;
            width: 250px;
            z-index: 15;

            li{
                display: block;
            }
            a{
                display: inline-block;
                padding: 5px 10px;
                color: $switcher-dropdown-color;

                &:hover{
                    color: $switcher-dropdown-color-hover;
                    background: $switcher-dropdown-bg-hover;
                }
            }
        }
        &.active .mage-dropdown-dialog{
            opacity: 1;
            visibility: visible;
            @include transform(translateY(0));
        }
    }

    .rd-navbar-collapse & {
        .dropdown .mage-dropdown-dialog{
            background-color: transparent;
            box-shadow: none;
            a{
                color: $gray;
                &:hover{
                    color: $primary;
                    background-color: transparent;
                }
            }
        }
    }
}
.switcher-options{
    display: inline-block;
    &.active{
        .switcher-dropdown{
            display: inline-block;
        }
    }
}
.switcher-dropdown{
    display: none;
}

.switcher-store{
    margin: 1.5em 0 1em 0;
}

.links{
    li{
        display: inline-block;
    }
}

//
//  Navigation
//  ---------------------------------------------

.page-top{
    margin-bottom: $indent-base;
}

//
//  Breadcrumbs
//  ---------------------------------------------

.breadcrumbs{
    background: transparent;
    margin-bottom: 20px;
    padding: 0;

    @media (min-width: $screen-lg-min) {
        margin-bottom: 40px;
    }

    .items{
        @include make-flex;
        @include justify-content(center);
        @include align-items(center);
        .item{
            font-size: 14px;
            margin-right: 25px;
            padding-right: 25px;
            position: relative;
            &:after{
                @include font-icon__pseudo('\f105', $fa);
                position: absolute;
                right: -3px;
                top: 1px;
            }
            &:last-child{
                margin-right: 0;
                padding-right: 0;
                color: lighten(saturate(adjust_hue($default, 0), 0%), 6.666666666666668%);
                &:after{
                    display: none;
                }
            }

            a{
                color: $secondary-2;
                &:hover{
                    color: $primary;
                }
            }
        }

        @media (max-width: 479px) {
            @include justify-content(flex-start);
            @include flex-wrap(wrap);
        }
    }

    @media (max-width: $screen-sm-min - 1){
        background: $bg-dark;
        padding: 15px;
    }
}

//
//  Main content
//  --------------------------------------------

.page-main{
    margin-bottom: $indent-base;
}

//Page title
.page-title-wrapper{
    margin-bottom: $indent-base;
}

.catalog-category-view{
    .columns.container{
        margin-top: 15px;

        @media (min-width: $screen-md-min) {
            margin-top: 20px;
        }
        @media(min-width:$screen-lg-min){
            margin-top: 35px;
        }
    }
    .page-title-wrapper{
        margin-bottom: 0;

        h1{
            margin-bottom: 0;
        }
    }
}
.category-description{
    font-size: 14px;
    line-height: 1.2;
    max-width: 80vw;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 15px;

    @media(min-width:$screen-lg-min){
        max-width: 1000px;
        font-size: 18px;
        line-height: 28px;
        margin-top: $indent-small;
    }
    @media(min-width: 1600px){
        max-width: 1300px;
    }
}

//
//  Page layouts
//  ---------------------------------------------

.columns > .row{
    @include make-flex();
    @include flex(0 1 auto);
    @include flex-direction(row);
    @include flex-wrap(wrap);
}

//Three columns
.page-layout-3columns{
    .grid-left{
        @include order(1);
    }
    .grid-main{
        @include order(2);
    }
    .grid-right{
        @include order(3);
    }
}

//Two columns with right aside
.page-layout-2columns-right{
    .grid-main{
        @include order(1);
    }
    .grid-right{
        @include order(2);
    }
}

//Two columns with left aside
.page-layout-2columns-left{
    .grid-left{
        @include order(1);
    }
    .grid-main{
        @include order(2);
    }
}
.page-layout-1column{

}

@media (max-width: $screen-sm-min - 1){
    .page-layout-3columns,
    .page-layout-2columns-right,
    .page-layout-2columns-left{
        .grid-left,
        .grid-main{
            @include flex-basis(100%);
        }.grid-main{
            @include order(1);
        }
        .grid-left{
            @include order(2);
        }
        .grid-right{
            @include order(3);
        }
    }
}

@media (min-width: $screen-xl-min - 100px){
    .page-header__content,
    .navigation,
    .breadcrumbs,
    .page-main,
    .page-footer{
        > .container{
            max-width: 1820px;
            width: 83.33333%;
        }
    }
    .page-header__content > .container{
        max-width: 1240px;
    }

    .tm-megamenu.rd-navbar-megamenu > .container{
        width: 100%;
        max-width: 100%;
    }
}

//
//  Blocks
//  ---------------------------------------------

.block{
    margin-bottom: $indent-base;
}

.block-title{
    margin-bottom: $indent-small;
    position: relative;
    strong{
        @extend .heading-2;
        width: 100%;
        display: inline-block;
    }
    .action{
        float: right;
        padding: 0;
        background: 0;
        line-height: inherit;
        &.edit{
            padding: 0 $button-padding-hor;
        }
    }
    .counter{
        width: 100%;
        font-size: .8rem;
        line-height: 1;
        margin-top: 0;
    }

    .sidebar .collapsible-block & {
        margin-bottom: 1em;
    }
}
.block-title,
.block-subtitle,
.filter-options-title{
    &,
    strong{
        font-size: 18px;
        font-weight: 700;
        color: $secondary-1;
        display: block;
        margin-bottom: $indent-small;
        text-transform: uppercase;

        @media (min-width: $screen-sm-min) {
            font-size: 15px;
        }
        @media (min-width: $screen-md-min) {
            font-size: 18px;
        }
        @media (min-width: $screen-lg-min) {
            font-size: 20px;
        }
    }
}


//
//  Boxes
//  ---------------------------------------------

.box{
    margin-bottom: $indent-small;
}
.box-title{
    margin-bottom: $indent-small;
    display: block;
    @extend .heading-4;
}


//
//  Sidebars
//  ---------------------------------------------

.sidebar{
    @media (min-width: $screen-lg-min){
        padding-right: 30px;
        border-right: 1px solid $border-color;
    }

    .block{
        padding-bottom: $indent-base;
        margin-bottom: 0;
        .block-title{
            @extend .heading-3;
        }
    }
    .collapsible-block:first-child{
        margin-top: 0;
        padding-top: 2rem;
    }

    .filter-title{
        &,
        strong{
            font-size: 28px;
            font-weight: 400;
            color: $black;
            margin-bottom: 75px;

            @media (min-width: $screen-lg-min) {
                font-size: 34px;
            }
        }
    }
    .filter-subtitle{
        @media (min-width: $screen-sm-min){
            display: none;
        }
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer{
    clear: both;
    background: #f5f6f7;
}

.copyright-container{
    margin: $indent-small 0;
}

.footer{
    &.links{
        .item{
            display: block;
        }
    }

    > .row{
        padding: 4vw 0 3vw;

        @media (min-width: $screen-sm-min){
            padding: 2vw 0 1.5vw;
        }
        @media (min-width: $screen-xl-min - 200){
            padding: 156px 0 83px;
        }
    }

    h4{
        color: $gray-lighten;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .footer-col-content{
        li{
            display: block;
            margin-bottom: 5px;
            @media (min-width: $screen-md-min){
                margin-bottom: 12px;
            }
        }
        li a,
        li strong{
            font-size: 16px;
            color: $gray;

            @media (min-width: $screen-md-min) {
                padding-left: 35px;
                position: relative;
                &:before{
                    @include font-icon__pseudo('\f101', $fa);
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -7px;
                    font-size: 13px;
                    line-height: 14px;
                    color: $gray;
                    transition: .3s all ease;
                }
            }
            &:hover{
                color: $primary;
                &:before{
                    left: 5px;
                    color: $primary;
                }
            }
        }

        li strong:hover {
            color: $secondary-1;

            &:before {
                left: 0;
            }
        }
    }

    .contact-info{
        .contact-info_address{
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 20px;
            color: $gray;
        }
        .contact-info_tel{
            a{
                color: $gray-lighten;
                &:hover{
                    color: $primary;
                }
            }

            + .contact-info_tel{
                margin-bottom: 20px;
            }
        }
        .contact-info_mail{
            a{
                color: $gray-lighten;
                &:hover{
                    color: $primary;
                }
            }
        }
    }

    @media (max-width: $screen-sm-min - 1){
        .footer-col{
            margin-bottom: 20px;
        }
        h4{
            margin-bottom: 5px;
            font-size: 16px;
            cursor: pointer;
            position: relative;
            &:after{
                @include font-icon__pseudo('\e5cf');
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .footer-col-content{
            display: none;

            li a{
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        .footer-col.active{
            h4:after{
                @include font-icon__pseudo('\e5cf');
            }
            .footer-col-content{
                display: block;
            }
        }
    }

    .copyright{
        padding: 2.34375vw;
        text-align: center;
        display: block;
        position: relative;
        z-index: 0;
        &:after{
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-top: 1px solid rgba(#ebebeb, .2);
        }

        @media (min-width: $screen-xl-min - 200){
            padding: 48px 20px;
        }

        span{
            font-size: 12px;
            color: $gray-lighten;

            @media (min-width: $screen-sm-min){
                font-size: 16px;
            }
        }
    }
}

.footer-col-content{
    padding: 0 !important;
}

//
//  Image Zoom
//  ---------------------------------------------

.zoomContainer{
    z-index: 90 !important;

    .zoomWindowContainer div{
        background-color: #fff !important;
    }
}

//
//  To Top Button
//  ---------------------------------------------
.scrollToTop{
    position: fixed;
    z-index: 999;
    bottom: 40px;
    right: 40px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $white;
    box-shadow: 1px 1px 10px rgba($black, .1);
    display: none;
    cursor: pointer;
    text-indent: 100vw;
    white-space: nowrap;
    overflow: hidden;
    transition: .3s all ease;

    &:before{
        @include font-icon__pseudo($icon:'\e316');
        font-size: 40px;
        line-height: 1;
        text-indent: 0;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -20px;
        color: $secondary-2;
        transition: .3s all ease;
    }
    &:hover{
        background: $primary;

        &:before {
            color: $white;
        }
    }

    @media (min-width: $screen-sm-min){
        right: 50px;
    }
}


//
//  Helpers
//  ---------------------------------------------
.no-display{
    display: none;
}

//
//  Preloader style
//  ---------------------------------------------

#page-preloader:after,
.loading-mask:after{
    content: '';
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: none;
    -webkit-animation: snow 10s linear infinite;
    -moz-animation: snow 10s linear infinite;
    -ms-animation: snow 10s linear infinite;
    animation: snow 10s linear infinite;
}
@keyframes snow {
    0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
    50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
    100% {background-position: 500px 1000px, 200px 400px, -100px 300px;}
}
@-moz-keyframes snow {
    0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
    50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
    100% {background-position: 400px 1000px, 200px 400px, 100px 300px;}
}
@-webkit-keyframes snow {
    0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
    50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
    100% {background-position: 500px 1000px, 200px 400px, -100px 300px;}
}
@-ms-keyframes snow {
    0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
    50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
    100% {background-position: 500px 1000px, 200px 400px, -100px 300px;}
}

@import "_pages.scss";