
//
//  List
//  ---------------------------------------------

.products-list{
    .product-item{
        @media (min-width: $screen-sm-min){
            margin-bottom: $indent-base;
            padding-bottom: $indent-base;
            border-bottom: 1px solid $border-color;
        }
    }
    .product-item-info{
        @include make-flex();
        @include make-row();
        @include flex-wrap(wrap);

        @media (max-width: $screen-sm-min - 1){
            margin-bottom: 30px;
        }
    }

    @media (min-width: $screen-lg-min){
        .product-item-details{
            @include make-flex;
            @include justify-content(space-between);
        }
        .list_product-item-details_1{
            @include flex-basis(66.6666%);
            padding-right: 15px;
        }
        .list_product-item-details_2{
            @include flex-basis(33.3333%);
            padding-left: 15px;
        }
    }

    //Image
    .product-item-photo{
        @media (max-width: $screen-xs-min - 1){
            display: block;
            width: 300px;
            margin: 0 auto 30px;
        }
    }

    .product-image-photo{
        @include make-col();
        @include flex-basis(100%);
        text-align: center;
        margin-bottom: $indent-small;
    }

    //Details
    .product-item-details{
        @include make-col();
        @include flex-basis(100%);
    }

    .product-item-actions > div {
            margin-bottom: 12px;
    }

    .actions .actions-secondary .action{
        background: none;
        display: block;
        padding: 0;
        color: $secondary-1;
        font-size: 19px;
        line-height: 19px;
        margin-bottom: 8px;

        &:before {
            font-size: 14px;
        }

        > span {
            padding-left: 5px;
            vertical-align: middle;
        }

        &:hover{
            color: $primary;
        }

        &.towishlist span,
        &.tocompare span{
            display: inline-block;
            font-size: 15px;
            line-height: 19px;
        }
    }

    .reviews-actions .action.add{
        display: none;
    }

    .products .product-item .price-box{
        margin-bottom: 20px;
    }
}

@media (min-width: $screen-xs-min){
    .products-list{
        .product-item-info{
            @include flex-wrap(nowrap);
        }
        .product-item-photo{
            @include flex-basis(42%);
            margin-bottom: 0;
            padding: 0 15px;
        }
        .product-item-details{
            @include flex-basis(100%);
        }
    }
}
