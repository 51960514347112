
.form-login{
    .actions-toolbar{
        flex-direction: row-reverse;
    }
}

.form-create-account,
.form-edit-account,
.form-address-edit{
    .fieldset{
        margin-bottom: $indent-base;
    }
    .actions-toolbar{
        flex-direction: row-reverse;
        .secondary{
        }
    }
}

.customer-account-create .form-create-account{
    @media (min-width: $screen-md-min){
        max-width: $screen-md-min - 30px;
        margin: 0 auto;
    }

    .field.password #password-error{
        top: calc(100% - 10px);
        @media (max-width: $screen-sm-min - 1){
            position: static;
            top: auto;
            left: auto;
        }
    }

    .field.password.required input.input-text.mage-error{
        @media (max-width: $screen-sm-min - 1){
            margin-bottom: 0;
        }
    }
}

//
//  Account main
//  _____________________________________________

body.account{
    //Block styling
    .block-title{
        position: relative;
        .action{
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    //Box styling
    .box{
        address{
            margin-bottom: 1rem;
        }
    }
}

.sidebar .account-navigation{
    a{
        color: $default;
        &:hover{
            color: $primary;
        }
    }
    li.current strong{
        font-weight: 400;
        color: $primary;
    }

    .block-title{
        &,
        strong{
            margin-top: 0;
        }
    }
}
.sidebar{
    #reorder-validate-detail{
        strong.subtitle{
            display: none;
        }
        .no-display{
            display: block;
        }
    }
    #cart-sidebar-reorder .item.choice{
        label{
            display: none;
        }
        input[type="checkbox"]{
            position: relative;
            opacity: 1;
            z-index: auto;
            margin: 0;
        }
    }
}

.products-grid.wishlist{
    //Price
    .price-box{
        font-size: 1.5rem;
        line-height: 1;
        color: $primary;

        .price-label{
            display: none;
        }
    }
    .special-price{
        color: $primary;
    }
    .old-price{
        .price{
            text-decoration: line-through;
            font-size: 1rem;
            color: $unactive;
        }
    }

    //Name
    .product-item-name{
        a{
            color: $default;
            &:hover{
                color: $primary;
            }
        }
    }

    // Comment
    textarea{
        min-height: 80px;
    }
}

.block-dashboard-info,
.block-dashboard-addresses{
    .block-title strong{
        margin-top: 0;
    }
    .box{
        padding: 10px;
        border: 1px solid $border-color;
        height: 100%;
        position: relative;
    }
    .action.edit{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 1.2rem;
        line-height: 1;
    }
}

// Email to Friend
//-----------------------------------

#add-recipient-button{
    margin-bottom: 1em;
}