@import 'product/_list.scss';
@import 'product/_toolbar.scss';
@import 'product/_view.scss';
@import 'product/compare/_list.scss';
@import 'product/compare/_block.scss';
@import 'product/list/_grid.scss';
@import 'product/list/_list.scss';
@import 'product/view/_fotorama.scss';
@import 'product/view/block/_related.scss';
@import 'product/view/block/_upsell.scss';
@import 'product/view/block/_crosssell.scss';
@import 'product/view/type/_downloadable.scss';


//
//  Sidebar
//  ---------------------------------------------

.collapsible-block{
    position: relative;

    @media (min-width: $screen-sm-min){
        padding-top: $indent-small;
        margin-top: $indent-small;
    }

    .opener{
        display: block;
        @extend .heading-3;
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;
        cursor: pointer;

        &::before{
            position: absolute;
            left: 0;
            top: 0;
            font-weight: 500;
        }
    }
}

.sidebar-additional{
    @media (min-width: $screen-sm-min){
        &:first-child .collapsible-block{
            margin-top: 0;
            border-top: none;
            .block-title{
                &,
                strong{
                    margin-top: 0;
                }
            }
        }
    }
}

//
//  Icons
//  ---------------------------------------------

.towishlist,
.tocompare{
	position: relative;
    span{
        display: none;
    }
}

.product-social-links .mailto{
    padding: 0 $indent-small/2;
    @include font-icon('\e0be');
}
.towishlist{
    @include font-icon('\f08a',$fa);
}
.tocompare{
    @include font-icon('\f074',$fa);
}
