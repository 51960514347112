/*
*   TM_AjaxCatalog
*/

// Local vars

$dropdown-bg:      #ffffff;
$dropdown-indent:  10px;
$border:           1px #e2e8eb;
$shadow-ambient:   0 0 2px 0 rgba(0, 0, 0, .25);
$shadow-hover:     0 0 15px 0 rgba(0, 0, 0, .15);
$dropdown-indent:  10px;
$screen-res:       1024px;

$product-name-size:   14px;
$product-price-size:  16px;
$product-price-color: $black;


// Main Styles



#search_mini_form{
    position: relative;
}

body.ajax-loading #search.input-text:focus{
    // if input has dark background, set "loader-white.gif" for "background-image" property.
    @media (min-width: $screen-sm-min) {
        background-image: url(../images/loader-dark.gif);
        background-position:  9px 50%;
        background-repeat:  no-repeat;
        background-size: 15px 15px;
    }
}


#search_mini_form{

    input[aria-haspopup="false"] + #search_autocomplete ul[role=listbox]{
        overflow: hidden;
    }

    .search-autocomplete {
        overflow: hidden;
        position: static;
        max-height: 350px;
        z-index: 99;
        background: $dropdown-bg;
        box-shadow: $shadow-ambient;

        &::after{
            content: '';
            position: absolute;
            top: 320px;
            left: 0;
            z-index: 9999;
            width: 120%;
            height: 30px;
            background: transparent;
        }

        ul[role=listbox] {
            overflow-y: scroll;
            position: static;
            max-height: 350px;
            margin-right: -20px;
            z-index: 10;
            display: block;            
        }

        ul li.search-item{
            border-bottom: solid $border;
            border-top: none;
            padding: $dropdown-indent;
            padding-right: $dropdown-indent + 20px;
            background: $white;
            transition: .3s all ease;

            .amount{
                position: static;
            }

            a{
                display: block;
                overflow: hidden;
                line-height: normal;
                text-decoration: none;
                margin: -$dropdown-indent;
                padding: $dropdown-indent;

                .search-thumb{
                    float: left;
                    width: 50px;
                    margin-right: 10px;
                }

                .qs-option-name{
                    font-size: $product-name-size;
                    display: block;
                    margin-bottom: 5px;
                    color: #525a6b;
                }

                .price{
                    display: block;
                    color: $product-price-color;
                    font-size: $product-price-size;
                    font-weight: 700;
                }
                &:hover{
                    
                }
            }

            &:hover{
                background: $gray-light;
            }
        }
    }
}