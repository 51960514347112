@import "../../_mixins";
@import "../../_variables";

.select2-container--default {
    .select2-selection--single {
        border: none;
        font-family: $default-font-family;
        border-radius: 0;
        outline: none;
        font-size: 16px;

        &:hover .select2-selection__rendered{
            background-color: $bg-light;
        }

        .select2-selection__rendered {
            color: $gray;
            border-radius: 100px;
            background-color: $bg-dark;
            line-height: 50px;
        }

        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
        }

        .select2-selection__placeholder {
            color: #999;
        }

        .select2-selection__arrow {
            height: 50px;
            position: absolute;
            top: 0;
            right: 13px;
            width: 20px;

            b {
                @include font-icon('\f107', $fa);
                border: none;
                height: 0;
                position: absolute;
                top: 50%;
                margin-top: -14px;
                width: 0;

                &::before {
                    font-size: 14px;
                    color: $secondary-1;
                }
            }
        }
    }

    &.select2-container--open .select2-selection--single .select2-selection__rendered{
        position: relative;
        box-shadow: 2px 2px 5px 0 transparentize($black, .9);
        position: relative;
        z-index: 99;
    }

    &[dir="rtl"] {
        .select2-selection--single {
            .select2-selection__clear {
                float: left;
            }

            .select2-selection__arrow {
                left: 1px;
                right: auto;
            }
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            background-color: #eee;
            cursor: default;
            opacity: 0.3;

            .select2-selection__clear {
                display: none;
            }
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent #888 transparent;
                    border-width: 0 4px 5px 4px;
                }
            }
        }
    }

    .select2-selection--multiple {
        background-color: white;
        border: 1px solid #aaa;
        border-radius: 4px;
        cursor: text;

        .select2-selection__rendered {
            box-sizing: border-box;
            list-style: none;
            margin: 0;
            padding: 0 5px;
            width: 100%;
        }

        .select2-selection__placeholder {
            color: #999;

            margin-top: 5px;

            float: left;
        }

        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
            margin-top: 5px;
            margin-right: 10px;
        }

        .select2-selection__choice {
            background-color: #e4e4e4;

            border: 1px solid #aaa;
            border-radius: 4px;
            cursor: default;

            float: left;

            margin-right: 5px;
            margin-top: 5px;
            padding: 0 5px;
        }

        .select2-selection__choice__remove {
            color: #999;
            cursor: pointer;

            display: inline-block;
            font-weight: bold;

            margin-right: 2px;

            &:hover {
                color: #333;
            }
        }
    }

    &[dir="rtl"] {
        .select2-selection--multiple {
            .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
                float: right;
            }

            .select2-selection__choice {
                margin-left: 5px;
                margin-right: auto;
            }

            .select2-selection__choice__remove {
                margin-left: 2px;
                margin-right: auto;
            }
        }
    }

    &.select2-container--focus {
        .select2-selection--multiple {
            border: solid black 1px;
            outline: 0;
        }
    }

    &.select2-container--disabled {
        .select2-selection--multiple {
            background-color: #eee;
            cursor: default;
        }

        .select2-selection__choice__remove {
            display: none;
        }
    }

    &.select2-container--open.select2-container--above {
        .select2-selection--single, .select2-selection--multiple {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &.select2-container--open.select2-container--below {
        .select2-selection--single, .select2-selection--multiple {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #aaa;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            border: none;
            outline: 0;
            box-shadow: none;
            -webkit-appearance: textfield;
        }
    }

    .select2-results > .select2-results__options {
        overflow-y: visible;
    }

    .select2-results__option {
        &[role=group] {
            padding: 0;
        }

        &[aria-disabled=true] {
            color: #999;
        }

        &[aria-selected=true] {
            background: none;
            color: $primary;
        }

        .select2-results__option {
            padding-left: 1em;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -1em;
                padding-left: 2em;

                .select2-results__option {
                    margin-left: -2em;
                    padding-left: 3em;

                    .select2-results__option {
                        margin-left: -3em;
                        padding-left: 4em;

                        .select2-results__option {
                            margin-left: -4em;
                            padding-left: 5em;

                            .select2-results__option {
                                margin-left: -5em;
                                padding-left: 6em;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background: none;
        color: lighten($primary, 10);
    }

    .select2-results__group {
        cursor: default;
        display: block;
        padding: 6px;
    }

    &.select2-container--open .select2-selection--single .select2-selection__arrow {
        z-index: 99;
    }
}

.product.info #product-review-container .select2-container--default .select2-selection--single{
    .select2-selection__rendered{
        padding-right: 25px;
    }
    .select2-selection__arrow{
        right: 0;
    }
}
