/*
* @subsection   RD Navbar
*
* @description  Describes style declarations for RD Navbar extension
*/
@import "../_variables.scss";
@import "../_mixins.scss";

$rd-navbar-default-color: $default;
$rd-navbar-contrast-color: $white;
$rd-navbar-hover-color: $primary;

$rd-navbar-shadow: 0 3px 5px 0 rgba(0, 0, 0, .25);

$rd-navbar-panel-height: 56px;

%rd-navbar-transition {
    transition: .3s all cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

%rd-navbar-hidden {
    display: none;
}

%rd-navbar-visible {
    display: block;
}

.rd-navbar--no-transition {
    &, * {
        transition: none !important;
    }
}

.rd-navbar-wrap {
    @extend %rd-navbar-transition;
}

.rd-navbar, .rd-navbar.rd-navbar--is-clone {
    @extend %rd-navbar-hidden;
}

.rd-navbar-fixed,
.rd-navbar-static,
.rd-navbar-fullwidth,
.rd-navbar-sidebar {
    @extend %rd-navbar-visible;

    .block {
        margin: 0;
    }
}

.rd-navbar-outer {
    color: $rd-navbar-default-color;
    background: $rd-navbar-contrast-color;
}

.rd-navbar-nav,
.rd-navbar-toggle{
    display: none;
}

.rd-navbar-brand{
    display: none;
    color: $rd-navbar-contrast-color;
}

.rd-navbar-collapse .switcher .actions .action{
    border: none;
    transition: none;
    &:after{
        background: transparent;
        transform: scale(1);
        opacity: 1;
        position: static;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        color: #7d7d7d;
    }
}

/*
* @subsection   RD Navbar Static
*
* @description  Describes style declarations for RD Navbar in static mode
*/

.rd-navbar-static {
    background: $content-bg;

    .rd-navbar-nav-wrap,
    .rd-navbar-search-wrap {
        display: table-cell;
        vertical-align: middle;
    }

    &.rd-navbar--is-clone {
        @extend %rd-navbar-transition;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        @include transform(translateY(-105%));
        z-index: 20;

        .rd-navbar-panel {
            display: none;
        }

        &.rd-navbar--is-stuck {
            @include transform(translateY(0));
        }
    }

    &.rd-navbar--is-stuck {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 120;
        background: $header-bg;
        border-bottom: 1px solid $bg-light;
        padding-top: 8px;
        padding-bottom: 8px;

        .rd-navbar-dropdown {
            margin-top: 10px;
        }

        .rd-navbar-panel {
            display: none;
        }

        .page-header__panel,
        .page-header__content {
            display: none;
        }
    }

    .rd-navbar-inner{
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .rd-navbar-collapse{
        display: block;
        float: right;
        margin-left: -15px;
        margin-top: 0;

        li{
            display: inline-block;
            margin-left: 15px;

            &.switcher-option{
                display: block;
                font-size: 14px;
            }
        }

        .header.links .link{
            &.compare,
            &.wishlist{
                span:before{
                    content: '(';
                }
                span:after{
                    content: ')';
                }
            }

            &.wishlist{
                display: none;
            }
        }
    }

    .rd-navbar-panel{
        @include clearfix;
        padding: 0;
    }

//    Brand

    .rd-navbar-brand{
        display: inline-block;
        float: left;
        > *{
            display: inline-block;
            vertical-align: middle;
        }

        > img{
            margin-right: 10px;
        }

        > a{
            font-size: 36px;
            line-height: 48px;

            span{
                display: none;
            }
        }
    }

//     Collapse-menu
    .rd-navbar-collapse-container{
        position: relative;
        margin-right: auto;
    }
    .rd-navbar-collapse{
        display: block;
        @extend %rd-navbar-transition;
        text-align: left;
        font-size: 16px;
        position: absolute;
        top: 100%;
        right: 50%;
        color: $rd-navbar-contrast-color;
        padding: 20px;
        z-index: 19;
        width: 250px;
        margin-right: -125px;
        box-shadow: none;
        visibility: hidden;
        transition: none !important;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $secondary-1;
        }

        &:after{
            content: '';
            @include triangle(top, 14px, 7px, $secondary-1);
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -7px;
        }

        @media (min-width: $screen-lg-min){
            padding: 33px 34px;
            width: 300px;
            margin-right: -210px;

            &:after {
                margin-left: -67px;
            }
        }

        li{
            position: relative;
            opacity: 1;
            display: block;
            padding: 4px;

            @media (min-width: $screen-lg-min){
                padding: 9px 16px 8px;
            }
        }

        &.active{
            visibility: visible;
            transition: none;
        }

        .switcher .dropdown .mage-dropdown-dialog {
            @include transform(translateY(0));
        }

        .switcher.currency,
        .switcher.language{
            display: block;
            margin: 0 16px 11px;

            .label{
                margin-right: auto;
            }
            .switcher-options{
                display: block;
            }
            .actions .action{
                color: $gray-lighten;
                background: none;
                padding: 0;
                @include make-flex;
                @include justify-content(space-between);
                @include align-items(flex-start);

                &:hover{
                    color: $primary;
                }
            }
            strong{
                font-weight: 300;

                &:not(.label) {
                    color: $gray;
                }
            }
            .dropdown > li{
                padding: 0;
            }
            .dropdown .mage-dropdown-dialog{
                width: 217px;
                margin-top: 10px;
                position: relative;
            }

            @media (min-width: $screen-sm-min) and (max-width: $screen-lg-min - 1) {
                margin-left: 4px;
            }
        }


        .links {
            margin-bottom: 10px;

            @media (min-width: $screen-lg-min){
                margin-bottom: 0;
            }

            li{
                display: block;
                margin: 0;

                a{
                    color: $gray-lighten;
                    transition: none;
                    &:hover{
                        color: $rd-navbar-hover-color;
                    }
                }
            }

            + .switcher{
                @media (min-width: $screen-lg-min){
                    margin-top: 28px;
                }
            }
        }
        
        @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
            margin-right: -160px;

            &:after {
                margin-left: -42px;
            }
        }
        @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
            margin-right: -170px;

            &:after {
                margin-left: -52px;
            }
        }
    }

    .welcome,
    .header-switchers{
        display: none;
    }

    .rd-navbar-collapse-toggle{
        display: block;
        position: relative;
        font-family: $default-icon-family;
        width: 40px;
        height: 40px;
        @include toggle-icons-via-rotation(40px, 28px, $secondary-1, '\e02e', '\e14c');
        z-index: 20;

        span:before {
            font-size: 19px;
            font-family: $out-icons;
        }

        &,
        span{
            transition: none;
        }

        &.active span,
        &:hover span{
            color: $primary;
        }
    }
}

body:not(.cms-index-index) .rd-navbar-static:not(.rd-navbar--is-stuck) {
    border-bottom: 10px solid $primary;
}

/*
* @subsection   RD Navbar Fixed
*
* @description  Describes style declarations for RD Navbar in fixed mode
*/

.rd-navbar-fixed{
    .rd-navbar-collapse{
        display: block;
        @extend %rd-navbar-transition;
        text-align: left;
        position: absolute;
        top: 100%;
        left: -25vw;
        color: $white;
        padding: 15px;
        z-index: 19;
        width: 100vw;
        font-size: 16px;
        box-shadow: 0 0 0 0 nth($rd-navbar-shadow, 5);
        visibility: hidden;
        overflow: hidden;
        transition: .3s box-shadow ease .2s;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            @include transform(scale(0));
            @extend %rd-navbar-transition;
        }

        li{
            position: relative;
            opacity: 0;
            transition: .3s all ease 0s;
        }


        > li > a{
            display: block;
            padding: 11px 16px;

            &:hover{
                background: lighten($rd-navbar-contrast-color, 75%);
            }
        }

        &.active{
            visibility: visible;
            box-shadow: $rd-navbar-shadow;

            li{
                font-size: 12px;
                transition: .3s all ease .2s;
                opacity: 1;
            }

            &:before{
                background: $secondary-1;
                @include transform(scale(35));
            }
        }
        .switcher.currency,
        .switcher.language{
            display: block;
            margin: 0;
            margin-top: 4px;

            .actions .action{
                background: none;
                padding: 0;
            }
            strong{
                font-size: 14px;
                font-weight: normal;
            }
            .dropdown .mage-dropdown-dialog{
                width: 217px;
                position: relative;
            }
        }

        .links {
            margin-top: 10px;

            li{
                display: block;
                margin: 0;
                font-size: 14px;

                a{
                    color: $rd-navbar-contrast-color;
                    &:hover{
                        color: $rd-navbar-hover-color;
                    }

                    .counter.qty{
                        &:before{
                            content: '(';
                        }
                        &:after{
                            content: ')';
                        }
                    }
                }

                &+ li {
                    margin-top: 8px;
                }
            }
        }

    }

    .switcher.currency,
    .switcher.language{
        display: none;
    }

    .wish-list-link-wrap{
        position: absolute;
        bottom: 0;
        right: 25vw;
        height: 100px;
        width: 25vw;

        @media (max-width: $screen-xs-min - 1){
            height: 40px;
        }

        a:before{
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -10px;
            color: $secondary-1;
            font-size: 20px;
            line-height: 20px;
            height: 20px;
            width: 20px;
            @include font-icon__pseudo('\e080', $md);

            @media (min-width: $screen-xs-min){
                @media (max-width: $screen-sm-min - 1){
                    font-size: 30px;
                    line-height: 30px;
                    height: 30px;
                    width: 30px;
                    margin-top: -15px;
                    margin-left: -15px;
                }
            }
        }
    }

    .switcher-label{
        display: none;
    }

    .rd-navbar-collapse-container{
        position: absolute;
        bottom: 0;
        left: 25vw;
        height: 100px;
        width: 25vw;

        @media (max-width: $screen-xs-min - 1){
            height: 40px;
        }
    }

    .rd-navbar-collapse-toggle{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        font-family: $default-icon-family;
        width: 48px;
        height: 48px;
        margin-top: -24px;
        margin-left: -24px;
        @include toggle-icons-via-rotation(50px, 19px, $secondary-1, '\e5d2', '\e14c');
        z-index: 20;

        span{
            font-size: 20px;
            @media (min-width: $screen-xs-min){
                @media (max-width: $screen-sm-min - 1){
                    font-size: 30px;
                }
            }
        }
    }

//    Toggles

    .rd-navbar-toggle {
        display: block;
        @include toggle-icons-via-rotation(40px, 20px, $white, '\e5d2', '\e5cd');
    }

    .navigation{
        height: 54px;
        margin: 0;
        background: $primary;
        .container{
            width: 100vw;
            max-width: 100vw;
            padding: 0;
        }
        .rd-navbar-toggle{
            position: absolute;
            top: 0;
            right: 0;
            left: auto;
            width: 100%;
            height: 54px;
            color: $white;
            transition: none;

            &.active{
                width: 40px;
                z-index: 99;
                transition: none;

                .rd-navbar-nav-title{
                    display: none;
                }
            }
        }
        .rd-navbar-toggle span{
            position: absolute;
            z-index: 99;
            top: 0;
            right: 0;
            font-family: $default-icon-family;
        }
        .rd-navbar-toggle span:before{
            display: none;
        }
        .rd-navbar-nav-title{
            display: block;
            visibility: visible;
            opacity: 1;
            color: $white;
        }
    }

    .rd-navbar-nav-wrap {
        @extend %rd-navbar-transition;
        position: absolute;
        z-index: 98;
        top: 0;
        left: 0;
        width: 100vw;
        padding: 0;
        color: $rd-navbar-default-color;
        background: $secondary-1;
        box-shadow: $rd-navbar-shadow;
        @include transform(translateX(-105%));

        &.active {
            @include transform(translateX(0));
        }
    }

    .rd-navbar-nav {
        display: block;
        height: 100%;
        overflow: auto;
        font-size: 16px;
        line-height: 26px;
        text-align: left;

        &:before,
        &:after {
            content: '';
            display: block;
            height: 8px;
        }

        li {
            > a {
                display: block;
                padding: 11px 80px 11px 40px;
                color: $white;
                position: relative;
                &:before{
                    @include font-icon__pseudo('\f101', $fa);
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    margin-top: -14px;
                }
            }

            &.opened > a,
            a:hover {
                background: $rd-navbar-hover-color;
                color: $rd-navbar-contrast-color;
            }

            &.active {
                > a {
                    background: darken($rd-navbar-hover-color, 10%);
                }
            }
        }
    }

    .rd-navbar-dropdown,
    .rd-navbar-megamenu {
        display: none;
    }

    .rd-navbar-submenu {
        position: relative;
        @extend %rd-navbar-transition;

        li > a {
            padding-left: 62px;

            &:before {
                left: 42px;
            }
        }

        .rd-navbar-dropdown li li > a,
        .rd-navbar-megamenu ul li li > a {
            padding-left: 48px;
        }

        &.opened {
            > .rd-navbar-dropdown,
            > .rd-navbar-megamenu {
                display: block;
            }
        }
    }

    .rd-navbar-submenu-toggle {
        @extend %rd-navbar-transition;
        position: absolute;
        right: 30px;
        z-index: 999;
        top: 0;
        width: 40px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        color: $rd-navbar-contrast-color;
        cursor: pointer;
        @include font-icon('\e313');

        &:before{
            padding-top: 12px;
        }

        &:hover {
            color: $rd-navbar-contrast-color;
        }
    }

    .rd-navbar-panel-canvas{
        display: none;
    }

    .rd-navbar-toggle{
        position: absolute;
        z-index: 17;
        top: 0;
        left: 0;
    }

//    Brand

    .rd-navbar-brand{
        display: block;
        text-align: left;
        position: fixed;
        top: 4px;
        left: 56px;
        right: 112px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 22px;
        line-height: 46px;
        height: 48px;
        z-index: 17;

        img{
            display: none;
        }

        a{
            display: inline-block;
        }
    }
}
