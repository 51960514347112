
/*
* @section      Custom pages
* @description  This section holds specific style for Custom pages
*/

.cms-page-view {
    @for $i from 1 through 6 {
        h#{$i},
        heading-#{$i}{
            text-transform: uppercase;
            margin-bottom: 1em;
        }
    }

    .page-title,
    .section-title{
        margin-top: 2em;
    }
}

.custom-page{
    table{
        margin-bottom: 4em;

        &.responsive{
            @media (max-width: $screen-sm-min - 1) {
                thead {
                    display: none;
                }
                tbody td {
                    display: block;
                    text-align: center;
                    &:first-child{
                        font-size: 14px;
                        line-height: 1.5;
                        font-weight: 600;
                        color: $secondary-1;
                        background: $bg-dark;
                        padding: 1em 1em .8em;
                        &:before{
                            display: none;
                        }
                    }
                }
                tbody td:before {
                    content: attr(data-th);
                    display: block;
                    text-align: center;
                    font-weight: 600;
                }
            }
        }
    }
}

// Home
//------------------------------------

.cms-index-index{
    .page-header,
    .page-main{
        margin-bottom: 0;
    }

    .page-main {
        padding-bottom: 15px;
    }

    .block-static-block{
        margin-bottom: 0;
    }

// Slider
    .sp-buttons{
        padding-top: 0;
        margin-top: -25px;

        @media (min-width: $screen-xs-min){
            margin-top: -40px;
        }
        @media (min-width: $screen-sm-min) {
            margin-top: -90px;
        }
    }
    .sp-button{
        border-color: $white;

        &.sp-selected-button{
            border-color: $primary;
            background-color: $primary;
        }

        @media (max-width: $screen-xs-min){
            width: 10px;
            height: 10px;
        }
    }

    .sp-layer{
        @media (max-width: 660px - 1){
            &.sp-layer-title {
                top: -45px !important;
                transform: scale(0.35781) !important;
            }
            &.sp-layer-description {
                height: 0!important;
                width: 0!important;
                margin: 0!important;
            }
            &.sp-layer-btn {
                top: 35px !important;
                transform: scale(0.49781) !important;
            }
        }
    }

    .btn-slide{
        transform: scale(1) translate3d(0px, 0px, 0px) !important;
        @include make-flex;
        @include justify-content(center);
        @include align-items(center);
    }

    .btn-slide .action{
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        padding: 18px 51px;
        transition: .3s ease !important;
        &:hover{
            background: #e7c159;
        }

        @media (max-width: $screen-xl-min - 101){
            padding: 14px 30px;
        }
        @media (max-width: $screen-lg-min){
            padding: 10px 25px;
        }
    }

    @media (max-width: $screen-sm-min){
        .sp-layer{
            &.btn-slide{
                .action{
                    font-size: 14px;
                    font-weight: 400;
                    padding: 9px 25px;
                }
            }
        }
    }
    @media (max-width: $screen-xs-min){
        .sp-layer{
            &.slide-1-1,
            &.slide-2-1,
            &.slide-3-1{
                transform: scale(0.32) !important;
            }
            &.slide-1-2,
            &.slide-2-2,
            &.slide-3-2{
                transform: scale(0.32) !important;
            }
            &.slide-1-3,
            &.slide-2-3,
            &.slide-3-3{
                transform: scale(0.20) !important;
            }
        }

        .sp-layer.btn-slide{
            text-align: center;
            .action{
                display: inline-block;
                font-size: 12px;
                padding: 3px 10px;
            }
        }
    }

//  Banners
    .custom-slider {
        padding: 0;

        .owl-carousel {
            .owl-height {
                max-height: 650px;
            }

            .owl-stage-outer {
                padding-top: 0;
                padding-bottom: 0;
                max-height: 650px;
            }

            .owl-item {
                .banner-title {
                    color: #FFF;
                    font-size: 64px;
                }

                .banner-description {
                    color: #FFF;
                    font-size: 28px;
                    margin-top: 10px;
                }

                .buttons {
                    .primary {
                        display: inline-block;
                        padding: 8px 20px;
                        text-align: center;
                        min-width: 160px;
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        border: 2px solid;
                        letter-spacing: .03em;
                        transition: .3s all ease;
                        border-color: #7bc0c9;
                        background-color: #7bc0c9;
                        color: #FFF;
                    }
                }
            }
        }
    }

    .banners-1{
        ul{
            @include make-flex;
            @include justify-content(space-between);
            @include align-items(center);
            @include flex-wrap(wrap);

            li{
                @include flex-basis(100%);

                @media (min-width: $screen-xs-min){
                    @include flex-basis(50%);
                }
            }
        }
    }
    .banner-1-1,
    .banner-1-2{
        > div{
            position: relative;
        }
    }
    .banner-img-in-1{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 216px;
        height: 216px;
        margin-top: -108px;
        margin-left: -108px;
        z-index: 3;

        @media (min-width: $screen-xs-min){
            width: 108px;
            height: 108px;
            margin-top: -54px;
            margin-left: -54px;
        }
        @media (min-width: $screen-sm-min){
            width: 216px;
            height: 216px;
            margin-top: -108px;
            margin-left: -108px;
        }
        @media (min-width: $screen-md-min){
            width: 324px;
            height: 324px;
            margin-top: -162px;
            margin-left: -162px;
        }
    }

    .banner-img-inner-2{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 200px;
        margin-top: -100px;
        margin-left: -100px;
        z-index: 4;
        overflow: hidden;
        background: none;

        @media (min-width: $screen-xs-min){
            width: 100px;
            height: 100px;
            margin-top: -50px;
            margin-left: -50px;
        }
        @media (min-width: $screen-sm-min){
            width: 200px;
            height: 200px;
            margin-top: -100px;
            margin-left: -100px;
        }
        @media (min-width: $screen-md-min){
            width: 302px;
            height: 300px;
            margin-top: -150px;
            margin-left: -151px;
        }
    }
    .banner-1-1 .banner-img-inner-2{
        background-image: url('../images/banner-1-2-1.png'), url('../images/banner-1-2-2.png');
        background-position: 0px -94px, 0px -94px;
        background-size: cover;
    }
    .banner-1-1 .banner-img-inner-1:hover .banner-img-inner-2{
        -webkit-animation: snow-1 5s linear infinite;
        -moz-animation: snow-1 5s linear infinite;
        -ms-animation: snow-1 5s linear infinite;
        animation: snow-1 5s linear infinite;

        @keyframes snow-1 {
            0% {background-position: 0px -94px, 0px -94px;}
            100% {background-position: 0px 429px, 0px 952px;}
        }
        @-moz-keyframes snow-1 {
            0% {background-position: 0px -94px, 0px -94px;}
            100% {background-position: 0px 429px, 0px 952px;}
        }
        @-webkit-keyframes snow-1 {
            0% {background-position: 0px 94px, 0px 94px;}
            100% {background-position: 0px 429px, 0px 952px;}
        }
        @-ms-keyframes snow-1 {
            0% {background-position: 0px -94px, 0px -94px;}
            100% {background-position: 0px 429px, 0px 952px;}
        }
    }
    .banner-1-2 .banner-img-inner-2{
        background-image: url('../images/banner-2-2-1.png'), url('../images/banner-2-2-2.png');
        background-position: 0px -75px, 0px -75px;
        background-size: cover;
    }
    .banner-1-2 .banner-img-inner-1:hover .banner-img-inner-2{
        -webkit-animation: snow-2 5s linear infinite;
        -moz-animation: snow-2 5s linear infinite;
        -ms-animation: snow-2 5s linear infinite;
        animation: snow-2 5s linear infinite;

        @keyframes snow-2 {
            0% {background-position: 0px -75px, 0px -75px;}
            100% {background-position: 0px 405px, 0px 885px;}
        }
        @-moz-keyframes snow-2 {
            0% {background-position: 0px -75px, 0px -75px;}
            100% {background-position: 0px 405px, 0px 885px;}
        }
        @-webkit-keyframes snow-2 {
            0% {background-position: 0px -75px, 0px -75px;}
            100% {background-position: 0px 405px, 0px 885px;}
        }
        @-ms-keyframes snow-2 {
            0% {background-position: 0px -75px, 0px -75px;}
            100% {background-position: 0px 405px, 0px 885px;}
        }
    }

    .banner-img-in-3{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 160px;
        height: 160px;
        margin-top: -80px;
        margin-left: -80px;
        z-index: 5;

        @media (min-width: $screen-xs-min){
            width: 80px;
            height: 80px;
            margin-top: -40px;
            margin-left: -40px;
        }
        @media (min-width: $screen-sm-min){
            width: 160px;
            height: 160px;
            margin-top: -80px;
            margin-left: -80px;
        }
        @media (min-width: $screen-md-min){
            width: 240px;
            height: 240px;
            margin-top: -120px;
            margin-left: -120px;
        }
    }

    //===========================

    .banner-img-31{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 39.08333vw;
        height: 38.25vw;
        margin-top: -19.541665vw;
        margin-left: -19.125vw;
        z-index: 3;

        @media (min-width: $screen-lg-min){
            width: 469px;
            height: 459px;
            margin-top: -234px;
            margin-left: -229px;
        }
        @media (max-width: $screen-sm-min - 1){
            width: 300px;
            height: 293px;
            margin-top: -146px;
            margin-left: -150px;
        }
    }
    .banner-img-inner-3{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 37.25vw;
        height: 36.16667vw;
        margin-top: -18.083335vw;
        margin-left: -18.083335vw;
        z-index: 4;
        overflow: hidden;
        background: none;

        @media (min-width: $screen-lg-min){
            width: 447px;
            height: 434px;
            margin-top: -223px;
            margin-left: -217px;
        }
        @media (max-width: $screen-sm-min - 1){
            width: 286px;
            height: 277px;
            margin-top: -138px;
            margin-left: -143px;
        }
    }
    .banner-3 .banner-img-inner-3{
        background-image: url('../images/banner-3-2-1.png'), url('../images/banner-3-2-2.png');
        background-position: 0px -119px, 0px -119px;
        background-size: cover;
    }
    .banner-3 .banner-3-inner:hover .banner-img-inner-3{
        -webkit-animation: snow-3 5s linear infinite;
        -moz-animation: snow-3 5s linear infinite;
        -ms-animation: snow-3 5s linear infinite;
        animation: snow-3 5s linear infinite;

        @keyframes snow-3 {
            0% {background-position: 0px -119px, 0px -119px;}
            100% {background-position: 0px 566px, 0px 1251px;}
        }
        @-moz-keyframes snow-3 {
            0% {background-position: 0px -119px, 0px -119px;}
            100% {background-position: 0px 566px, 0px 1251px;}
        }
        @-webkit-keyframes snow-3 {
            0% {background-position: 0px -119px, 0px -119px;}
            100% {background-position: 0px 566px, 0px 1251px;}
        }
        @-ms-keyframes snow-3 {
            0% {background-position: 0px -119px, 0px -119px;}
            100% {background-position: 0px 566px, 0px 1251px;}
        }
    }
    .banner-img-33{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30.16667vw;
        height: 30.16667vw;
        margin-top: -15.083335vw;
        margin-left: -15.083335vw;
        z-index: 5;

        @media (min-width: $screen-lg-min){
            width: 362px;
            height: 362px;
            margin-top: -181px;
            margin-left: -181px;
        }
        @media (max-width: $screen-sm-min - 1){
            width: 231px;
            height: 231px;
            margin-top: -115px;
            margin-left: -115px;
        }
    }
}

//  Featured Products

.block.featured{
    margin-top: 65px;
    margin-bottom: 65px;

    .block-title{
        padding-right: 15px;
        padding-left: 15px;
        text-align: center;
        margin-bottom: 60px;

        strong{
            font-size: 22px;
            line-height: 1.2;
            font-weight: 400;

            @media (min-width: 480px) {
                font-size: 26px;
            }
            @media (min-width: $screen-sm-min) {
                font-size: 34px;
            }
        }
    }

    @media (min-width: $screen-sm-min){
        margin-top: 75px;
        margin-bottom: 75px;
    }
    @media (min-width: $screen-lg-min){
        margin-top: 152px;
        margin-bottom: 80px;

        .block-title {
            margin-bottom: 40px;
        }
    }
}

.block.featured.featured-prods {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    background: $gray-light;

    .product-item-img_box {
        background: $white;

        .product-item-inner .actions-secondary a,
        .featured-prods .product-item-img_box .product-item-inner .actions-secondary a {
            background: $white;
        }
    }

    .products-grid .product-item-info:hover .product-item-img_box,
    .product-item-info:hover .product-item-img_box {
        border-color: $white;
    }

    .block-title strong {
        color: $black;
    }

    @media (min-width: $screen-lg-min) {
        padding-top: 150px;
        padding-bottom: 123px;
    }
}

.block.featured .block-content .products-grid{
    position: relative;
    z-index: 3;

    .owl-carousel{
        margin: 0 auto;
        max-width: 1286px;
        padding-right: 15px;
        padding-left: 15px;

        .owl-wrapper-outer {
            overflow: hidden;
            padding-top: 12px;
            padding-right: 15px;
            width: 100%;
        }

        .owl-controls {
            position: absolute;
            top: -50px;
            right: 0;

            .owl-next,
            .owl-prev {
                display: inline-block;
                cursor: pointer;
            }
        }

        @media (min-width: $screen-xl-min) {
            position: static;
        }
        @media (min-width: $screen-xl-min - 100px){
            padding-right: 0;
            padding-left: 0;
            width: 83.33333%;
        }

        .owl-wrapper-outer{
            @media (min-width: $screen-xl-min - 200px){
                position: static;
            }
            @media (min-width: $screen-lg-min) {
                .product-item{
                    padding-right: 10px;
                    padding-left: 10px;
                }
            }
        }
        .owl-controls{
            z-index: -1;
            left: 15px;
            right: 15px;
            top: -35px;

            @media (min-width: $screen-xl-min - 200px){
                margin-top: -90px;
                top: 50%;
                left: -100px;
                right: -100px;
            }
            @media (min-width: $screen-xl-min) {
                left: 153px;
                right: 153px;
            }
        }
        .owl-buttons{
            @include make-flex;
            @include justify-content(center);
            @include align-items(center);

            @media (min-width: $screen-xs-min){
                @include justify-content(space-between);
            }

            [class^="owl-"]{
                width: 35px;
                height: 35px;
                padding: 2px;
                text-align: center;
                &:before{
                    display: block;
                    font-size: 36px;
                    line-height: 1;
                    color: $black;
                }
                &:hover:before{
                    color: $primary;
                }

                @media (min-width: $screen-xl-min - 200){
                    width: 70px;
                    height: 70px;
                    padding: 17px 7px;
                    box-shadow: 3px 3px 62px rgba(0,0,0,.09);
                    background: $white;

                    &, &:before {
                        transition: .3s all ease;
                    }

                    &:hover {
                        box-shadow: 3px 3px 62px rgba(0,0,0,.15);
                    }
                }
            }
            .owl-prev{
                padding-left: 0;
                @include font-icon('\e5cb');
            }
            .owl-next{
                padding-right: 0;
                @include font-icon('\e5cc');
            }
        }
    }
}

.block.featured.featured-prods .product-item-img_box .product-item-inner .tocart,
.block.featured.featured-prods .product-item-img_box .product-item-inner .actions-secondary a{
    @media (min-width: $screen-lg-min){
        @media (max-width: $screen-xl-min - 101px){
            width: 50px;
            height: 50px;
        }
    }
}

.block.widget.featured .products-grid .owl-carousel .owl-item .product-item {
    width: 100%;
}

.block.widget.featured .product-item {
    position: relative;
    max-width: 100%;
}

.block.widget.featured .products-grid .product-items .product-item-photo{
    @media (max-width: $screen-xs-min - 1){
        width: auto;
    }
}


// About us
//------------------------------------

.cms-about-us .column.main{
    .section-title{
        padding-top: 2em;
        border-top: 1px solid $border-color;
    }
}

.cms-about-us .page-main {
    padding-bottom: 60px;
}

.skills {
    color: $primary;
    .value {
        font-size: 56px;
        line-height: 1.519em;
        font-weight: 700;
    }
    .term {
        font-size: 16px;
        text-transform: uppercase;
    }
}

.big-circle-icon {
    .cms-about-us & {
        @media (max-width: 767px) {
            margin-top: 2rem;
        }
    }
}

.team > div[class*='col-'] {
    @media (max-width: 767px) {
        margin-bottom: 2rem;
    }

    img{
        margin-bottom: 1rem;
    }
}

.cms-customer-service {
    .terms-conditions {
        h4 {
            &.small-icon {
                &:before {
                    padding-right: 15px;
                    vertical-align: bottom;
                }
            }
        }
    }
}

// Privacy policy
//------------------------------------

.change-message{
    padding: 1em;
    margin-bottom: 30px;
    border: 1px solid #bce8f1;
    border-radius: 2px;
    color: #31708f;
    background-color: #d9edf7;
}

dl.custom-page {
    dd {
        font-style: normal;
        margin-bottom: 2em;
    }
}

// Testimonials in About Us
//------------------------------------

.testimonials {
    padding-bottom: 2em;
    blockquote {
        padding: 10px 20px;
        cite {
            display: block;
            text-align: center;
            margin: 0 auto 0.5em;
            font-weight: 500;
            text-transform: uppercase;
            color: $primary;
        }
        q {
            display: block;
            padding: 0 5%;
        }
    }
    &.owl-testimonials {
        .owl-nav {
            top: auto;
            bottom: 0;
            right: 50%;
            margin-right: -58px;
        }
        .owl-prev,
        .owl-next {
            padding: 8px 7px 0;
            height: 2.5em;
            &:before {
                font-size: 2.5em;
                line-height: 1;
                color: $secondary-1;
                transition: .3s ease;
            }
            &:hover {
                &:before {
                    color: $primary;
                }
            }
        }
        .owl-prev {
            @include font-icon('\e408');
        }
        .owl-next {
            @include font-icon('\e409');
        }
    }
}

.big-circle-icon{
    display: block;
    width: 122px;
    height: 122px;
    margin: 0 auto 1rem;
    border-radius: 50%;
    border: 2px solid $primary;
    text-align: center;
    &:before{
        color: $primary;
        font-size: 66px !important;
        line-height: 122px;
        width: 100%;
    }
}

.medium-icon{
    margin: 0 auto 1rem;
    text-align: center;
    width: 100%;
    &:before{
        font-size: 60px;
        line-height: 1em;
        color: $primary;
    }
}

.small-icon{
    width: 100%;
    &:before{
        font-size: 40px;
        line-height: inherit;
        color: $primary;
    }
}

//404 page
//======================================

.cms-no-route .page-title{
    color: $default;
}

.not-found-page{
    color: $default;

    .image404 svg{
        .svg_layer1{
            fill: $black;
        }
        .svg_layer2{
            fill: $default;
        }
        .svg_layer3{
            fill: $primary;
        }
    }

    .disc li,
    dd{
        font-style: normal;
    }

    .not-found-page_top{
        @include make-flex;
        @include flex-direction(column);
        @include align-items(center);
        margin-bottom: 30px;

        img{
            width: auto;
            max-width: 100%;
            margin-bottom: 40px;
        }

        > div{
            @include make-flex;
            @include justify-content(center);
            @include align-items(flex-end);
            @include flex-wrap(wrap);
            text-align: center;
        }
        strong{
            font-size: 20px;
            line-height: 1;
            font-weight: 600;
            padding-right: 20px;

            @media (min-width: $screen-sm-min){
                font-size: 50px;
                line-height: 1;
            }

            @media (min-width: $screen-md-min){
                font-size: 84px;
                line-height: 84px;
            }
        }
        span{
            font-size: 16px;
            line-height: 20px;
            font-weight: 300;

            @media (min-width: $screen-sm-min){
                font-size: 30px;
                line-height: 38px;
            }

            @media (min-width: $screen-md-min){
                font-size: 55px;
                line-height: 68px;
            }
        }
    }

    .not-found-page_bottom{
        dt{
            font-size: 12px;
            line-height: 1;
            font-weight: 400;
            text-align: center;
            margin-bottom: 30px;

            @media (min-width: $screen-md-min){
                font-size: 20px;
            }
        }
        dd{
            text-align: center;

            a{
                display: inline-block;
                font-size: 14px;
                line-height: 22px;
                font-weight: 600;
                padding: 12px 25px;
                @include font-icon('\e88a');
                @include button-block__primary;
                &:before{
                    padding-right: 5px;
                    font-size: 22px;
                    vertical-align: sub;
                }
            }
        }
    }
}

// Create account page
//------------------------------------

.customer-account-create{
    .field .checkbox{
        margin-top: .5rem;
    }
}

// Search terms
//------------------------------------

.search-term-popular {
    ul.search-terms {
        @include clearfix;
        li {
            float: left;
            margin-right: 15px;
            font-size: 24px;
        }
    }
}

// Advanced Search
//------------------------------------

.search.advanced{
    @media (min-width: $screen-lg-min){
        max-width: 50%;
    }
    @media (min-width: $screen-md-min){
        max-width: 75%;
    }

    legend.legend{
        padding-bottom: 2em;
        border-bottom: 1px solid $border-color;
        margin-bottom: 2em;
        width: 100%;
    }

    .price .range.price{
        @include make-flex;
        @include justify-content(space-between);
        @include align-items(center);
        position: relative;

        >.field{
            @include flex-basis(48%);
        }

        .addafter{
            position: absolute;
            top: 0;
            left: calc(100% + 6px);
            line-height: 28px;
            margin-right: 0;

            @media (max-width: $screen-sm-min - 1){
                left: auto;
                right: 0;
            }
        }

        .separator{
            line-height: 28px;
            padding: 7px 0;
            margin-bottom: 1rem;
        }

        .addon{
            @media (max-width: $screen-sm-min - 1){
                padding-right: 45px;
            }
        }
    }

    .actions-toolbar{
        padding-top: 1.5rem;
        border-top: 1px solid $border-color;
    }
}

// Advanced Search
//------------------------------------

.search-terms{
    ul{
        @include make-flex;
        @include justify-content(flex-start);
        @include align-items(center);

        li{
            margin: 0 5px 5px 0;
            border: 1px solid $border-color;
        }
        a{
            padding: 5px;
            color: $default;
            &:hover{
                background: $bg-light;
            }
        }
    }
}

// Orders and Returns
//------------------------------------

.form-orders-search{
    legend.admin__legend{
        padding-bottom: 2em;
        border-bottom: 1px solid $border-color;
        margin-bottom: 2em;
        width: 100%;
    }
    .actions-toolbar{
        padding-top: 1.5rem;
        border-top: 1px solid $border-color;
    }
    #oar-email{
        margin-bottom: 40px;
    }
}

// Shipping and delivery
//=========================================

.shipping-delivery-page{
    p{
        margin-bottom: 20px;

        @media (min-width: $screen-md-min){
            margin-bottom: 30px;
        }
    }

    .heading-3{
        text-transform: uppercase;
        color: $primary;
        margin-bottom: 10px;
    }

    ul li{
        padding-left: 15px;
        position: relative;
        margin-bottom: 0.8em;
        &:before{
            content: '.';
            font-size: 35px;
            line-height: inherit;
            color: $primary;
            position: absolute;
            top: -8px;
            left: 0;
        }
        &:last-child{
            margin-bottom: 1.5em;
        }
    }

    .shipping-delivery-page__border{
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $border-color;

        @media (min-width: $screen-md-min){
            padding-bottom: 30px;
            margin-bottom: 40px;
        }

        span:not(.heading-3){
            margin-bottom: 15px;
            display: block;
        }
    }

    .shipping-delivery-page_shipping-icon{
        @include font-icon('\e558')
    }
    .shipping-delivery-page_track-icon{
        @include font-icon('\e8b6')
    }
    .shipping-delivery-page_order-icon{
        @include font-icon('\e614')
    }
    .shipping-delivery-page_shipping-icon,
    .shipping-delivery-page_track-icon,
    .shipping-delivery-page_order-icon{
        &:before{
            font-size: 40px;
            color: $primary;
            padding-right: 15px;
        }
    }
}

// FAQ page
//=========================================

.faq-page{
    #faq-accordion{
        .accordion-trigger{
            margin-bottom: 30px;
            padding-right: 30px;
            position: relative;
            &:before{
                content: 'q';
                text-transform: uppercase;
                font-size: 20px;
                line-height: 1;
                color: $white;
                background: $primary;
                text-align: center;
                padding: 15px 0;
                width: 50px;
                display: inline-block;
                margin-right: 15px;
                cursor: pointer;
            }
            &:after{
                position: absolute;
                font-size: 20px;
                line-height: 1;
                top: 17px;
                right: 0;
                cursor: pointer;
                @include font-icon__pseudo('\e313');
            }
            &.open:after{
                @include font-icon__pseudo('\e316');
            }

            @media (min-width: $screen-sm-min) {
                display: inline-block;
            }

            span{
                cursor: pointer;
                font-size: 20px;
                line-height: 50px;
                color: $primary;
            }
        }

        .accordion-content{
            display: none;
            p{
                padding-bottom: 50px;
                margin-bottom: 0;

                &:before{
                    content: 'a';
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 1;
                    color: $primary;
                    background: lighten(saturate(adjust_hue($bg-dark, 0), 0%), 1.5686274509804008%);;
                    text-align: center;
                    padding: 15px 0;
                    width: 50px;
                    display: inline-block;
                    margin: 5px 15px 15px 0;
                    float: left;
                }
            }
        }
    }
}

// Support page
//=======================================

.support-page{
    .text-block{
        padding-bottom: 20px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 30px;
    }
    .boxes-block{
        li a{
            transition: 0s;
        }
        li div{
            background: transparent;
            border: 1px solid $primary;
            margin-bottom: 30px;
            padding: 87px 10px;
            transition: .3s ease;
            @include make-flex;
            @include justify-content(center);
            @include align-items(center);
            &:hover{
                background: $primary;

                span,
                span:before{
                    color: $white;
                }
            }

            @media (min-width: $screen-lg-min) {
                margin-bottom: 50px;
            }

            span{
                font-size: 20px;
                line-height: 1;
                @include make-flex;
                @include flex-direction(column);
                @include justify-content(center);
                @include align-items(center);
                &:before{
                    color: $primary;
                    font-size: 70px;
                    line-height: 1;
                }
            }
        }

        .support-page__archive-icon{
            @include font-icon('\e80c');
        }
        .support-page__bubbles-icon{
            @include font-icon('\e7fc');
        }
        .support-page__bubble-question-icon{
            @include font-icon('\e8af');
        }
        .support-page__envelope-icon{
            @include font-icon('\e0be');
        }
    }
}

// Guarantee page
//=======================================+
.guarantee-page{
    p{
        margin-bottom: 20px;
    }
    h3{
        font-size: 35px;
        line-height: 1;
        color: #000;
        font-weight: 600;
    }
    h4{
        font-size: 20px;
        line-height: 1;
        font-weight: 400;
        color: $secondary-1;
        @include font-icon('\e86c');
        &:before{
            font-size: 30px;
            line-height: inherit;
            color: $primary;
            padding-right: 10px;
        }
    }
    ul{
        padding: 10px 0 20px;

        li{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .text-block{
        padding-bottom: 20px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 30px;
        &:last-child{
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

// Secure payment page
//=======================================+
.secure-payment-page{
    h4{
        color: $primary;
        @include font-icon('\e86c');
        &:before{
            font-size: 1.5em;
            line-height: inherit;
            color: $primary;
            padding-right: 10px;
            vertical-align: bottom;
        }
    }
    ul{
        padding: 15px 0 20px;

        li{
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .secure-payment_icons{
        ul{
            @include clearfix;

            li{
                float: left;
                margin-right: 28px;
            }

            a{
                font-size: 41px;
                line-height: 1;
                color: $primary;
                &:hover{
                    color: $secondary-1;
                }
            }
        }
    }
}

// Terms & Conditions
//=======================================

.terms-conditions-page{
    p{
        margin-bottom: 28px;
    }
    ul{
        h4{
            color: $primary;
            @include font-icon('\e039');
            &:before{
                font-size: 1.5em;
                line-height: inherit;
                color: $primary;
                padding-right: 10px;
                vertical-align: bottom;
            }
        }
    }
}

// Template Settings
//------------------------------------

.cms-template-settings{
    &.cms-page-view h3{
        font-size: 20px;
        line-height: 1;
        text-transform: none;
        margin-bottom: 2em;

        em{
            color: $secondary-1;
        }
    }

    .data-table{
        margin-bottom: 40px;
        th,td{
            word-break: break-all;
        }
    }
}

// Catalog Category View
//------------------------------------

.catalog-category-view {
    .category-image {
        margin-bottom: 2rem;

        img {
            width: 100%;
        }
    }
    .tollbar-bottom {
        margin-top: 20px;
    }
    .category-view {
        display: block;
    }
    .category-view-bottom {
        display: block;
        padding-top: 50px;
        padding-bottom: 30px;
        background-color: #EBF0F1;

        @media (min-width: $screen-lg-min) {
            .container {
                max-width: 1820px;
                width: 83.33333%;
            }
        }
    }
}

.modal-popup.confirm .modal-content a {
    color: $primary;

    &:hover,
    &:focus {
        color: $secondary-1;
    }
}