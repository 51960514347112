@import "../../_mixins";
@import "../../_variables";

.select2-container {
    box-sizing: border-box;

    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    max-width: 250px;

    .form-orders-search & {
        max-width: none;

    }

    .select2-selection--single {
        box-sizing: border-box;

        cursor: pointer;
        display: block;

        height: 50px;

        user-select: none;
        -webkit-user-select: none;

        .select2-selection__rendered {
            display: block;
            padding-left: 20px;
            padding-right: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (min-width: $screen-md-min){
                padding-left: 30px;
                padding-right: 40px;
            }
        }

        .select2-selection__clear {
            position: relative;
        }
    }

    &[dir="rtl"] {
        .select2-selection--single {
            .select2-selection__rendered {
                padding-right: 8px;
                padding-left: 20px;
            }
        }
    }

    .select2-selection--multiple {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        min-height: 32px;
        user-select: none;
        -webkit-user-select: none;

        .select2-selection__rendered {
            display: inline-block;
            overflow: hidden;
            padding-left: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .select2-search--inline {
        float: left;

        .select2-search__field {
            box-sizing: border-box;
            border: none;
            font-size: 100%;
            margin-top: 5px;
            padding: 0;

            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }
    }
}

.select2-dropdown {
    background-color: #faf9f9;
    margin-top: 1px;
    font-family: $default-font-family;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 98;
    &:after{
        content: '';
        position: absolute;
        z-index: -1;
        right: 0;
        left: 0;
        bottom: 100%;
        height: 25px;
        background-color: #faf9f9;
        box-shadow: 2px 2px 5px 0 transparentize($black, .9);
    }
}

.select2-hidden-accessible + .select2-container--open{
    z-index: 99;
}

.select2-results {
    display: block;
    padding: 15px 20px;
    position: relative;
    z-index: 1;
    &:after{
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        height: 25px;
        background-color: #faf9f9;
    }
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    padding: 0 0 4px;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 2px;

    user-select: none;
    -webkit-user-select: none;

    &[aria-selected] {
        cursor: pointer;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 2px 2px 5px 0 transparentize($black, .9);
}

.select2-search--dropdown {
    display: none;
    padding: 4px;

    .select2-search__field {
        padding: 4px;
        width: 100%;
        box-sizing: border-box;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    &.select2-search--hide {
        display: none;
    }
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;

    // styles required for IE to work

    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

@import "default-theme";

