/*
*   TM_AjaxCatalog
*/

// Local vars

$screen-res: 1024px;

// Main Styles

.swatch-option-tooltip {
    
    visibility: hidden; 

    @media (max-width: $screen-sm-min - 1) {
        .image {
            height: auto;
            width: auto;
        }

        .title {
            min-height: 0;
        }
    }
    @media (min-width: $screen-res) {
        visibility: visible;
    }
}