.block.newsletter{

    .field{
        margin-bottom: 0;
        padding-bottom: 1.5rem;
        position: relative;
    }

    .field.newsletter .control{
        @media (min-width: $screen-sm-min){
            margin-left: 5%;
            max-width: 90%;
        }
    }

    #newsletter{
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 140px 10px 30px;
        border: none;
        height: 54px;

        @media (min-width: $screen-xs-min) {
            padding-right: 170px;
        }
        @media (min-width: $screen-sm-min){
            padding-right: 180px;
            width: calc(100% - 1px);
            max-width: 360px;
        }
        @media (min-width: $screen-md-min) {
            padding-right: 30px;
        }
        @media (max-width: $screen-xs-min - 1) {
            padding-right: 30px;
            min-width: 270px;
        }
    }

    input{
        margin-bottom: 0;
        color: $secondary-1;
        background: $white;

        &::-webkit-input-placeholder {
            color: $secondary-1;
        }
        &::-moz-placeholder {
            color: $secondary-1;
        }
        &:-ms-input-placeholder {
            color: $secondary-1;
        }
        &:-moz-placeholder {
            color: $secondary-1;
        }
    }

    #newsletter-error{
        padding: 0;

        @media (min-width: $screen-xs-min){
            position: absolute;
            bottom: -20px;
            left: 0;
        }
        @media (min-width: $screen-sm-min){
            left: 60px;
        }
    }
}

.page-footer {
    .footer-top {
        padding-bottom: 15px;
        background: $white;

        .container {
            max-width: none;
            min-width: 0;
            padding-right: 0;
            padding-left: 0;

            > .widget.block.block-static-block {
                background: $primary;

                .contact-info-wrap {
                    padding: 50px 25px 60px;
                    font-size: 18px;
                    line-height: 1.2;
                    color: $secondary-1;
                    margin-bottom: 0;

                    .contact-info {
                        dt, dd {
                            display: inline-block;
                        }

                        dt {
                            font-weight: 400;
                        }
                        dd {
                            padding-left: 0;
                            font-style: normal;
                        }
                        
                        a {
                            color: $secondary-1;
                            
                            &:hover,
                            &:focus {
                                color: $white;
                            }
                        }

                        > p {
                            margin-bottom: 24px;
                        }
                    }

                    .icon {
                        display: inline-block;
                    }

                    * + .contact-info {
                        margin-top: 17px;
                    }

                    * + .icon {
                        margin-top: 10px;
                    }

                    * + .heading-3 {
                        margin-top: 37px;
                    }

                     .contact-info-wrap-inner > p  {
                         margin-bottom: 0;
                     }

                    .heading-4, .heading-3 {
                        font-weight: 400;
                        line-height: 1.2;
                        color: $white;
                        text-transform: uppercase;
                    }

                    .heading-4 {
                        font-size: 26px;

                        @media (min-width: $screen-md-min) {
                            font-size: 30px;
                        }
                        @media (min-width: $screen-lg-min) {
                            font-size: 36px;
                        }
                    }

                    .heading-3 {
                        font-size: 22px;

                        @media (min-width: $screen-md-min) {
                            font-size: 26px;
                        }
                        @media (min-width: $screen-lg-min) {
                            font-size: 30px;
                        }
                    }

                    .icon {
                        font-size: 42px;
                        color: $white;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                    }

                    @media (min-width: $screen-md-min) {
                        font-size: 22px;
                    }
                    @media (min-width: $screen-lg-min) {
                        padding: 81px 25px 71px;
                        font-size: 24px;
                    }
                    @media (min-width: $screen-xl-min) {
                        padding-right: 100px;
                        padding-bottom: 107px;
                        padding-left: 71px;
                    }
                }
            }

            > .widget.block.block-static-block {
                margin-bottom: 15px;
            }

            @media (min-width: $screen-sm-min) {
                @include make-flex;
                @include justify-content(space-between);
                @include align-items(stretch);

                > .widget.block.block-static-block {
                    @include flex-basis(34.5%);
                    margin-right: 7.5px;
                    margin-bottom: 0;
                }
                .block.newsletter {
                    @include flex-basis(65.5%);
                    margin-bottom: 0;
                    margin-left: 7.5px;
                }
            }
        }
    }

    .block.newsletter{
        display: none;
        min-height: 350px;
        background: url("../images/newsletter-bg.jpg");
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: center center;
        @include justify-content(center);
        @include align-items(center);
        text-align: center;
        padding: 15px;
        margin-bottom: 0;

        .newsletter-wrap{
            padding-bottom: 5px;

            @media (max-width: $screen-xs-min - 1){
                padding-bottom: 0;
            }
            @media (min-width: $screen-lg-min) {
                padding-right: 80px;
                padding-left: 80px;
            }
            @media (min-width: $screen-xl-min) {
                padding-top: 100px;
                padding-left: 300px;
                padding-right: 300px;
            }
        }

        .title{
            font-size: 34px;
            line-height: 1.2;
            color: $white;
            margin-bottom: 22px;
            text-transform: uppercase;

            @media (min-width: $screen-xl-min) {
                margin-bottom: 44px;
            }
            @media (max-width: $screen-lg-min - 1){
                margin-bottom: 20px;
            }

            strong{
                font-weight: 400;
            }
        }

        .content{
            position: relative;
        }

        .newsletter{
            padding-bottom: 0;

            .label{
                font-size: 18px;
                line-height: 28px;
                color: $white;
                margin-right: 0;
                margin-bottom: 23px;

                @media (min-width: $screen-xl-min) {
                    margin-bottom: 46px;
                }
                @media (max-width: $screen-xs-min - 1){
                    display: none;
                }
            }
        }

        .actions{
            padding-top: 15px;
            @media (min-width: $screen-xs-min){
                position: absolute;
                right: 0;
                bottom: 0;
                padding-top: 0;
            }
            @media (min-width: $screen-sm-min){
                right: 5%;
            }

            .action{
                font-size: 16px;
                line-height: 20px;
                padding: 17px 28px;
                font-weight: 700;
                text-transform: uppercase;
                background: $primary;

                &:hover,
                &:focus {
                    background: $secondary-1;
                }

                @media (min-width: $screen-sm-min) {
                    padding: 17px 43px;
                }
            }
        }
    }
}

.cms-index-index .page-footer .block.newsletter{
    @include make-flex;
}