
//
//  Header
//  ---------------------------------------------
.wish-list-link-wrap{
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: auto;

    a{
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        text-indent: -9999px;
        display: inline-block;
        &:before{
            @include font-icon__pseudo('\e028', $out-icons);
            color: $primary;
            font-size: 19px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            text-indent: 0;
            transition: .3s ease;
        }
        &:hover:before{
            color: $secondary-1;
        }
    }

    @media (min-width: $screen-sm-min) {
        margin-top: 13px;
    }
    @media (min-width: $screen-md-min) {
        margin-top: 7px;
    }
}

//
//  Wishlist block
//  ---------------------------------------------

.block-wishlist{
    .product-items{
        margin-bottom: $indent-small;
    }
    .product-item{
        display: block;
        margin-bottom: $indent-base;
        &:last-child{
            margin-bottom: 0;
        }
    }

    .product-item-photo{
        width: auto;
        margin: 0 auto;
        @media (min-width: $screen-lg-min){
            float: left;
            width: 75px;
        }
    }
    .product-item-name{
        display: block;
        padding-right: 2rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;

        a{
            color: $secondary-1;
            &:hover{
                color: $primary;
            }
        }
    }
    .price-box{
        color: $default;
    }
    .product-item-details{
        overflow: hidden;
        padding-right: 20px;
        margin-left: 0;
        position: relative;
        margin-top: $indent-small;
        @media (min-width: $screen-lg-min){
            margin-left: 85px;
        }
    }
    .product-item-actions{
        @include justify-content(space-between);
        margin: 0;

        .actions-primary{
            margin: 0;

            a{
                font-size: 14px;
            }
        }
    }
    .empty{
        margin-top: $indent-small;
    }

    .actions-toolbar .action{
        background: none;
        font-size: 16px;
        padding: 5px;
        text-transform: uppercase;
        color: $default;
        &:hover{
            background: none;
            color: $primary;
        }
    }

    .actions-secondary{
        position: absolute;
        top: -3px;
        right: 0;

        .btn-remove {
            padding: 0;
            background: none;
            color: $secondary-1;
            &:hover{
                background: none;
                color: $primary;
            }
            &:before{
                font-size: 20px;
            }
        }
    }
}


//
//  Wishlist page
//  ---------------------------------------------

.form-wishlist-items{
    .actions-toolbar{
        @include justify-content(space-between);
        margin-top: 30px;
        >.primary{
            @include order(2);
        }
        >.secondary{
            @include order(1);
        }

        @media (max-width: $screen-md-min - 1) {
            >.primary{
                @include order(1);
            }
            >.secondary{
                @include order(2);
            }
            button{
                margin-bottom: 7px;
            }
        }
        @media (max-width: $screen-sm-min - 1) {
            @include flex-direction(column);
            margin-bottom: 20px;
        }
    }
}

.products-grid.wishlist{
    .product-item-name{
        display: block;
    }
    .price-box .price-as-configured{
        margin-bottom: .5rem;
    }
    .field label.label{
        padding-top: 0;
    }
    .box-tocart{
        margin-bottom: $indent-small;

        >.fieldset{
            .product-item-actions{
                margin-bottom: 7px;
            }
        }
        .field.qty{
            @include make-flex;
            float: left;
            margin-bottom: 7px;
            margin-right: 1rem;

            .label{
                @include make-flex;
                @include align-items(center);
                padding-bottom: 0;
                margin-right: .5rem;
            }
            input.qty{
                width: 4rem;
                line-height: 1.5;
                min-height: 33px;
                padding: 6px 0 6px 10px;
                text-align: center;
            }
        }
        .unavailable.stock{
            margin: 7px 3px 6px 8px;
        }
    }
    .product-item-actions{
        display: block;
        margin: 0;
        padding-top: 1px;
        .actions-primary{
            margin: 0;
        }
        a.action{
            &.edit,
            &.delete{
                color: $secondary-1;
                &:hover{
                    color: $primary;
                }
            }
        }
    }

    a.action{
        &.edit,
        &.delete{
            font-size: 1.2rem;
            line-height: 1;
            padding: 2px 20px;
            background: none;
        }
    }

    @media (min-width: $screen-lg-min) {
        .product-item {
            @include flex-basis(25%);
        }
    }
}