//
//  Search mini form
//  ---------------------------------------------

.block-search{
    .block-title{
        display: none;
    }
}
#search_mini_form{
    background: none;
    .label{
    	display: none;
    }
    .nested{
    	display: none;
    }
}

.rd-navbar-static{

    .page-header__content {
        .block.block-search.rd-navbar-search,
        .rd-navbar-collapse-container,
        .wish-list-link-wrap,
        .rd-navbar-cart-wrap {
            @media (min-width: $screen-lg-min) {
                margin-top: 40px;
            }
        }
    }

    .rd-navbar-search-toggle{
        display: block;
        position: relative;
        font-family: $default-icon-family;
        width: 40px;
        height: 40px;
        @include toggle-icons-via-rotation(40px, 28px, $secondary-1, '\e032', '\e5cd');
        z-index: 99;

        span:before {
            font-size: 19px;
            font-family: $out-icons;
        }

        &,
        span{
            transition: none;
        }

        &.active span,
        &:hover span{
            color: $primary;
        }
    }

    .rd-navbar-search.block{
        margin-right: 1.964583vw;
        position: relative;
    }

    .rd-navbar-search{
        display: block;

        .field{
            margin-bottom: 0;
            z-index: 0;
        }

        .form-group{
            position: absolute;
            z-index: 98;
            top: -24px;
            left: -15px;
            height: 88px;
            @extend %rd-navbar-transition;
            visibility: hidden;
            opacity: 0;

            @media (min-width: $screen-sm-min){
                width: $screen-sm-min - 25px;
            }
            @media (min-width: $screen-md-min){
                width: $screen-md-min - 25px;
            }
            @media (min-width: $screen-lg-min){
                width: 1200px;
            }
            @media (min-width: $screen-xl-min - 100px){
                width: 1280px;
            }

            input#search{
                width: 100%;
                height: 88px;
                font-size: 14px;
                line-height: 18px;
                display: block;
                padding-right: 60px;
                padding-left: 60px;
                background-color: $white;
                border: none;
                border-radius: 0;
                -webkit-box-shadow: 3px 3px 62px rgba(0,0,0,.09);
                -moz-box-shadow: 3px 3px 62px rgba(0,0,0,.09);
                box-shadow: 3px 3px 62px rgba(0,0,0,.09);
            }
        }

        &.active{
            .form-group{
                opacity: 1;
                visibility: visible;
            }
        }

        form{
            button[type="submit"]{
                @include font-icon('\e8b6');
                background: $rd-navbar-contrast-color;
                height: calc(100% - 2px);
                font-size: 26px;
                line-height: 1;
                padding: 0;
                position: absolute;
                z-index: 99;
                right: 0;
                top: 0;
                width: 66px;
                color: $secondary-1;
                transition: .3s ease;
                &:before{
                    padding-top: 10px;
                }
                &:hover{
                    color: $primary;
                }

                span{
                    display: none;
                }
            }
        }
    }
}

//
//  RD Navbar Search
//-----------------------------------------------

.rd-navbar-fixed{
    .rd-navbar-search-toggle{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: $default-icon-family;
        width: 25vw;
        height: 100px;
        @include toggle-icons-via-rotation(44px, 19px, $secondary-1, '\e032', '\e5cd');
        z-index: 96;

        span:before {
            font-family: $out-icons;
        }

        @media (max-width: $screen-xs-min - 1){
            height: 40px;
        }

        span{
            margin-left: 0;
            font-size: 20px;
            @media (min-width: $screen-xs-min){
                @media (max-width: $screen-sm-min - 1){
                    font-size: 30px;
                }
            }
        }

        &.active{
            width: 47px;
        }
    }

    .rd-navbar-search{
        display: block;

        .form-group{
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100px;
            width: 100vw;
            z-index: 95;
            @extend %rd-navbar-transition;
            visibility: hidden;
            opacity: 0;

            @media (max-width: $screen-xs-min - 1){
                height: 40px;
            }

            input{
                width: 100%;
                height: 100px;
                font-size: 14px;
                line-height: 24px;
                padding: 38px 45px !important;
                border: none;
                display: block;
                background: $white;
                min-height: 40px;

                @media (max-width: $screen-xs-min - 1){
                    height: 40px;
                    padding: 10px 45px 6px !important;
                }
            }
        }

        &.active{
            .form-group{
                opacity: 1;
                visibility: visible;
            }
        }

        form{
            button[type="submit"]{
                @include font-icon('\e8b6');
                color: $secondary-1;
                height: 20px;
                font-size: 19px;
                line-height: 20px;
                margin: -10px 0 0;
                padding: 0;
                position: absolute;
                z-index: 96;
                right: 10px;
                top: 50%;
                background-color: transparent;

                @media (min-width: $screen-xs-min){
                    @media (max-width: $screen-sm-min - 1){
                        font-size: 30px;
                        line-height: 30px;
                        height: 30px;
                        margin-top: -15px;
                    }
                }
                @media (max-width: $screen-sm-min - 1) {
                   margin-right: 15px;
                }

                span{
                    display: none;
                }
            }
        }
    }
}

