
.posts-list-wrapper .toolbar-top .toolbar-posts{
    @include make-flex;
    @include justify-content(space-between);

    .limiter{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 0;
        @include justify-content(space-between);

        @media (min-width: $screen-sm-min){
            @include order(2);
        }
    }


    .sorter{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 0;
        @include justify-content(space-between);

        @media (min-width: $screen-sm-min){
            @include order(1);
        }

        .sorter-label{
            min-width: 90px;
            padding-right: $indent-small;

            @media (max-width: $screen-xs-min - 1) {
                min-width: 0;
                margin-right: 0;
                padding-right: 0;
            }
        }
    }

    .control{
        text-align: center;
    }
}

div.block-posts-list{
    ol.post-items{
        margin: 0;
        .post-item{
            @media (max-width: $screen-lg-min - 1) {
                @include flex-basis(100%);
                padding: 0;
            }
        }

        .post-image a {
            position: relative;
            display: block;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($black, .35);
                opacity: 0;
                transition: .3s all ease;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }

        .post-title{
            margin-bottom: 15px;
            text-transform: uppercase;

            @media (min-width: $screen-lg-min) {
                h3 {
                    font-size: 24px;
                }
            }

            a{
                color: $secondary-1;
                &:hover{
                    color: $primary;
                }
            }
        }
        .post-short-content {
            font-size: 16px;
        }
        a.post-link{
            color: $secondary-1;
            text-transform: capitalize;
            &:hover{
                color: $primary;
            }
        }
        .post-data{
            @include make-flex;
            @include justify-content(space-between);
            time{
                display: inline-block;
            }

            .post-author {
                &:before {
                    position: relative;
                    top: -2px;
                }

                .label,.author {
                    font-size: 16px;
                }
            }
            .create-date {
                &:before {
                    position: relative;
                    top: -2px;
                }

                time {
                    font-size: 16px;
                }
            }

            .post-comments a{
                color: $default;

                .count {
                    font-size: 16px;
                }

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

.post-page{
    .post-text{
        img{
            margin-bottom: 1rem;

            @media (min-width: $screen-lg-min) {
                float: left;
                padding-right: 20px;
                margin-bottom: 0;
            }
        }
    }
    .post-data{
        @include make-flex;
        @include justify-content(space-between);
        time{
            display: inline-block;
        }

        .post-comments a{
            color: $default;
            &:hover{
                color: $primary;
            }
        }
    }

    .post-comments-block{
        .post-comment{
            background-color: transparent;
            padding: 30px 0;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
        }
        .comment-info{
            @include make-flex;
            @include justify-content(flex-start);
            border-bottom: none;

            div{
                float: none;
            }
            h3{
                margin: 0 2em 0 0;
                @include order(1);
            }
            span{
                line-height: 2rem;
            }
            .post-data{
                @include order(2);
            }
        }
        .action{
            @include button-block;
            @include button-block__primary;
        }
    }
}

.block-recent-comments .title-section a:hover {
    color: $secondary-1;
}

.block.widget.blog-posts {
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 70px;

    .block-title {
        padding-right: 15px;
        padding-left: 15px;
        text-align: center;

        strong {
            font-size: 22px;
            line-height: 1.2;
            font-weight: 400;

            @media (min-width: 480px) {
                font-size: 26px;
            }
            @media (min-width: $screen-sm-min) {
                font-size: 34px;
            }
        }
    }

    .block-title + .block-content {
        margin-top: 35px;

        @media (min-width: $screen-md-min) {
            margin-top: 65px;
        }
    }

    .owl-carousel {
        max-width: 1590px;
        margin-right: auto;
        margin-left: auto;
    }

    .post-items {
        max-width: 1753px;
        margin-right: auto;
        margin-left: auto;
        @include justify-content(space-between);

        .post-link {
            color: $secondary-1;
            text-transform: uppercase;

            &:hover,
            &:focus {
                color: $white;
            }
        }

        * + .post-link {
            margin-top: 17px;

            @media (min-width: $screen-lg-min) {
                margin-top: 27px;
            }
        }

        .post-short-content {
            margin-bottom: 15px;
        }

        .post-item {
            @include flex-basis(100%);

            @media (min-width: $screen-sm-min) {
                @include flex-basis(50%);
            }
            @media (min-width: $screen-md-min) {
                position: relative;
                padding-left: 105px;
            }
            @media (min-width: $screen-lg-min) {
                padding-left: 140px;
            }
            @media (min-width: $screen-xl-min) {
                padding-left: 184px;
            }

            .post-title {
                margin-bottom: 16px;

                h3 {
                    font-weight: 700;
                    line-height: 1.2;

                    a {
                        color: $secondary-1;

                        &:hover,
                        &:focus {
                            color: $primary;
                        }
                    }
                }
            }

            .post-data {
                .create-date {
                    @include make-flex;
                    @include align-items(center);
                    @include justify-content(center);
                    @include flex-wrap(wrap);
                    @include flex-direction(column);
                    text-align: center;
                    width: 66px;
                    height: 66px;
                    color: $secondary-1;
                    background: $primary;

                    .icon {
                        display: block;
                        font-size: 26px;
                        margin-top: 4px;
                    }

                    time {
                        font-weight: 400;
                        font-size: 16px;
                    }

                    * + time {
                        margin-top: 2px;
                    }

                    @media (min-width: $screen-md-min) {
                        position: absolute;
                        top: 0;
                        left: 17px;
                        width: 77px;
                        height: 77px;

                        .icon {
                            margin-top: 9px;
                        }
                    }
                    @media (min-width: $screen-lg-min) {
                        left: 37px;
                        width: 88px;
                        height: 88px;

                        .icon {
                            margin-top: 10px;
                            font-size: 30px;
                        }

                        time {
                            font-size: 18px;
                        }

                        * + time {
                            margin-top: 5px;
                        }
                    }
                    @media (min-width: $screen-xl-min) {
                        left: 77px;
                    }
                }

                * + .create-date {
                    margin-right: 5px;

                    @media (min-width: $screen-md-min) {
                        margin-right: 0;
                    }
                }

                .post-comments {
                    a {
                        @include make-flex;
                        @include align-items(center);
                        @include justify-content(center);
                        @include flex-wrap(wrap);
                        @include flex-basis(initial);
                        @include flex-direction(column);
                        text-align: center;
                        width: 66px;
                        height: 66px;
                        background: $secondary-1;
                        color: $gray-lighten;
                        transition: .3s all ease;

                        .count {
                            display: block;
                            margin-top: 4px;
                            line-height: 1.2;

                            &:before {
                                font-size: 26px;
                                color: $gray-lighten;
                                transition: .3s all ease;
                            }
                        }

                        .count-title {
                            display: block;
                            font-size: 16px;
                            text-transform: uppercase;
                        }

                        &:hover {
                            color: $secondary-1;
                            background: $primary;

                            .count:before {
                                color: $secondary-1;
                            }
                        }
                    }

                    @media (min-width: $screen-md-min) {
                        position: absolute;
                        top: 88px;
                        left: 17px;
                        margin-top: 6px;

                        a {
                            width: 77px;
                            height: 77px;

                            .count {
                                margin-top: 5px;
                            }
                        }
                    }
                    @media (min-width: $screen-lg-min) {
                        left: 37px;

                        a {
                            width: 88px;
                            height: 88px;

                            .count {
                                margin-top: 7px;

                                &:before {
                                    font-size: 30px;
                                }

                                .count-title {
                                    font-size: 18px;
                                }

                                * + .count-title {
                                    margin-top: 1px;
                                }
                            }
                        }
                    }
                    @media (min-width: $screen-xl-min) {
                        left: 77px;
                    }
                }
            }
        }

        .post-item + .post-item {
            margin-top: 30px;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
            }
        }

        .post-image {
            margin-bottom: 29px;

            > a {
                display: inline-block;
                position: relative;
                width: 100%;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba($black, .35);
                    opacity: 0;
                    transition: .3s all ease;
                    z-index: 1;
                    will-change: transform;
                }
            }

            > a:hover {
                &:before {
                    opacity: 1;
                }
            }
        }

        .post-data .post-author {
            display: none;
        }

        .post-data {
            padding-top: 0;
            font-size: 14px;
            font-weight: 300;
            border-top: none;
            @include align-items(center);
            @include justify-content(flex-start);

            .create-date {
                display: inline-block;
            }

            * + .post-author {
                margin-left: 15px;
            }

            .post-author {
                > span {
                    vertical-align: middle;
                }

                &:before {
                    color: $white;
                }
            }

            .create-date:before {
                display: none;
            }

            @media (max-width: $screen-lg-min) {
                @include make-flex;
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        padding-top: 152px;
        padding-bottom: 100px;

        .block-title + .block-content {
            margin-top: 50px;
        }
    }
}


.block.widget.blog-posts {
    .owl-carousel {
        .owl-nav {
            top: -55px;
            left: 50%;
            right: auto;
            @include transform(translateX(-50%));

            .owl-next,
            .owl-prev {
                &:before {
                    color: $white;
                    transition: .3s all ease;
                }

                &:hover {
                    &:before {
                        color: $secondary-1;
                    }
                }
            }
        }

        @media (min-width: 480px) {
            .owl-nav {
                @include make-flex;
                @include justify-content(space-between);
                width: 100%;
            }
        }

        @media (min-width: $screen-xl-min - 200) {
            position: static;

            .owl-stage-outer {
                z-index: 2;
            }

            .owl-nav {
                z-index: 1;
                top: 50%;
                margin-top: -65px;

                [class^="owl-"]{
                    width: 70px;
                    height: 70px;
                    padding: 17px 7px;
                    background: $white;
                    box-shadow: 3px 3px 62px rgba(0,0,0,.09);
                    text-align: center;

                    &:before {
                        line-height: 1;
                    }

                    &, &:before {
                        color: $secondary-1;
                        transition: .3s all ease;
                    }

                    &:hover {
                        box-shadow: 3px 3px 62px rgba(0,0,0,.15);

                        &:before {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $screen-xl-min - 200) {
        .block-content {
            position: relative;
        }
    }
}

.block.related-posts .related-post-grid {
    .inner > a {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($black, .4);
            opacity: 0;
            z-index: 1;
            transition: .3s all ease;
        }

        h5.post-title {
            a {
                &:hover,
                &:focus {
                    color: $primary;
                }
            }
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }

    .post-title > a {

        &:hover,
        &:focus {
            color: $primary;
        }
    }
}