
//
//  Grid
//  ---------------------------------------------

.products-grid{
    .product-items{
        @include make-flex();
        @include make-row();
        @include flex-wrap(wrap);
    }
    .product-item{
        @include make-col();
        @include flex-basis(100%);
        margin-bottom: $indent-base;

        .product-label {
            position: absolute;
            top: 26px;
            left: 37px;
            z-index: 1;
            width: 63px;
            height: 63px;
            line-height: 52px;
            padding: 5px;
            text-align: center;
            font-size: 24px;
            border-radius: 50%;
            background: $primary;
            color: $white;
            will-change: transform;
        }
    }

    //Image
    .product-item-photo{
        @media (max-width: $screen-xs-min - 1){
            display: block;
            width: 300px;
            margin: 0 auto;
        }
    }

    .product-image-container{
        display: inline-block;
        max-width: 100%;
    }
    .product-image-wrapper{
        height: 0;
        display: block;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
    .product-image-photo{
        text-align: center;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        max-width: 100%;
    }

    //Reviews
    .reviews-actions{
        @include make-flex();
        @include justify-content(space-between);
        .action.add{
            @include font-icon('\e0cb');
            font-size: 0;
            &:before{
                font-size: $default-font-size;
            }
        }
    }

    //Price
    .special-price{
        display: block;
    }
    .old-price{
        display: block;
    }

    .price-from{
        margin-bottom: 0;
    }

    .price-to{
        margin-bottom: 0;
    }

    .product-item-inner {
        margin-top: 20px;

        @media (min-width: $screen-lg-min) {
            .towishlist {
                @include transform(translateX(-50%))
            }

            .tocompare {
                @include transform(translateX(50%))
            }

            .tocompare,
            .towishlist {
                visibility: hidden;
                opacity: 0;
                transition: .3s all ease;
            }
        }
    }

    //Actions
    .product-item-actions{
        @include make-flex();
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        @include justify-content(center);

        .actions-secondary {
            @include make-flex;
            @include justify-content(center);
            @include align-items(center);

            .actions-primary {
                margin-bottom: 5px;
                margin-right: 5px;
                margin-left: 5px;
            }

            .stock.unavailable {
                line-height: 21px;
                background: $gray-lighten;
                border: 1px solid $gray-lighten;
            }

            .action + .action {
                margin-left: 5px;
            }

            .action,
            .stock.unavailable {
                padding: 7px 16px;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                color: $white;
            }

            .tocart,
            .stock.unavailable {
                min-width: 125.67px;
            }

            .action {
                background: $primary;
                border: 1px solid $primary;

                &:hover,
                &:focus {
                    color: $primary;
                    background: transparent;
                }

                &.tocompare,
                &.towishlist {
                    padding-right: 12px;
                    padding-left: 12px;
                }
            }

            @media (min-width: $screen-sm-min + 1) {
                @include flex-wrap(row-reverse);

                > * {
                    &:first-child {
                        @include order(1);
                    }
                    &:nth-child(2) {
                        @include order(3);
                    }
                    &:last-child {
                        @include order(2);
                    }
                }

                .action + .action {
                    margin-left: 0;
                }

                .actions-primary, .actions-primary .action,
                .actions-primary form{
                    height: 100%;
                }

                .actions-primary {
                    margin-bottom: 0;
                }
            }
            @media (min-width: 418px) and (max-width: $screen-sm-min) {
                > .action {
                    @include flex-basis(25%);
                    text-align: center;
                    max-width: 40px;
                }

                .actions-primary {
                    min-width: 100%;
                    text-align: center;
                }
            }
            @media (max-width: $screen-sm-min) {
                @include flex-wrap(wrap-reverse);
            }
            @media (max-width: 417px) {
                > .action {
                    @include flex-basis(18%);
                    min-width: 40px;
                }

                .actions-primary {
                    @include flex-basis(62%);
                    text-align: center;
                    max-width: 77%;
                }
            }
        }

        >div{
            margin-bottom: .5rem;
        }
    }
}

.products-grid,
.featured-prods{
    .product-item-img_box{
        position: relative;
        overflow: hidden;
        z-index: 0;
        background: $white;
        margin-bottom: 23px;
        border: 1px solid transparent;

        @media (min-width: $screen-xl-min - 200px){
            min-height: 385px;
        }

        .product-image-wrapper img {
            will-change: transform;
            transition: .3s opacity ease;
        }

        .product-item-photo:hover .product-image-wrapper img {
            opacity: .5;
        }

        .product-reviews-summary{
            position: absolute;
            z-index: 99;
            top: 0;
            right: 0;
            left: 0;
            margin-bottom: 0;
            transition: .3s ease;
            padding-top: 10px;
            padding-bottom: 8px;
            background: $white;
            border-bottom: 1px solid $gray-light;
            @include justify-content(center);
            @include align-items(flex-start);

            .rating-summary {
                line-height: 1;
            }

            @media (min-width: $screen-lg-min){
                top: -40px;
            }
        }

        @media (min-width: $screen-lg-min) {
            @include make-flex;
            @include align-items(center);
            @include justify-content(center);
        }
    }

    .product-item-details {
        text-align: center;
    }

    .product-item-name a{
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-item-info:hover {
        @media (min-width: $screen-lg-min) {
            .product-item-img_box{
                border-color: $gray-light;

                .product-reviews-summary{
                    top: 0;
                    bottom: auto;
                }
            }

            .product-item-inner {
                .towishlist {
                    @include transform(translateX(0))
                }

                .tocompare {
                    @include transform(translateX(0))
                }

                .tocompare,
                .towishlist {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

@media (min-width: $screen-xs-min){
    .products-grid{
        .product-item{
            @include flex-basis(50%);
            max-width: 50%;
        }
    }
}

@media (min-width: $screen-md-min){
    .products-grid{
        .product-item{
            @include flex-basis(33.33%);
        }
    }
}

@media (min-width: $screen-lg-min) {
    .product-item-actions{
        .actions-secondary{
            @include flex-basis(100%);
        }
    }
}

.products-grid.wishlist {
    @media (min-width: $screen-lg-min) {
        .product-item .product-item-tooltip {
            position: relative;

            .tooltip.toggle, .tooltip.content {
                transition: .3s all ease;
            }

            .tooltip.content {
                position: absolute;
                bottom: -130px;
                left: 50%;
                width: 100%;
                min-height: 100%;
                padding: 10px;
                z-index: 1;
                background: $white;
                box-shadow: 0 2px 5px rgba($black, .25);
                @include transform(translateX(-50%));
                opacity: 0;
                visibility: hidden;
                transition-delay: .2s;
            }

            &:hover {
                .tooltip.toggle {
                    cursor: pointer;
                    color: $primary;
                }

                .tooltip.content {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}