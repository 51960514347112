//
// Grid system
// --------------------------------------------------

$column-count: 12;
$column-width: 70px;
$gutter-width: 30px;

$row-ws: 0;

$container-xs: 300px;
$container-sm: 720px + $gutter-width;
$container-md: 940px + $gutter-width;
$container-lg: $column-count*$column-width + $gutter-width*($column-count);
$container-xl: 1920px;

// Layouts
$layout-boxed-width: 1920px;

// Resolutions
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1920px;

// Fonts
$default-font-family: 'Open Sans', sans-serif;
$default-font-weight: 400;
$default-font-size: 18px;
$default-line-height: 1.5em;

// Icons
$default-icon-family: 'Material Icons';
$fa: 'FontAwesome';
$out-icons: 'fl-outicons';
$md: 'material-design';

// Colors
$white: #FFF;
$black: #000;
$red: #d73105;
$default: #7d7d7d;

// Shadows of Gray
$gray: #898e91;
$gray-light: #f5f6f7;
$gray-lighten: #aeb4bc;

$bg-light: #f0f0f0;
$bg-dark: $gray-light;

$primary: #7bc0c9;
$secondary-1: #393e45;
$secondary-2: #4e4e4e;
$contrast: $white;
$unactive: #5f5f5f;

$border-color: $gray-light;

$header-color: $contrast;
$header-bg: #ffffff;

$content-color: $default;
$content-bg: $white;

$footer-color: $content-color;
$footer-bg: $secondary-1;

$modal-popup__box-shadow: (transparentize($black, .8));

// rd-navbar variables

$rd-navbar-default-color: $default;
$rd-navbar-contrast-color: $white;
$rd-navbar-accent-color: #434343;
$rd-navbar-accent-color-2: rgb(46, 204, 113);
$rd-navbar-hover-color: $primary;

$rd-navbar-submenu-bg: darken($white, 10%);

$rd-navbar-shadow-ambient: 0 0 2px 0 rgba(0, 0, 0, .25);
$rd-navbar-shadow-medium: 0 3px 5px 0 rgba(0, 0, 0, .25);
$rd-navbar-shadow-large: 0 5px 8px 0 rgba(0, 0, 0, .25);

%rd-navbar-transition {
    transition: .3s all cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

%rd-navbar-hidden {
    display: none;
}

%rd-navbar-visible {
    display: block;
}


//Indents
$indent-base: 2rem;
$indent-large:  $indent-base * 2;
$indent-small:  $indent-base / 2;

//Links
$link-regular:  $secondary-1;
$link-hover:    $primary;
$link-active:   $secondary-2;
$link-alt:      $default;

//Transition
$default-transition: .3s ease;

//
//  Form Variables
//  ---------------------------------------------

//Inputs
$input-padding-hor:     20px;
$input-padding-vert:    6px;

$input-font-size:       $default-font-size;
$input-line-height:     $default-line-height;


$input-bg-color:        darken($white, 10%);

//Buttons
$button-padding-vert:   6px;
$button-padding-hor:    20px;

$button-bg-color:       $primary;
