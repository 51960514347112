/*
* TM_Newsletter-popup
*/

.newsletter-popup.modal-popup{
    > .modal-inner-wrap{
        @media (max-width: $screen-lg-min - 1){
            max-width: 90vw;
        }
    }

    .modal-content .block.newsletter .field .control{
        position: relative;
        #newsletter{
            border: none;
            @media (max-width: $screen-xs-min - 1) {
                margin-bottom: 30px;
            }
        }
        .mage-error:not(input){
            padding-left: 0;
            color: $white;
            @media (min-width: $screen-sm-min){
                position: absolute;
                top: 100%;
                padding-left: 30px;
            }
        }
    }

    .modal-inner-wrap{
        margin-top: 9%;
        padding: 15px 25px 80px 25px;
        color: $white;
        background: $secondary-1;

        @media (min-width: $screen-sm-min) {
            padding-right: 15px;
            padding-bottom: 95px;
            padding-left: 15px;
        }

        .icon {
            width: 70px;
            height: 70px;
            line-height: 70px;
            font-size: 60px;

            @media (min-width: $screen-sm-min) {
                width: 90px;
                height: 90px;
                line-height: 90px;
                font-size: 80px;
            }
        }

        .icon + p {
            padding-top: 39px;
        }

        @media (max-width: 470px) {
            p {
                line-height: 1.2;
            }
        }
    }

    header.modal-header{
        display: block !important;
        padding: 0;
        text-align: center;

        @media (max-width: $screen-xs-min - 1){
            padding: 0;
        }

        h1.modal-title{
            color: $white;
            display: inline-block;
            width: 310px;
            height: 67px;
            margin-bottom: 9px;
            overflow: hidden;
            text-indent: -9999px;

            @media (max-width: $screen-lg-min - 1){
                margin-bottom: 1.42vw;
            }
            @media (max-width: $screen-sm-min - 1){
                width: 180px;
                height: 60px;
            }
            @media (max-width: $screen-xs-min - 1){
                font-size: 20px;
                text-align: center;
                margin-bottom: 0;
            }
        }
        .action-close{
            display: block;
            padding: 6px;
            background: $white;
            border-radius: 100px;
            &:before{
                @include font-icon__pseudo('\e14c');
                display: block;
                font-size: 30px;
                line-height: 1;
                font-weight: 400;
                color: $secondary-1;
                transition: .3s ease;
            }
            &:hover{
                background: $primary;
                &:before{
                    color: $white;
                    opacity: 1;
                }
            }
            span{
                display: none;
            }

            @media (min-width: $screen-md-min){
                top: -42px;
                right: -42px;
            }
            @media (max-width: $screen-sm-min - 1){
                top: 5px;
                right: 5px;
                font-size: 20px;
                padding: 3px;
            }
        }
    }

    .newsletter-wrap{
        position: relative;
        padding: 13px 70px 0;

        @media (max-width: $screen-lg-min - 1){
            padding: 3.42vw 5vw 0;
        }
        @media (max-width: $screen-xs-min - 1){
            padding: 2vw 0 0;
        }
    }

    .modal-content{
        .block.newsletter .field{
            padding-bottom: 0;
        }
        .content-text{
            padding: 0 0 36px;
            font-size: 24px;
            line-height: 36px;
            color: $white;
            text-align: center;

            @media (min-width: $screen-xs-min) {
                padding-right: 50px;
                padding-left: 50px;
            }
            @media (max-width: $screen-lg-min - 1){
                padding-bottom: 4vw;
            }
            @media (max-width: $screen-xs-min - 1){
                font-size: 16px;
                line-height: 1;
                text-align: center;
            }
        }
        .block.newsletter input{
            background: $white;
            color: $secondary-1;
            border: none;
            font-size: 16px;
            line-height: 20px;
            padding: 20px 175px 20px 32px;

            @media (max-width: $screen-sm-min - 1){
                padding: 15px 110px 15px 20px;
            }
            @media (max-width: $screen-xs-min - 1){
                padding: 8px 15px;
                border-radius: 100px;
            }
        }
    }

    .modal-content,
    footer.modal-footer{
        padding: 0;
    }

    footer.modal-footer{
        .buttons-container{
            position: absolute;
            right: 90px;
            bottom: 0;
            padding: 0;

            @media (min-width: $screen-sm-min) {
                bottom: 32px;
                right: 15px;
            }
            @media (min-width: $screen-md-min) {
                right: 36px;
            }
            @media (min-width: $screen-lg-min) {
                right: 48px;
            }
            @media (max-width: $screen-xs-min - 1){
                position: relative;
                right: auto;
                left: auto;
                text-align: center;
            }
        }
        button.subscribe{
            margin: 0;
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            padding: 21px 58px;
            text-transform: uppercase;

            @media (max-width: $screen-sm-min - 1){
                padding: 16px 30px;
            }
        }

        .buttons-container button:not(.subscribe){
            display: none;
        }
    }
}

.modal-popup .modal-inner-wrap {
    pointer-events: auto;
}
.modal-popup.newsletter-popup._show {
    pointer-events: none;
    background: rgba(#1e1e1e, .7);

    .block.newsletter .field.newsletter .control{
        max-width: 100%;
        margin-left: auto;
    }

    @media (max-width: $screen-sm-min - 1){
        .modal-inner-wrap{
            padding-bottom: 14vw;
        }
    }
}