/*
* @subsection   Utilities Custom
*/


// Buttons
.btn {
  display: inline-block;
  text-align: center;
  padding: 8px 20px;
  min-width: 160px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid;
  letter-spacing: .03em;
  transition: .3s all ease;
}

// Button Appearance
.btn-white {
  color: $white;
  border-color: $white;

  &:hover,
  &:focus {
    color: $secondary-1;
    background: $white;
    border-color: $white;
  }
}

.btn-dark {
  color: $secondary-1;
  border-color: $secondary-1;

  &:hover,
  &:focus {
    color: $white;
    background: $secondary-1;
    border-color: $secondary-1;
  }
}

.btn-primary {
  color: $white;
  background: $primary;
  border-color: $primary;

  &:hover,
  &:focus {
    color: $white;
    background: $secondary-1;
    border-color: $secondary-1;
  }
}

.btn-secondary {
  color: $white;
  background: #4c5462;
  border-color: #4c5462;

  &:hover,
  &:focus {
    color: $white;
    background: $primary;
    border-color: $primary;
  }
}

.btn-secondary-filled {
  color: $white;
  background: $secondary-1;
  border-color: $secondary-1;

  &:hover,
  &:focus {
    color: $white;
    background: $primary;
    border-color: $primary;
  }
}

.btn-transparent {
  color: $white;
  background: transparent;
  border-color: $white;

  &:hover,
  &:focus{
    color: $secondary-1;
    background: $white;
  }
}


// Banners

.banner {
  position: relative;
  display: inline-block;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba($black, .15);
    opacity: 0;
    transition: .3s all ease;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 1;
    }
  }

  span:not(.btn) {
    display: block;
  }

  &, &-img-wrap,
  &-img-wrap img {
    width: 100%;
  }

  &-img-wrap {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba($secondary-1, .3);
    }
  }

  &-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    padding: 15px;
    text-align: center;
    @include transform(translate(-50%,-50%));
    will-change: transform;
  }

  &.banner-2,
  &.banner-3,
  &.banner-4,
  &.banner-5{
    .banner-caption.banner-light {
      color: $white;
    }

    .banner-caption.banner-dark {
      color: $secondary-1;
    }
  }

  @media (min-width: $screen-md-min) {
    &-img-wrap:before {
      display: none;
    }
  }
  @media (min-width: 600px) and (max-width: $screen-sm-min - 1) {
    .btn {
      font-size: 12px;
      min-width: 0;
      padding: 6px 12px;
    }
  }
  @media (max-width: $screen-xs-min - 1) {
    .btn {
      font-size: 12px;
      min-width: 0;
    }
  }
}

.banner-1 {

  .banner-1-price-wrap .banner-1-sup,
  .banner-1-price-wrap .banner-1-price {
    display: inline-block;
  }

  &-img-wrap {
    img {
      min-height: 245px;
    }
  }

  &-caption {
    color: $white;
  }

  &-title {
    font-size: 26px;
  }

  &-desc {
    font-size: 14px;
    text-transform: uppercase;
  }

  &-price {
    position: relative;
    top: -12px;
    font-weight: 300;
    font-size: 52px;
    line-height: 1;
  }

  &-sup {
    font-size: 14px;
  }

  // offsets
  * + &-desc {
    margin-top: 5px;
  }

  * + &-price-wrap {
    margin-top: 14px;
  }

  * + .btn {
    margin-top: 12px;
  }

  @media (min-width: $screen-sm-min) {
    &-img-wrap {
      img {
        min-height: 0;
      }
    }
    &-title {
      font-size: 36px;
    }
    &-desc {
      font-size: 20px;
    }
    &-sup {
      font-size: 18px;
    }
    &-price {
      top: -16px;
      font-size: 82px;
    }
  }
  @media (min-width: $screen-md-min) {
    &-title {
      font-size: 42px;
      line-height: 1;
    }
    &-desc {
      font-size: 22px;
      line-height: 1;
    }
    &-sup {
      font-size: 28px;
    }
    &-price {
      top: -26px;
      font-size: 122px;
    }
    .btn {
      position: relative;
      bottom: -8px;
    }
  }
  @media (min-width: $screen-lg-min) {
    &-title {
      font-size: 64px;
    }
    &-desc {
      font-size: 28px;
    }
    &-sup {
      font-size: 40px;
    }
    &-price {
      top: -44px;
      font-size: 190px;
    }
    * + &-price-wrap {
      margin-top: 26px;
    }
  }
  @media (min-width: $screen-xl-min) {
    &-title {
      font-size: 80px;
    }
    &-desc {
      font-size: 36px;
    }
    &-sup {
      font-size: 60px;
    }
    &-price {
      top: -93px;
      font-size: 300px;
    }
    .btn {
      bottom: 8px;
    }
    &-price-wrap {
      .banner-1-sup:first-child {
        position: relative;
        right: -15px;
      }
    }
    * + &-desc {
      margin-top: 16px;
    }
    * + &-price-wrap {
      margin-top: 57px;
    }
  }
  @media (max-width: $screen-xs-min - 1) {
    .banner-1-title,
    .banner-1-desc,
    .btn {
      @include transform(scale(0.7));
    }

    .banner-1-desc,
    .banner-1-price-wrap {
      margin-top: 0;
    }

    .banner-1-price-wrap {
      @include transform(scale(0.8));
    }

    .btn {
      margin-top: -10px;
    }

    .banner-1-img-wrap img {
      min-height: 0;
    }
  }
}

.banner-2, .banner-3 {
  .banner-img-wrap:before {
    background: rgba($black, .1);
  }

  .banner-caption {
    color: $secondary-1;
  }

  &-desc {
    font-size: 16px;
    line-height: 1.2;
  }

  &-title {
    font-size: 28px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  * + &-title,
  * + &-desc {
    margin-top: 5px;
  }

  * + .btn {
    margin-top: 10px;
  }

  @media (min-width: 600px) {
    &-desc {
      font-size: 15px;
    }
    &-title {
      font-size: 25px;
    }
  }
  @media (min-width: $screen-sm-min) {
    &-desc {
      font-size: 18px;
    }
    &-title {
      font-size: 30px;
    }
    * + &-title,
    * + &-desc {
      margin-top: 8px;
    }
    * + .btn {
      margin-top: 15px;
    }
  }
  @media (min-width: $screen-md-min) {
    .banner-img-wrap:before {
      display: none;
    }
    &-desc {
      font-size: 22px;
    }
    &-title {
      font-size: 40px;
    }

    * + .btn {
      margin-top: 30px;
    }

    .banner-caption-left {
      left: 15px;
      text-align: left;
      @include transform(translateY(-50%));
    }
  }
  @media (min-width: $screen-lg-min) {
    &-desc {
      font-size: 26px;
    }
    &-title {
      font-size: 48px;
    }
    .banner-caption-left {
      left: 30px;
    }
  }
  @media (min-width: $screen-xl-min) {
    &-desc {
      font-size: 36px;
    }
    &-title {
      font-size: 72px;
    }
    * + &-title {
      margin-top: 14px;
    }
    * + &-desc {
      margin-top: 20px;
    }
    * + .btn {
      margin-top: 80px;
    }
    .banner-caption-left {
      left: 50px;
    }
  }
  @media (max-width: 479px) {
     .btn {
      font-size: 12px;
      min-width: 0;
    }
  }
}

.banner-2 {
  &, .banner-img-wrap,
  .banner-img-wrap img {
    height: 100%;
  }
}

.banner-3 {
  @media (min-width: $screen-md-min) {
    .banner-caption-left {
      top: 30px;
      @include transform(none);
    }
  }
  @media (min-width: $screen-lg-min) {
    .banner-caption-left {
      top: 50px;
    }
  }
  @media (min-width: $screen-xl-min) {
    * + .btn {
      margin-top: 68px;
    }
    .banner-caption-left {
      left: 63px;
      top: 78px;
    }
  }
}

.banner-4 {
  &-title {
    font-size: 22px;
  }

  * + &-title {
    margin-top: 5px;
  }
  * + .btn {
    margin-top: 10px;
  }

  @media (min-width: $screen-md-min) {
    .banner-caption-right {
      left: auto;
      right: 15px;
      text-align: right;
      @include transform(translateY(-50%));
    }
    &-title {
      font-size: 26px;
    }
    * + &-title {
      margin-top: 8px;
    }
    * + .btn {
      margin-top: 15px;
    }
  }
  @media (min-width: $screen-lg-min) {
    .banner-caption-right {
      right: 30px;
    }

    &-title {
      font-size: 30px;
    }
    * + &-title {
      margin-top: 12px;
    }
    * + .btn {
      margin-top: 18px;
    }
  }
  @media (min-width: $screen-xl-min) {
    .banner-caption-right {
      right: 50px;
    }

    &-title {
      font-size: 48px;
    }
    * + &-title {
      margin-top: 21px;
    }
    * + .btn {
      margin-top: 57px;
    }
  }
  @media (min-width: 600px) and (max-width: $screen-sm-min - 1) {
    &-title {
      font-size: 16px;
      white-space: nowrap;
    }
  }
}

.banner-5 {
  .banner-img-wrap {
   &:before {
     background: rgba($black, .2);
   }
  }

  &-title {
    font-size: 22px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &-price-desc-start {
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &-price {
    font-size: 64px;
    font-weight: 300;
    line-height: 1;
    color: $primary;
    pointer-events: none;
  }

  &-price-desc-end {
    font-weight: 900;
    line-height: 1.2;
    text-transform: uppercase;
    color: $white;

    > div {
      display: inline-block;
    }
  }

  * + &-price-desc-end {
    margin-top: 10px;
  }

  @media (min-width: $screen-xs-min) {
    &-price {
      font-size: 74px;
    }
    &-price-desc-end {
      font-size: 22px;
    }
  }
  @media (min-width: 600px) {
    &-title {
      font-size: 24px;
    }
    &-price {
      font-size: 84px;
    }
    &-price-desc-end {
      font-size: 20px;
    }
  }
  @media (min-width: $screen-sm-min) {
    &-title {
      font-size: 28px;
    }
    &-price-desc-start {
      font-size: 22px;
    }
    &-price {
      font-size: 94px;
    }
    &-price-desc-end {
      font-size: 26px;
    }
  }
  @media (min-width: $screen-md-min) {
    .banner-img-wrap {
      &:before {
        display: none;
      }
    }

    .banner-5-price-wrap {
      .banner-5-price-wrap-inner {
        position: relative;
        display: inline-block;
      }
    }

    &-price-desc-start,
    &-price-desc-end {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
    }

    &-price-desc-start {
      left: -25px;
    }

    .banner-5-price-wrap .banner-5-price-desc-end {
      @include make-flex;

      > span {
        display: inline-block;
      }
    }

    &-price-desc-end {
      right: 35px;
      @include flex-direction(column);
      @include align-items(center);
    }

    * + &-price-desc-end {
      margin-top: 0;
    }

    &-price {
      font-size: 200px;
      margin-top: -20px;
    }
  }
  @media (min-width: $screen-lg-min) {
    &-title {
      font-size: 40px;
    }
    &-price-desc-start {
      font-size: 28px;
    }
    &-price {
      font-size: 270px;
    }
    &-price-desc-end{
      right: 50px;
      font-size: 32px;

      > div + div {
        margin-top: 10px;
      }
    }
  }
  @media (min-width: $screen-xl-min) {
    &-title {
      font-size: 60px;
    }
    &-price-desc-start {
      left: -8px;
      margin-top: -5px;
      font-size: 48px;
    }
    &-price-wrap-inner {
      margin-top: -40px;
    }
    &-price-desc-end{
      right: 100px;
      margin-top: 15px;
      font-size: 60px;

      > div + div {
        margin-top: 45px;
      }
    }
    &-price {
      font-size: 500px;
    }
    .banner-caption {
      margin-top: 27px;
    }
    * + .btn {
      margin-top: 13px;
    }
  }
}

.banner-6 {
  margin-top: 15px;
  min-height: 130px;

  &:before {
    display: none;
  }

  &.banner-secondary {
    background: $secondary-1;

    .text-primary {
      color: $primary;
    }
  }

  .banner-4-title {
    color: $white;
    line-height: 1.2;
    white-space: pre-wrap;
  }

  .text-bold {
    font-weight: 700;
  }

  @media (min-width: 600px) {
    height: 30%;
    min-height: 0;
    margin-top: 0;
  }
  @media (min-width: $screen-lg-min) {
    .banner-caption {
      padding-right: 120px;
      padding-left: 120px;
    }
  }
  @media (min-width: $screen-xl-min) {
    .banner-4-title {
      line-height: 60px;
    }
    .banner-caption {
      padding-right: 180px;
      padding-left: 180px;
    }
  }
}

.banner-7 {
  @media (min-width: $screen-lg-min) {
    .banner-caption {
      margin-top: -25px;
    }
  }
  @media (min-width: $screen-xl-min) {
      * + .btn {
        margin-top: 90px;
      }
  }
}

.banner-8 {
  .banner-2-desc-bottom {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 5px;

    @media (min-width: $screen-sm-min) { font-size: 16px; }
    @media (min-width: $screen-md-min) { font-size: 20px; }
    @media (min-width: $screen-lg-min) {
      font-size: 22px;
      padding-right: 50px;
      padding-left: 50px;
    }
    @media (min-width: $screen-xl-min) {
      font-size: 24px;
      line-height: 36px;
      padding-right: 100px;
      padding-left: 100px;
    }
  }
  @media (min-width: $screen-xl-min) {
    .banner-caption {
      margin-top: 35px;
    }
  }
}

.banner-darker {
  .banner-img-wrap:before {
    background: rgba($black, .2);
  }

  @media (min-width: $screen-md-min) {
    .banner-img-wrap:before {
      display: none;
    }
  }
}


// Banners Grid

.banners-grid {
  padding-top: 15px;

  .banners-grid {
    padding-top: 0;
  }

  > [class*='banners-column-'] + [class*='banners-column-'] {
    padding-top: 15px;
  }

  > [class*='banners-column-'] {
    .banner + .banner {
      padding-top: 15px;
    }
  }

  .banners-column-50 {
    width: 100%;
  }

  @media (min-width: 600px) {
    @include make-flex;
    @include justify-content(space-between);

    > [class*='banners-column-'] + [class*='banners-column-'] {
      padding-top: 0;
    }

    .banners-column-inset-right {
      padding-right: 7.5px;
    }

    .banners-column-inset-left {
      padding-left: 7.5px;
    }

    .banners-column-70 {
      @include flex-basis(61.4%);
    }

    .banners-column-30 {
      @include flex-basis(38.6%);
    }

    .banners-column-80 {
      @include flex-basis(66.5%)
    }

    .banners-column-inset-bottom {
      padding-bottom: 15px;
    }

    .banners-column-20 {
      @include flex-basis(34.5%);
    }

    .banners-column-grid-vertical {
      @include make-flex;
      @include flex-direction(column);
      @include justify-content(space-between);
    }
  }
}


// Helpers

.text-uppercase {
  text-transform: uppercase;
}

.page-wrapper .banner .reveal-inline-block,
.page-wrapper .reveal-inline-block {
  display: inline-block;
}