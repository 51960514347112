/**
 * Copyright © 2016 TemplateMonster. All rights reserved.
 * See COPYING.txt for license details.
 */

// Local vars
$category-link-size: 20px;
$category-link-size-small: 16px;
$category-link-color: $default;
$category-link-color-hover: $primary;

$subcategory-link-size: 18px;
$subcategory-link-size-small: 14px;
$subcategory-link-color: #000000;
$subcategory-link-color-hover: #7d7d7d;

$link-size: 16px;
$link-color: #1979c3;
$link-color-hover: #000000;


// Main styles
.catalog-image-grid {
	padding: 70px 15px;
	margin-bottom: 20px;

	.block-title {
		text-align: center;

		strong {
			font-size: 34px;
			font-weight: 400;
			color: $black;
		}
	}

	ul{
		list-style: none;
		padding: 0;
		margin: 0;

		img {
			max-width: 100%;
		}
	}

	/************** Category **************/
	.category-grid,
	.category-list {
		overflow: hidden;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -8px;

		> li {
			padding: 0 8px;
			margin: 0 0 20px;
			box-sizing: border-box;

			@media (max-width: 767px) {
				-moz-flex-basis: 33.333%;
				-ms-flex-basis: 33.333%;
				flex-basis: 33.333%;
			}
			@media (max-width: 500px) {
				-moz-flex-basis: 50%;
				-ms-flex-basis: 50%;
				flex-basis: 50%;
			}
			@media (max-width: 350px) {
				-moz-flex-basis: 100%;
				-ms-flex-basis: 100%;
				flex-basis: 100%;
			}

			.grid-inner {
				box-sizing: border-box;
			}
		}

		.category-link {
			display: inline-block;
			text-decoration: none;
			font-weight: 700;
			font-size: $category-link-size;
			color: $category-link-color;
			text-align: center;

			&:hover {
				color: $category-link-color-hover;
				
				.category-image {
					background: $white;
				}
			}

			@media (max-width: 767px) {
				font-weight: 600;
				font-size: $category-link-size-small;
			}

			.category-name {
				display: inline-block;
				margin-bottom: 10px;
			}
			.category-image {
				display: block;
				margin-bottom: 20px;
			}

			.category-icon {
				display: inline-block;
				font-style: normal;

				&:before {
					line-height: normal;
					margin-right: 5px;
				}
			}
		}

		.button-more {
			text-decoration: none;
			font-size: $link-size;
			color: $link-color;

			&:hover {
				color: $link-color-hover;
			}
		}

		/************** SubCategory **************/
		.subcategory-grid {

			margin-bottom: 10px;

			> li {
				padding: 0;
				margin: 0;
				line-height: 30px;
			}
			.subcategory-link {
				display: inline-block;
				text-decoration: none;
				font-weight: 500;
				font-size: $subcategory-link-size;
				color: $subcategory-link-color;

				&:hover {
					color: $subcategory-link-color-hover;
				}

				@media (max-width: 767px) {
					font-size: $subcategory-link-size-small;
				}

				.subcategory-name,
				.subcategory-image {
					display: inline-block;
				}

				.subcategory-image {
					vertical-align: middle;
					margin-right: 10px;

					img {
						vertical-align: middle;
					}
				}

				.category-icon {
					display: inline-block;
					font-style: normal;

					&:before {
						line-height: normal;
						margin-right: 5px;
					}
				}
			}
		}
	}

	.category-grid {
		max-width: 1590px;
		margin-right: auto;
		margin-left: auto;
		
		.category-link {
			.category-image {
				@include make-flex;
				@include align-items(center);
				@include justify-content(center);
				min-height: 257px;
				padding: 15px;
				background: #fbfbfc;
				border: 2px solid #fbfbfc;
				transition: .3s all ease;

				@media (min-width: 650px) {
					min-height: 290px;
				}
				@media (min-width: $screen-sm-min) {
					min-height: 300px;
				}
				@media (min-width: $screen-md-min) {
					min-width: 220px;
					min-height: 220px;
				}
				@media (min-width: $screen-lg-min) {
					min-width: 270px;
					min-height: 300px;
				}
				@media (min-width: $screen-lg-min + 400) {
					min-width: 370px;
					min-height: 370px;
				}
			}
		}

		@media (min-width: 480px) and (max-width: 767px) {
				&.columns- > li {
					@include flex-basis(50%);
				}
		}
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
				&.columns- > li {
					@include flex-basis(40%);
				}
		}
		@media (max-width: $screen-md-min - 1) {
			@include justify-content(center);
		}
		@media (max-width: 479px) {
				&.columns- > li {
					text-align: center;
					@include flex-basis(100%);
				}

			.grid-inner {
				display: inline-block;
			}
		}
	}

	.block-title + .category-grid {
		margin-top: 30px;

		@media (min-width: $screen-md-min) {
			margin-top: 45px;
		}
	}

	.grid-wrapper {
		overflow: hidden;

		.category-image {
			float: left;
			width:33%;
			margin-right: 20px;
		}

		.subcategories {
			overflow: hidden;
			float: left;
		}
	}

	@media (min-width: $screen-lg-min) {
		padding-top: 145px;
		padding-bottom: 94px;
	}
}