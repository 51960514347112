//
//  Filters
//  __________________________________________________


//Current filters
.filter-current--wrapper{
    margin-bottom: $indent-small;
    background: $primary;
    padding: 25px 10px 25px 25px;

    .filter-current .remove:hover {
        color: $secondary-1;
    }

    //Filter actions
    .filter-actions{
        margin-top: 7px;
        font-weight: 700;
        font-size: 16px;

        a{
            color: $white;
            &:hover{
                color: $secondary-1;
            }
        }
    }
}

.filter-current{
    &.active{
        .filter-current-subtitle{
            margin-bottom: 0;
        }
        .items{
            display: none;
        }
    }

    .filter-current-subtitle{
        @extend .heading-4;
        color: $white;
    }

    .items{
        display: block;
        color: $white;
    }

    .filter-label{
        &:after{
            content: ':';
            padding: 0 2px 0 4px;
        }
    }

    .remove{
        color: $white;
        @include font-icon("\e5cd");
        position: relative;
        padding-right: 20px;
        &:before{
            line-height: 22px;
            position: absolute;
            top: 1px;
            right: 0;
        }
        &:hover{
            color: $primary;
        }
        span{
            display: none;
        }
    }

    &[role="tablist"] {
        //Tablist title
        [role="tab"] {
            @include font-icon('\e5ce');
            &[aria-expanded="true"] {
                @include font-icon('\e5cf');
            }
        }
    }
}

#layered-filter-block .modal-slide._show{
    background-color: transparent;
}


//Filter options

.filter-options-title{
    @extend .heading-4;
    @include font-icon('\e5cf');
    position: relative;
    cursor: pointer;
    &:before{
        position: absolute;
        top: 2px;
        right: 2px;
        left: auto;
        bottom: auto;
        width: 20px;
        height: 31px;
    }
}

.sidebar .filter-content .filter-options-title{
    display: block;
}

.filter-options-content{
    .item a{
        text-transform: capitalize;
        color: $default;
        font-size: 14px;
        font-weight: 300;

        &:hover{
            color: $primary;
        }
    }

    .count{
        display: inline-block;
        width: auto;
        margin-left: $indent-small / 2;
        &:before{
            content: '(';
        }
        &:after{
            content: ')';
        }

        .filter-count-label{
            display: none;
        }

        @media (min-width: $screen-sm-min){
            @media (max-width: $screen-md-min - 1){
                width: 100%;
                margin-left: 0;
            }
        }
    }
}

.filter-toggle{
    @include order(0);
    @include font-icon('\e5d2');
    @include flex-basis(25%);
    @include justify-content(flex-start);
    text-align: left;
    font-size: 2rem;
    line-height: 1;
    padding-right: 10px;
    &:before{
        vertical-align: bottom;
    }

    @media (min-width: $screen-sm-min){
        display: none;
    }

    span{
        display: none;
    }
}

[role="tablist"] .filter-options-content[role="tabpanel"]{
    margin-bottom: $indent-small;
}


@media (max-width: $screen-sm-min) {
    #layered-filter-block{
        margin: 0;
    }
}
