
//
//  Toolbar
//  ---------------------------------------------

.toolbar-products {
    @include make-flex();
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    margin-bottom: $indent-base;
    text-align: center;

    p,
    .field{
        @include make-flex();

        .label{
            margin-right: 10px;
        }
    }

    label.label{
        @media(min-width: $screen-sm-min){
            @media(max-width: $screen-md-min - 1){
                display: none;
            }
        }
    }

    @media (max-width: $screen-sm-min - 1){
        .filter-toggle,
        .field.toolbar-sorter,
        p.toolbar-amount,
        .field.limiter,
        .modes,
        .pages{
            margin-bottom: 8px;
        }

        .field.toolbar-sorter {
            max-width: 160px;

            .select2-container {
                max-width: 120px;

                .select2-selection {
                    &__rendered {
                        min-width: 85px;
                    }

                    &__arrow {
                        top: 4px;
                        right: 7px;
                    }
                }
            }
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow {
            top: 4px;
            right: 7px;
        }
    }
}

.mobile {
    .toolbar-products .field.toolbar-sorter {
        max-width: 120px;
    }

    .field.toolbar-sorter .sorter-action.sort-asc {
        margin-left: 2rem;
    }

    .field.limiter .limiter-options {
        padding-left: 25px;
        padding-right: 0;
    }
}

.toolbar-container{
    .filter-toggle{
        @include button-block(); //@mixin source/_mixins.scss
        margin-bottom: 20px;
        display: none;
    }
}
.tollbar-bottom .filter-toggle{
    display: none !important;
}

//Sorter
.field.toolbar-sorter{
    @include order(3);
    @include flex-basis(180px);
    @include justify-content(flex-start);
    @include align-items(center);
    margin-bottom: 15px;

    @media (min-width: $screen-xs-min){
        margin-bottom: 0;
    }
    @media (min-width: $screen-sm-min){
        @include order(2);
        @include flex-basis(auto);

        .select2-container{
            max-width: none;
            min-width: 16.66667vw;
        }
        .select2-selection--single{
            min-width: 16.66667vw;
        }
    }
    @media (min-width: $screen-lg-min) and (max-width: $screen-xl-min - 1) {
        .select2-container{
            min-width: 14.66667vw;
        }
        .select2-selection--single{
            min-width: 14.66667vw;
        }
    }

    .label{
        display: none;
    }
    .sorter-action{
        margin-left: $indent-small;
        padding: $input-padding-vert 0;
        line-height: $input-line-height;
        span{
            display: none;
        }
        &.sort-asc,
        &.sort-desc{
            @include font-icon('\e0b2');
            color: lighten(saturate(adjust_hue($default, 0), 0%), 36.86274509803921%);
            font-size: 1.5rem;
            line-height: 1;
            &:hover{
                color: $primary;
            }
        }
        &.sort-desc{
            transform: rotate(180deg);
        }
    }
}

//Amount
p.toolbar-amount{
    @include order(1);
    @include align-items(center);
    @include make-flex();
    @include flex-basis(50%);
    @include justify-content(center);
    margin-bottom: 0;

    span.toolbar-number{
        &:first-child{
            margin-left: .3em;
        }
        &:nth-child(2){
            margin-right: .3em;
        }
        &:last-child{
            margin-left: .3em;
        }
    }

    @media (min-width: $screen-sm-min){
        @include order(3);
        @include flex-basis(auto);
        margin-left: auto;
        margin-right: 3.65vw;
    }
}

//Limiter
.field.limiter{
    @include order(4);
    @include flex-basis(100px);
    @include justify-content(flex-start);
    @include align-items(center);
    margin-bottom: 0;

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        min-height: 50px;
        line-height: 45px;
    }

    .label{
        display: none;
    }

    @media (min-width: $screen-xs-min){
        @include justify-content(flex-end);
    }
    @media (min-width: $screen-sm-min){
        @include flex-basis(50%);
        @include order(4);
        @include flex-basis(auto);
        @include justify-content(flex-start);

        .label{
            display: inline-block;
        }
    }

    .limiter-text{
        display: none;
    }

    .select2-container{
        min-width: 90px;
    }
}

.tollbar-bottom{
    .modes,
    .toolbar-amount,
    .limiter,
    .toolbar-sorter{
        display: none;
    }
}

// Modes
.modes{
    @include order(2);
    @include make-flex;
    @include justify-content(flex-end);
    @include align-items(center);
    @include flex-basis(25%);
    margin-bottom: 0;

    @media (min-width: $screen-sm-min){
        @include order(1);
        @include flex-basis(auto);
        @include justify-content(space-between);
        margin-right: auto;

        @media (max-width: $screen-md-min - 1){
            @include flex-basis(100%);
            @include justify-content(flex-start);
            margin-bottom: 10px;
        }
    }

    .modes-label{
        display: none;
    }
    .modes-mode{
        margin-left: .5em;
        font-size: 28px;
        color: $secondary-2;
        &:hover{
            color: $primary;
        }

        span{
            display: none;
        }
        &.active{
            color: $primary;
            &:hover{
                color: $primary;
            }
        }
    }
    .mode-grid{
        @include font-icon('\e8f0');
        font-size: 1.5rem;
        line-height: 1;
    }
    .mode-list{
        @include font-icon('\e8ef');
        font-size: 1.5rem;
        line-height: 1;
    }
}

//Pages
.pages{
    @include order(9);
    @include flex-basis(100%);
    display: none;
    margin-bottom: 0;

    .pages-items{
        position: relative;
        display: inline-block;
        @include clearfix;
        @include make-flex;
        @include justify-content(center);
        @include align-items(center);

        @media (min-width: $screen-md-min) {
            @include justify-content(space-between);
        }
    }
    .item{
        padding: 0 21px;
        font-size: 16px;
        line-height: 1;
        color: $default;
        &.current{
            color: $primary;
        }
        &.pages-item-previous,
        &.pages-item-next{
            padding: 0;
            a{
                @include make-flex;
                @include justify-content(center);
                @include align-items(center);
            }

            @media (max-width: $screen-md-min - 1){
                a{
                    background: none;
                    color: $secondary-1;
                    padding: 5px;
                    &:hover{
                        background: none;
                        color: $primary;
                    }
                }
            }
        }
        &.pages-item-previous{
            @media (min-width: $screen-md-min) {
                margin-right: auto;
                a{
                    padding: 15px 42px 15px 32px;
                }
            }
        }
        &.pages-item-next{
            @media (min-width: $screen-md-min) {
                margin-left: auto;
                a{
                    padding: 15px 32px 15px 42px;
                }
            }
        }
        &.pages-item-previous{
            a span:not(.label){
                @include font-icon('\e5cb');
                padding-right: 15px;
                &:before{
                    display: block;
                }
            }
        }
        &.pages-item-next{
            a span:not(.label){
                @include font-icon('\e5cc');
                padding-left: 15px;
                &:before{
                    display: block;
                }
            }
        }
    }
    .tollbar-bottom & {
        display: block;
    }
}