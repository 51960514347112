
//
//  Product view
//  _____________________________________________

.catalog-product-view{
    .main{
        @include make-flex();
        @include make-row();
        @include flex-wrap(wrap);
    }

    .imagezoom{
        .gallery-placeholder{
            @include make-flex;
            @include justify-content(center);
            margin-right: -2px;
            margin-left: -2px;
        }
        .main{
            margin-right: 0;
            margin-left: 0;
        }
    }

    #fancybox-content{
        background: $white;
    }

    //Product info
    .product-info-main{
        @include make-col();
        @include order(2);
        @include flex-basis(100%);
    }

    //Stock, sku
    .product-info-stock-sku{
        margin-bottom: $indent-small;
        font-size: 16px;
        @include make-flex;
        @include justify-content(flex-start);
        @include flex-wrap(wrap);

        @media (min-width: $screen-lg-min){
            @include justify-content(space-between);
        }

        .available{
            color: $primary;
            padding-right: 2em;
        }
        .unavailable{
            color: $primary;
            padding-right: 2em;
        }

        .attribute.sku .type{
            display: none;
        }
    }

    //Title
    .page-title-wrapper{
        margin-top: 2rem;
        margin-bottom: 3rem;
        line-height: 1;
        text-align: center;
        padding: 0 5vw;

        .container,
        h1{
            max-width: none;
            margin: 0;
            padding: 0;
            font-size: 20px;
            line-height: 1;

            @media (min-width: $screen-xs-min) {
                font-size: 22px;
            }
            @media (min-width: $screen-sm-min) {
                font-size: 25px;
            }
            @media (min-width: $screen-md-min) {
                font-size: 35px;
            }
            @media (min-width: $screen-lg-min) {
                font-size: 45px;
            }
        }

        .container {
            min-width: 0;
        }
    }


    .product-price-review-wrap{
        @include make-flex;
        @include justify-content(flex-start);
        @include align-items(center);
        @include flex-wrap(wrap);
        padding-bottom: $indent-small;

        @media (min-width: $screen-lg-min){
            @include flex-wrap(nowrap);
            @include justify-content(space-between);
        }

        .price-box{
            padding-right: 30px;
        }
    }

    //Review
    #tab-label-reviews-title{
        @media (min-width: $screen-sm-min){
            display: none;
        }
    }
    .product-reviews-summary{
        @include make-flex;
        @include align-items(center);

        .reviews-actions {
            line-height: 1;
        }
    }
    .product-info-main .rating-summary{
        padding-right: 1rem;
        min-width: 110px;
        .label{
            display: none;
        }

        .rating-summary .rating-result__stars span.fa{
            font-size: 14px;
            line-height: 16px;
        }
    }
    .reviews-actions{
        .action.view{
            font-size: 15px;
            line-height: 16px;
        }
        .action.add{
            display: none;
        }
    }
    #tab-label-reviews-title{
        span.counter{
            &:before{
                content: '(';
            }
            &:after{
                content: ')';
            }
        }
    }

    //Price
    .page-main .price-box{
        color: $primary;
        font-size: 19px;
        line-height: 1;
        font-weight: 400;
        @include make-flex;
        @include flex-wrap(wrap);
        @include align-items(flex-start);

        .price-label{
            display: none;
        }
        .timer-wrapper{
            font-size: 20px;
        }
        .old-price{
            color: $unactive;
            text-decoration: line-through;
            font-size: 14px;
            line-height: 1;
            padding: 10px 0 0 10px;
        }

        @media (min-width: $screen-sm-min) {
            font-size: 29px;
        }
        @media (min-width: $screen-md-min) {
            font-size: 39px;
        }
        @media (min-width: $screen-lg-min) {
            font-size: 49px;
            line-height: 49px;
        }
    }

    //Short Description
    .overview{
        margin-bottom: 1rem;
    }

    //Add form
    .product-add-form{
        margin-bottom: $indent-small;
    }

    .box-tocart{
        .fieldset{
            @include make-flex;
            @include flex-direction(row);
            @include flex-wrap(wrap);

            @media (max-width: $screen-sm-min - 1) {
                @include justify-content(space-between);
                max-width: 260px;
                margin-right: auto;
                margin-left: auto;
            }
        }
        .field.qty{
            @include flex-basis(150px);
            @include make-flex;
            padding-right: 0;
            .label{
                display: none;
            }
            .control input{
                text-align: center;
                padding: 18px;
                width: 100%;
                border-radius: 100px;
                max-height: 59px;

                @media (max-width: $screen-sm-min - 1) {
                    padding: 5px;
                    min-height: 42px;
                    max-width: 80%;
                }
            }

            @media (min-width: $screen-lg-min){
                padding-right: 2rem;
            }
        }
        .actions {
            .action{
                padding: 16px 32px;
                min-width: 100px;

                @media (max-width: $screen-sm-min - 1) {
                    padding: 9px 12px;
                }
            }

            @media (min-width: $screen-md-min) {
                padding-left: 15px;
            }
        }
    }

    //Social Links
    .product-social-links{
        font-size: 1.5rem;
        line-height: 1;
        margin-bottom: 1rem;

        .action span{
            display: none;
        }
    }

    //Options
    .swatch-attribute{
        @include make-flex;
        @include flex-direction(column);
        @include align-items(center);
        @include flex-wrap(wrap);
        margin-bottom: 2rem;
        position: relative;

        @media (min-width: $screen-lg-min){
            @include flex-direction(row);
        }

        .swatch-attribute-label{
            font-weight: 300;
            display: inline-block;
            min-width: 80px;
            font-size: 16px;
            color: #111;
            text-align: center;
            padding-top: 6px;
            padding-bottom: 6px;

            @media (min-width: $screen-lg-min) {
                text-align: left;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .swatch-attribute-selected-option{
            display: none;
        }

        [id*='super_attribute['].mage-error{
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            padding: 5px 10px;
        }
    }
    .swatch-option{
        min-width: 50px;
        margin-bottom: 0;
        &.color{
            min-height: 50px;
        }
        &.text{
            min-width: 50px;
            padding: 15px 20px;
        }
    }
    .product-options-wrapper{
        .select2-container {
            min-width: 250px;
        }

        .field{
            @include make-flex;
            @include flex-direction(column);
            @include align-items(center);

            @media (min-width: $screen-lg-min){
                @include flex-direction(row);
            }

            &.required{
                margin-bottom: 2rem;
            }


            .control{
                @include flex-basis(100%);
                @media (min-width: $screen-lg-min){
                    @include flex-basis(60%);
                }
            }
            .label{
                font-weight: 300;
                font-size: 16px;
                color: #111;
                @include flex-basis(100%);
                @media (min-width: $screen-lg-min){
                    @include flex-basis(40%);
                }
                @media (max-width: $screen-lg-min - 1) {
                    margin-right: 0;
                }
            }
        }
    }

    //Add to links
    .product-addto-links{
        display: inline-block;

        .towishlist:before {
            content: '\e87e';
            font-family: $default-icon-family;
        }

        .tocompare:before {
            content: '\e043';
            font-family: $default-icon-family;
        }
    }

    .product{
        //Attributes
        &.attribute{
            strong{
                font-weight: 600;
            }
            .value{
                display: inline-block;
            }
        }

        //Product media
        &.media{
            @include order(1);
            @include make-col();
            @include flex-basis(100%);
        }

        //Product details
        &.info{
            @include order(3);
            margin: $indent-base 0;
            @include flex-basis(100%);
            background: $white;

            @media (min-width: $screen-sm-min){
                @include make-col();
            }

            .description p {
                //text-indent: 1.5em;
            }

            .content[role="tabpanel"]{
                background: $white;
            }
        }
    }

    //Tabs
    .data.items {
        position: relative;
        &:after{
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: $border-color;
        }
        .title {
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            display: block;
            line-height: 1;
            margin-right: 0;
            padding: .5rem 1rem;
            font-size: 20px;
            font-weight: 700;

            @media (min-width: $screen-sm-min){
                border-bottom: none;
                padding: 40px 10px 10px 0;
                margin-bottom: 0;
                cursor: default;
                a{
                    cursor: default;
                }
                a:hover{
                    color: $black;
                }
            }
            &:before{
                top: 9px;
                right: 12px;
                @media (min-width: $screen-sm-min){
                    display: none;
                }
            }
        }

        .content {
            font-size: 16px;
            line-height: 26px;
            @media (max-width: $screen-sm-min - 1){
                padding: 0 3vw;
            }
            @media (min-width: $screen-sm-min){
                display: block !important;
            }
            &.active {
                display: block;
            }
            &[role="tabpanel"] {
                display: block;
            }
            .table-caption{
                display: none;
                text-align: left;
                @media (min-width: $screen-sm-min){
                    display: table-caption;
                }
            }

            .additional-attributes-wrapper{
                margin-top: 15px;
                .attributes-label,
                .attributes-data{
                    border-bottom: 1px solid $border-color;
                }
                .attributes-row:first-child{
                    .attributes-label,
                    .attributes-data{
                        border-top: 1px solid $border-color;
                    }
                }
                .attributes-label{
                    background: $bg-dark;
                }
                .attributes-label,
                .attributes-data{
                    @include flex-basis(50%);
                }
                @media (min-width: $screen-sm-min){
                    .attributes-label,
                    .attributes-data{
                        @include flex-basis(320px);
                    }
                }
            }

            .review-form .review-field-text{
                margin-bottom: 2rem;
            }
        }

        //Attributes
        .additional-attributes{
            .attributes-caption{
                @extend .heading-4;
            }
            .attributes-row{
                @include make-flex;
            }
            .attributes-label{
                border-right: 1px solid $border-color;
                @include flex-basis(150px);
                padding: $indent-small $indent-base;
            }
            .attributes-data{
                padding: $indent-small $indent-base;
            }
        }
    }

    .block.upsell,
    .block.related{
        .price-box{
            font-size: 20px;
            line-height: 20px;
        }
    }

    .products-grid .product-item-details {
        @include make-flex;
        @include flex-wrap(wrap);
        @include align-items(center);

        .product-item-name {
            width: 100%;
        }

        @media (max-width: $screen-sm-min) {
            .product-item-actions .actions-secondary > .action {
                @include flex-basis(auto);
            }
        }

        .price-box {
            width: 100%;
            margin-bottom: 10px;
        }

        .choice.related label {
            cursor: pointer;
        }

        .product-item-actions .actions-secondary {
            .action + .action {
                margin-left: 5px;
            }
        }
    }
}

@media (min-width: $screen-sm-min){
    .catalog-product-view{
        .product-info-main{
            @include flex-basis(40%);
            max-width: 40%;
        }
        .product{
            &.media{
                @include flex-basis(60%);
                max-width: 60%;
            }
        }
    }
}
