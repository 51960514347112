/*
* TM_Megamenu
*/

// Module Styles
//--------------------

@import '_vars.scss';
@import '_topNav.scss';
@import '_sidebarNav.scss';

// Main Styles

.navigation{
    position: relative;
    margin-top: 2px;
    margin-bottom: 3px;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    @media (min-width: $screen-sm-min) {
        .row{
            margin-bottom: 20px;
        }

        ul.vm-static,
        ul.vm-easing {
            margin-left: 10px;
        }
        ul.vm-pop-up {
            position: absolute;
            z-index: 9;
            top: 100%;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: 1px 1px 5px #cccccc;
        }
    }

    @media (max-width: $screen-sm-min - 1) {

        li.level-top .megamenu-wrapper,
        a.level-top .megamenu-wrapper {
            padding: 0;
            background: none;
        }

        [class*="col-"]{
            padding: 0;
        }
    }


}


//Theme Styles
//--------------------


.rd-navbar-static .navigation .container{
    position: relative;
    @include make-flex;
    @include justify-content(center);
    @include align-items(center);

    .row{
        @media (min-width: $screen-lg-min) {
            margin-bottom: 15px;
        }
    }

    .container{
        position: relative;
    }

    @media (min-width: $screen-lg-min) {
        .row{
            @include flex-basis(100%);
            margin-bottom: 58px;
        }
    }


    li.level-top,
    a.level-top{
        .megamenu-wrapper {
            top: calc(100% + 3px);
            left: 0;
            width: 100%;
            padding: 15px 0 0;
            background: $secondary-1;
            box-shadow: none;

            @media (min-width: $screen-sm-min) {
                width: 760px;
                left: 50%;
                margin-left: -380px;
            }
            @media (min-width: $screen-md-min) {
                width: 980px;
                left: 50%;
                margin-left: -490px;
            }
            @media (min-width: $screen-lg-min) {
                width: 1130px;
                left: 50%;
                margin-left: -565px;
                padding: 40px 25px 0;
            }
            @media (min-width: $screen-xl-min - 100px) {
                width: 100%;
                left: 0;
                margin-left: 0;
                padding: 58px 0 0;
                &:after{
                    content: '';
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    bottom: 0;
                    right: -3000px;
                    left: -3000px;
                    background: $secondary-1;
                }

                .row > .mm-col-sm{
                    @include flex-basis(16.666667%);
                }
                .row > .mm-col-lg{
                    @include flex-basis(33.333333%)
                }
            }
        }
        .mm-label{
            background-color: $secondary-1;
            top: 4px;
            left: 100%;
            font-size: 14px;
            line-height: 1;
            padding: 2px 4px 0;

            &.new{
                background-color: #0ec2fd;
            }
            &.hot{
                color: $primary;
                background-color: #fffc13;
            }
        }
        .mm-product-title{
            display: block;
            font-weight: 400;
            font-size: 30px;
            line-height: 1;
            color: $primary;
            padding: 6px 0;
            margin-bottom: 12px;
            text-transform: uppercase;
        }
    }

    .cms-index-index & {
        li.level-top .megamenu-wrapper{
            top: 100%;
        }
        .rd-navbar-nav > li.focus > a:after{
            top: calc(100% + 17px);
        }
        .rd-navbar-nav > li > .rd-navbar-dropdown{
            top: calc(100% + 26px);
        }
    }

    .mmenu-1,
    .mmenu-2{
        @media (min-width: $screen-lg-min){
            padding-top: 12px;

            img {
                min-height: 328px;
            }
        }
        a{
            display: inline-block;
            position: relative;
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba($black, .35);
                opacity: 0;
                will-change: transform;
                transition: .3s all ease;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
    .mmenu-2{
        @media (min-width: $screen-xl-min - 100px){
            text-align: right;
        }
    }

    li.level-top .tm-megamenu ul.level1 > li > a{
        font-weight: 400;
        font-size: 20px;
        letter-spacing: .03em;
        padding-bottom: 16px;
        color: $gray-lighten;
        text-transform: uppercase;

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
            font-size: 14px;
            line-height: 1.2;
        }

        &:hover,
        &:focus {
            color: $primary;
        }

        &:before{
            display: none;
        }
    }
}

.rd-navbar-static{
    .tm-megamenu {
        margin-top: -25px;

        .mm-cols {
            @include make-flex;
            @include flex-wrap(nowrap);

            > ul.mm-submenu {
                padding-right: 10px;
                @include flex-basis(33.3333%)
            }
        }
    }

    .rd-navbar-nav > li.parent {
        > ul.level0:not(.rd-navbar-dropdown){
            position: absolute;
            z-index: 80;
            padding: 8px 0;
            color: $default;
            background: $bg-light;
            text-align: left;
            opacity: 0;
            visibility: hidden;
            transition: .3s ease;
            @include transform(translateY(30px));
        }

        &:hover{
            > ul.level0:not(.rd-navbar-dropdown){
                opacity: 1;
                visibility: visible;
                @include transform(translateY(0));
            }
        }

        li > a{
            display: inline-block;
            font-size: 14px;
            line-height: 24px;

            @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
                font-size: 11px;
            }
        }
    }

    .rd-navbar-subpanel{
        display: table;
    }

    .rd-navbar-outer .rd-navbar-inner{
        position: relative;
    }

    .rd-navbar-dropdown,
    .rd-navbar-megamenu{
        position: absolute;
        z-index: 99;
        color: $default;
        background: $bg-light;
        text-align: left;
        opacity: 0;
        visibility: hidden;

        transition: .3s ease;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);

        li{
            color: $white;

            > a{
                display: inline-block;
                font-size: 16px;
                line-height: 18px;
                padding: 6px 16px;
                font-weight: 400;
                letter-spacing: .03em;
                color: $gray-lighten;
                text-transform: uppercase;

                @media (min-width: $screen-md-min) {
                    position: relative;
                    padding-left: 32px;
                    &:before{
                        @include font-icon__pseudo('\f101', $fa);
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -14px;
                        transition: .3s left ease, .3s color ease;
                        color: $default;
                    }
                    &:hover:before{
                        left: 5px;
                        color: $primary;
                    }
                }
            }

            &.focus > a,
            > a:hover {
                color: $primary;
            }

            &.rd-navbar-submenu:after{
                @include font-icon__pseudo('\e5cc');
                position: absolute;
                right: 20px;
                top: 50%;
                margin-top: -13px;
            }
        }
    }

    .rd-navbar-dropdown{
        width: 220px;
        padding: 10px 0;
        li{
            padding: 0 10px;

            a{
                padding: 5px 10px;
            }
        }
        @media (min-width: $screen-md-min) {
            width: 360px;
            padding: 34px 0;

            li{
                padding: 0 50px;

                a{
                    padding: 8px 30px;
                }
            }
        }
    }

    .rd-navbar-megamenu{
        left: 0;
        right: 0;
        display: table;
        table-layout: fixed;
        width: 100%;
        padding: 16px 8px;

        > li{
            display: table-cell;
        }

        .mm-submenu.level1{
            a{
                color: $gray;
                padding: 5px 10px 5px 0;
                text-transform: none;

                @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
                    padding: 2px 10px 2px 0;
                }

                &:hover,
                &:focus {
                    color: $primary;
                }

                @media (min-width: $screen-md-min) {
                    padding: 8px 10px 8px 0;
                }
            }
        }
        .mm-submenu.level2 a{
            @media (min-width: $screen-md-min) {
                padding-left: 32px;
            }
        }
    }

    .rd-navbar-nav{
        display: block;
        text-align: center;
        margin: 30px 0 30px;

        > li{
            &.rd-navbar--has-dropdown > a,
            &.rd-navbar--has-megamenu > a{
                @include font-icon('\e5cf');
                &:before {
                    position: absolute;
                    top: 50%;
                    right: 1px;
                    margin-top: -12px;
                    font-size: 17px;
                    line-height: 22px;
                    color: $default;
                }
            }
        }
        li {

            &.rd-navbar--has-dropdown{
                position: relative;
            }

            &.rd-navbar--has-megamenu > a{
                position: relative;
            }

            &.focus,
            &.opened{
                > .rd-navbar-dropdown, > .rd-navbar-megamenu{
                    opacity: 1;
                    visibility: visible;-webkit-transform: translateY(0);
                    transform: translateY(0);
                }
            }
        }

        > li{
            display: inline-block;
            margin-right: 9px;

            @media (min-width: $screen-md-min) {
                margin-right: 20px;
            }
            @media (min-width: $screen-lg-min) {
                margin-right: 20px;
            }
            &.rd-navbar-submenu > a{
                padding-right: 20px;
            }

            > .rd-navbar-dropdown,
            > .rd-navbar-megamenu{
                top: calc(100% + 29px);
                z-index: 99;
            }

            > .rd-navbar-dropdown{
                left: -38px;
                background: $secondary-1;

                .rd-navbar-dropdown{
                    left: 100%;
                    top: -8px;
                    z-index: 2;
                    background: $secondary-1;

                    a{
                        color: $white;
                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }

            > a{
                display: block;
                font-weight: 600;
                padding: 8px;
                color: $secondary-1;
                font-size: 17px;
                line-height: 1 !important;
                letter-spacing: .01em;
                text-transform: uppercase;

                &:before {
                    transition: .2s all ease;
                }
            }

            &.active > a, &.opened > a,  &.focus > a, > a:hover{
                color: $primary;

                &:before {
                    color: $primary;
                }
            }

            &.parent{
                > {
                    position: relative;
                }
                > a:after{
                    content: '';
                    position: absolute;
                    top: calc(100% + 29px);
                    left: 50%;
                    margin-left: -7px;
                    opacity: 0;
                    visibility: hidden;
                    @include triangle(top, 14px, 9px, $secondary-1);
                }

                &.focus > a:after{
                    top: calc(100% + 20px);
                    opacity: 1;
                    visibility: visible;
                    transition: .1s .2s ease;
                }
            }
        }
    }

    &.rd-navbar--is-stuck{
        .rd-navbar-nav{
            margin: 0;
        }

        .tm-megamenu {
            margin-top: 10px;
        }

        .rd-navbar-nav > li{
            > a{
                padding: 5px 10px;
            }

            &.rd-navbar--has-dropdown > a{
                padding-right: 20px;
            }

            > .rd-navbar-dropdown,
            > .rd-navbar-megamenu{
                top: calc(100% + 3px) !important;
            }
        }
    }

}

.rd-navbar-static .rd-navbar-nav > li.rd-navbar-submenu > a{
    padding-right: 20px;
}

.sidebar .navigation li.level-top {
    a.level-top {
        padding: 10px;
        color: $secondary-2;
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
        &:hover{
            color: $primary;
        }
    }
}

.rd-navbar-fixed .navigation li.level-top .megamenu-wrapper{
    box-shadow: none;
}

.rd-navbar-nav-title{
    display: none;
    visibility: hidden;
    opacity: 0;
}

@media (max-width: $screen-sm-min - 1) {

    .navigation li.level-top, 
    .navigation a.level-top{
        position: relative;
    }

    .rd-navbar-fixed .rd-navbar-submenu {
        .megamenu-wrapper.rd-navbar-megamenu ul li li > a {
            padding-left: 15px;
            padding-right: 0;
        }
    }
}
