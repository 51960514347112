
//
//  Generic Grid/List Item
//  ---------------------------------------------

.products .product-item,
.products-grid .product-item{

    // Image
    .product-image-container{
        font-size: 0;
    }

    .list_product-item-photo_wrap{
        display: inline-block;
        background: $bg-dark;
    }

    //Price
    .price-box{
        font-size: 18px;
        line-height: 1;
        color: $primary;
        font-weight: 700;

        .price-label{
            display: none;
        }
    }
    .special-price{
        color: $primary;
    }
    .old-price{
        .price{
            text-decoration: line-through;
            font-size: 14px;
            line-height: 18px;
            color: #5f5f5f;
            font-weight: 300;
        }
    }

    //Name
    .product-item-name{
        a{
            color: $secondary-1;
            &:hover{
                color: $primary;
            }
        }
    }

    //Details
    .product-item-name{
        display: block;
        font-size: 16px;
        line-height: 1.6;
        font-weight: 400;
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .product-item-description{
        margin-bottom: 1em;
        font-size: 14px;
        line-height: 22px;
        p{
            margin-bottom: 0;
        }

        .action{
            color: $primary;
            &:hover{
                color: $secondary-1;
            }
        }
    }

    //Reviews
    .product-reviews-summary{
        @include make-flex;
        @include align-items(center);

        @media (min-width: $screen-lg-min){
            margin-bottom: $indent-small;
        }
    }
    .rating-summary{
        .label{
            display: none;
        }
    }
    .reviews-actions{
        line-height: 1;
        .view{
            margin-right: $indent-small;
            font-size: 15px;
            line-height: 1;
            color: $secondary-1;
            &:hover{
                color: $primary;
            }
        }
    }

    //Swatches

    .swatch-attribute-options {
        margin-top: 10px;
    }

    //Actions
    .product-item-actions{
        margin-top: 0;
    }
}

.products-upsell {
    .owl-nav {
        top: -10px;
    }
}

.products.products-grid.products-related {
    @media (max-width: $screen-sm-min - 1) {
        margin-top: 3.5rem;

        .owl-nav {
            top: -42px;
        }
    }
}