/**
 *
 */

.fotorama-video-container {
    &:after {
        background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
        bottom: 0;
        content: '';
        height: 100px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 12px;
        width: 100px;
    }
    .magnify-lens {
        display: none !important;
    }
    &.video-unplayed {
        &:hover {
            img {
                opacity: 0.6;
            }
            &:after {
                transform: scale(1.25);
            }
        }
    }
}

.video-thumb-icon:after {
    background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
    bottom: 0;
    content: '';
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 49px;
}

.product-video {
    height: 75%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    margin: auto;
    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9999;
    }
}



.fotorama__arr.hidden-video {
    z-index: -1 !important;
}

.fotorama__video-close {
    bottom: 89%;
    top: auto;
}

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
    bottom: 0;
    content: '';
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 12px;
    width: 100px;
}
