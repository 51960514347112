//
//  Compare products block
//  ---------------------------------------------

.block-compare{
    .block-title{
        @include make-flex;
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);

        strong,
        span{
            width: auto;
            margin-bottom: 0;
        }

        .counter{
            font-size: 16px;
            font-weight: 300;
            color: $default;
        }
    }

    .product-items{
        margin-bottom: $indent-small;
    }

    .product-item-name{
        display: block;
        padding-right: 2rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;

        a{
            color: $secondary-1;
            &:hover{
                color: $primary;
            }
        }
    }
    .product-item{
        position: relative;
        margin-bottom: $indent-small;
    }
    .action.delete{
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 $button-padding-hor;
        color: $default;
        &:hover{
            color: $primary;
        }
    }
    .actions-toolbar{
        @include make-flex();
        @include justify-content(space-between);
        .primary{
            @include order(2);
        }
        .action{
            font-size: 16px;
            background: none;
            padding: 0;
            color: lighten(saturate(adjust_hue($default, 0), 0%), 18.431372549019603%);
            &:hover{
                background: none;
                color: $primary;
            }
        }
        .primary{
            color: $secondary-1;
        }
    }
}
