//
//  Minicart block
//  ---------------------------------------------

.minicart-wrapper {
    .showcart span[class=""] {
        @include font-icon("\e8cc");
    }
}

.rd-navbar-cart {
    display: none;
    text-align: left;
    color: $default;
    background: $white;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
    transition: $default-transition;

    .block-minicart {
        &.empty {
            height: auto;
        }
    }

    .block-title,
    button.close {
        display: none;
    }

    #minicart-content-wrapper {
        overflow: hidden;
        padding: $indent-small 0;

        @media (min-width: $screen-sm-min){
            padding: 46px 0;
        }
    }
    .block-content {
        position: static;

        .minicart-items{
            overflow: auto;
        }
        .items-total {
            color: $default;
            padding: 0 $indent-small;

            @media (min-width: $screen-sm-min){
                padding: 0 50px;
            }
        }

        .product-item {
            padding: 0 $indent-small $indent-small;

            @media (min-width: $screen-sm-min){
                display: block;
                padding: 10px 50px;
            }
        }

        > .actions {
            position: relative;
            padding: $indent-small $indent-small;
            font-size: 16px;
            @include make-flex;
            @include justify-content(center);
            @include align-items(center);

            @media (min-width: $screen-sm-min){
                padding-right: 50px;
                padding-left: 50px;
            }

            + .actions{
                padding-top: 0;

                @media (min-width: $screen-sm-min){
                    padding: 0 50px 0;
                }
            }

            .primary,
            .secondary {
                button,
                a {
                    display: block;
                    width: 100%;
                    text-align: center;
                }
            }

            .secondary a{
                background-color: transparent;
                color: $secondary-1;
                padding: 5px;
                &:hover{
                    color: $primary;
                }
            }
        }

    }

    &:before {
        content: '';
        position: absolute;
        height: 32px;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
    }

    &-items {
        overflow: auto;
        max-height: 100%;
        height: 100%;
        padding: 16px 0;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: $default;
        }
    }

    .rd-navbar-cart-item {
        &__preview {
            border-radius: 50%;
        }
    }

    .rd-navbar-cart-item {
        position: relative;
        padding: 8px 0;
        &__left, &__body, &__remove {
            position: relative;
            display: table-cell;
        }

        &__left {
            padding: 0 16px;
            img {
                max-width: none;
            }
        }

        &__body {
            vertical-align: middle;
            width: 100%;
        }

        &__title {
            font-size: 16px;
            line-height: 26px;
            color: $white;
        }

        &__counter {
            font-size: 14px;
            line-height: 1.3;
        }

        &__remove {
            vertical-align: middle;
            width: 56px;
            font-size: 24px;
            padding: 0 16px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 88px;
            display: block;
            height: 1px;
            background: rgba($default, .2);
        }
    }
}

.rd-navbar-static {
    .rd-navbar-cart-wrap {
        position: relative;
        float: right;
        margin-left: 2.564583vw;
        width: 40px;
        height: 40px;

        @media (min-width: $screen-sm-min) {
            margin-top: 8px;
        }
    }

    .rd-navbar-cart-floating {
        display: block;
    }

    .rd-navbar-cart {
        display: block;
        position: absolute;
        z-index: 19;
        top: calc(100% + 30px);
        right: 0;
        width: 320px;
        visibility: hidden;
        opacity: 0;
        font-size: 14px;
        @include transform(translateY(30px));

        @media (min-width: $screen-sm-min){
            width: 400px;
        }
    }

    .minicart-wrapper.active .rd-navbar-cart {
        visibility: visible;
        opacity: 1;
        @include transform(translateY(0));
    }

    #top-cart-btn-checkout {
        position: relative;
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        transition: $default-transition;
        padding: 11px 43px;
        max-width: none;
        width: 100%;

        &:before {
            font-weight: 400;
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
        }
    }

    .minicart-items-wrapper{
        height: auto !important;
        overflow: visible;
        padding: 20px 0;
        border-bottom: 1px solid $border-color;
        margin-bottom: 20px;
    }
    .minicart-items{
        overflow: visible;
    }
    .minicart-items li > .product{
        overflow: visible;

        .product-item-details .product.option{
            position: relative;
            .content{
                position: absolute;
                z-index: 29;
                top: 20px;
                left: 0;
                background: $white;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
                padding: 5px;
            }
        }
    }
}

.tablet .rd-navbar-static {
    .rd-navbar-cart-wrap {
        @media (max-width: $screen-md-min - 1) {
            margin-top: 6px;
        }
    }
}

.block-minicart {
    font-family: $default-font-family;

    .block-content {
        position: relative;
    }

    .action.close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        padding: 0;
        background: none;
    }

    // a.action.delete, a.action.edit mixin in web\css\source\components\_forms.scss

    .subtotal {
        @include make-flex();
        @include justify-content(space-between);
        padding: 0 $indent-small;
        font-size: 20px;

        @media (min-width: $screen-sm-min){
            padding: 0 50px;
        }

        .label {
            margin-right: $indent-small;
            color: $black;
            font-weight: 400;
        }
        .price-wrapper{
            color: $primary;
        }
    }

    .subtitle {
        font-weight: 700;
        line-height: 1;
        padding: 0 $indent-small;

        &:not(.empty){
            font-size: 20px;
            color: $secondary-1;
        }

        @media (min-width: $screen-sm-min){
            display: block;
            padding: 0 50px;
        }

        span{
            vertical-align: baseline;
        }
    }

    .product.actions {
        .action {
            background: none;
            padding: 0;
            margin-right: 15px;
            color: $link-regular;
            box-shadow: none;
            border: none;
            &:hover {
                color: $link-hover;
            }
            &:after{
                display: none;
            }

            &.edit:before{
                @include font-icon__pseudo('\e3c9');
                font-size: 16px;

            }
            &.delete:before{
                @include font-icon__pseudo('\e872');
                font-size: 16px;
            }
        }
    }
}

//Minicart items
.minicart-items-wrapper {
    overflow-x: auto;
    padding: $indent-base 0;
}

.minicart-items {
    li > .product {
        overflow: hidden;
        @include make-flex;
        @include flex-wrap(wrap);
        @include justify-content(space-between);

        .product-image-container {
            display: block;
        }

        .minicart-item-foto-wrap{
            @include flex-basis(75px);
        }

        .product-item-photo{
            background: $bg-dark;
            display: block;
            padding: 10px 0;
        }

        .product-item-name {
            padding-right: 5px;
            display: inline-block;
            font-size: 16px;
            font-weight: 400;

            a{
                color: $black;
                &:hover{
                    color: $primary;
                }
            }
        }

        .product-item-bottom{
            position: relative;
            padding-top: 1rem;
        }

        .product-item-pricing{
            padding-bottom: 12px;
            @include make-flex;
            @include justify-content(flex-start);
            @include align-items(center);

            .details-qty{
                @include make-flex;
                @include justify-content(flex-start);
                @include align-items(center);
                padding-right: 30px;
                position: relative;
            }

            input{
                @include flex-basis(70px);
                -webkit-appearance: none;
                background-color: transparent;
                border-color: $border-color;
                font-size: 14px;
                line-height: 18px;
                padding: 6px;
                min-height: 0;
                border-radius: 50px;
                text-align: center;
                -moz-appearance: textfield;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            .minicart-price{
                color: lighten(saturate(adjust_hue($default, 0), 0%), 6.666666666666668%);
                font-size: 1rem;
            }
        }

        .minicart-item-info-wrap {
            padding-left: 10px;
            @include flex-basis(calc(100% - 75px));
        }
    }
    .item {
        margin-bottom: $indent-small;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .details-qty {
        .control {
            @include make-flex();
            @include flex-wrap(wrap);
        }
        .update-cart-item {
            @include font-icon("\e86a");
            color: $primary;
            text-align: right;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -11px;
            &:hover {
                color: $secondary-1;
            }
            span {
                display: none;
            }
        }
    }

    //Options
    .product.options {
        @include make-flex();
        @include flex-direction(column);
        font-size: 12px;

        .toggle {
            cursor: pointer;
            span{
                font-style: italic;
            }
        }

        .content{
            .subtitle {
                font-weight: 700;
                font-size: 12px;
            }
        }

        .list {
            @include make-flex();
            @include flex-wrap(wrap);
            @include flex-direction(row);

            > dt, > dd {
                @include flex-basis(50%);
            }
        }
    }

    //Actions
    .product.actions {
        @include make-flex;
    }
}

/*
* @subsection   RD Navbar Toggles
*/

.rd-navbar-cart-floating {
    display: none;
}

.rd-navbar-cart-toggle {
    span,
    strong {
        display: inline-block;
        font-weight: normal;
    }

    .loading-mask {
        display: none;
    }

    .text {
        font-family: $default-icon-family;
        position: relative;
        color: $primary;
        transition: .3s ease;

        &:before, &:after {
            position: absolute;
            top: 0;
            left: 0;
            transition: .3s transform ease;
            height: 40px;
            width: 40px;
            line-height: 40px;
        }

        &:before {
            content: '\e04f';
            font-size: 19px;
            font-family: $out-icons;
            @include transform(rotate(0deg) scale(1));
            opacity: 1;
            visibility: visible;
        }

        &:after {
            content: '\e5cd';
            font-size: 28px;
            @include transform(rotate(-90deg) scale(0));
            opacity: 0;
            visibility: hidden;
        }
    }

    &.active {
        .text {
            &:before {
                opacity: 0;
                visibility: hidden;
                @include transform(rotate(90deg) scale(0));
            }

            &:after {
                @include transform(rotate(0deg) scale(1));
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.active,
    &:hover {
        .text {
            color: $secondary-1;
        }
    }

    .rd-navbar-static & {
        .counter.qty {
            position: absolute;
            top: 2px;
            right: 7px;
            width: 16px;
            height: 16px;
            text-align: center;
            border-radius: 50px;
            background: $secondary-1;
            padding: 3px 0;
            .counter-number{
                font-size: 12px;
                line-height: 1;
                color: $white;
            }
        }

        .counter-label{
            display: none;
        }
    }
}

.rd-navbar-fixed {
    .block-minicart .subtitle.empty{
        display: inline-block;
    }

    .rd-navbar-cart {
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100vw;
        z-index: 94;

        &.active {
            opacity: 1;
        }
    }

    .rd-navbar-cart-floating {
        display: block;
        @extend %rd-navbar-transition;
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 20;
        color: #FFF;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: $rd-navbar-shadow-large, $rd-navbar-shadow-ambient;
        width: 56px;
        height: 56px;
        outline: 1px solid transparent;
        backface-visibility: hidden;

        &:before {
            @extend %rd-navbar-transition;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: $rd-navbar-accent-color;
            @include transform(translate(-50%, -50%));
        }

        #top-cart-btn-checkout {
            @extend %rd-navbar-transition;
            position: relative;
            display: block;
            font-size: 16px;
            font-weight: 500;
            line-height: 56px;
            opacity: 0;
            visibility: hidden;

            &:before {
                font-weight: 400;
                font-size: 24px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
            }
        }

        .rd-navbar-cart-toggle {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));

            span {
                color: #FFF;
                height: 56px;
                line-height: 56px;
            }
        }

        &.active {
            right: 0;
            bottom: 0;
            border-radius: 0;
            width: 100%;

            &:before {
                @include transform(translate(-50%, -50%) scale(20));
                background: $rd-navbar-accent-color-2;
            }

            #top-cart-btn-checkout {
                opacity: 1;
                visibility: visible;
            }

            .rd-navbar-cart-toggle {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
    .rd-navbar-cart-fixed {
        display: block;
        @extend %rd-navbar-transition;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 17;
        overflow: visible;
        width: 25vw;
        height: 100px;
        outline: 1px solid transparent;
        backface-visibility: hidden;

        @media (max-width: $screen-xs-min - 1){
            height: 40px;
        }

        .rd-navbar-cart-toggle {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 30px;
            height: 30px;
            margin-top: -16px;
            margin-left: -13px;

            @media (max-width: $screen-xs-min - 1){
                margin-top: -10px;
            }

            .text {
                width: 30px;
                height: 30px;
                text-align: center;
                color: $secondary-1;
                &:after,
                &:before {
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    font-size: 20px;
                    line-height: 1;
                    @media (min-width: $screen-xs-min){
                        @media (max-width: $screen-sm-min - 1){
                            font-size: 30px;
                        }
                    }
                }
            }

            .qty{
                position: absolute;
                display: inline-block;
                top: 50%;
                left: 50%;
                margin-top: -17px;
                margin-left: 10px;
                font-size: 12px;
                line-height: 1;
                height: 12px;
                color: $secondary-1;
                @media (min-width: $screen-xs-min){
                    @media (max-width: $screen-sm-min - 1){
                        margin-top: -19px;
                        margin-left: 14px;
                    }
                }

                .counter-number{
                    line-height: 12px;
                }
            }

            &.active {
                .text,
                .qty{
                    color: $primary;
                }
            }

            .counter-number {
                line-height: 46px;
            }

            .loading-mask {
                visibility: hidden;
            }

            &, a {
                color: $secondary-1;
            }

            .counter-label {
                display: none;
            }
        }
    }
}